
/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class CategoryAdd extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected form: HTMLFormElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('category_add');
            this.form = this.window.getForm();
            this.initFormSender();
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    let rowData: Object = {
                        id: response.id,
                        nazwa: this.form['name']['value'],
                        kolor: this.form['color'].value.substring(1),
                        priorytet_koloru: this.form['priority'].value ? this.form['priority'].value : 0
                    };
                    let newCategory: Entity.Map.Type.Category = new Entity.Map.Type.Category(rowData);
                    this.app.entityContainer.categories[newCategory.id] = newCategory;
                    this.app.service.callbackContainer.invokeCallbacks('category', new Control.CallbackContainer.Event('update', newCategory, {}));
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
