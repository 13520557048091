/**
 * Created by Mateusz Lipowski on 21.10.2016.
 */

namespace Action.ContextMenu {
    export class MarkerDisconnectedNetworks extends UserActionBase {
        protected node: Entity.Map.Marker.Node.DeviceNode;
        protected window: Entity.View.Window.Window;
        protected div: HTMLDivElement;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.node = this.event.target as Entity.Map.Marker.Node.DeviceNode;
            this.window = this.app.service.windowContainer.createWindow(Control.WindowClass.WINDOW) as Entity.View.Window.Window;
            this.window.show('disconnected_networks');
            this.window.setWide();
            this.initView();
            this.initRequestSender();
        }

        protected initView(): void {
            this.div = this.window.getForm().getElementsByClassName('disconnected-networks-div')[0] as HTMLDivElement;
        }

        protected initRequestSender(): void {
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('FindLostNodes', {id: this.node.id}, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                    return;
                }

                try {
                    let networks: [[number]] = JSON.parse(response);
                    this.populateNetworks(networks);
                }
                catch (e) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, e.message);
                    alert.show();
                }
            });
        }

        protected populateNetworks(networks: [[number]]): void {
            this.removeAllChildren(this.div);

            let index: number = 1;
            for(let network of networks) {
                let table: HTMLTableElement = document.createElement('table') as HTMLTableElement;

                let headerTr: HTMLTableRowElement = document.createElement('tr') as HTMLTableRowElement;
                let th: HTMLTableHeaderCellElement = document.createElement('th') as HTMLTableHeaderCellElement;
                th.colSpan = 3;
                th.textContent = 'Sieć ' + index + '.';
                headerTr.appendChild(th);
                table.appendChild(headerTr);

                for(let i = 0; i < network.length; i++) {
                    if(!network[i])
                        continue;

                    let node: Entity.Map.Marker.Node.DeviceNode = this.app.entityContainer.findNode(network[i].toString()) as Entity.Map.Marker.Node.DeviceNode;
                    if(!node)
                        continue;

                    let tr: HTMLTableRowElement = document.createElement('tr') as HTMLTableRowElement;
                    let tdId: HTMLTableCellElement = document.createElement('td') as HTMLTableCellElement;
                    let tdName: HTMLTableCellElement = document.createElement('td') as HTMLTableCellElement;
                    let tdAdres: HTMLTableCellElement = document.createElement('td') as HTMLTableCellElement;

                    tdId.textContent = node.id.toString();

                    let anchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
                    anchor.href = '#';
                    anchor.textContent = (node.name ? node.name : ' - brak - ');
                    tdName.appendChild(anchor);

                    tdAdres.textContent = node.address;

                    tr.appendChild(tdId);
                    tr.appendChild(tdName);
                    tr.appendChild(tdAdres);
                    table.appendChild(tr);
                }


                this.div.appendChild(table);
                index++;
            }
        }
    }
}



