
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class RangePolygonCut extends Action.UserActionBase {
        protected rangePolygon: Entity.Map.Shape.RangePolygon = null;
        protected stateBox: Entity.View.StateBox = null;
        protected guidePolyline: google.maps.Polyline = null;
        protected path: google.maps.MVCArray = null;
        protected vertexIndex: number = 0;

        protected proxyListenerOnStateBoxClose: (e: MouseEvent) => void;
        protected proxyListenerOnMouseMoveInPolygon: (params) => void;
        protected proxyListenerOnMouseOutOffPolygon: (params) => void;
        protected proxyListenerOnPolygonMouseDown: (params) => void;

        protected mouseMoveListener: google.maps.MapsEventListener;
        protected mouseOutListener: google.maps.MapsEventListener;
        protected mouseDownListener: google.maps.MapsEventListener;

        public constructor(event: Action.UserActionEvent) {
            super(event);
            this.stateBox = new Entity.View.StateBox(document.getElementById('sub-state-box') as HTMLScriptElement);

            this.proxyListenerOnStateBoxClose = (e: MouseEvent): void => {
                this.onStateBoxClose();
            };
            this.proxyListenerOnMouseMoveInPolygon = (params): void => {
                this.onMouseMoveInPolygon(params);
            };
            this.proxyListenerOnMouseOutOffPolygon = (params): void => {
                this.onMouseMoveInPolygon(params);
            };
            this.proxyListenerOnPolygonMouseDown = (params): void => {
                this.onPolygonMouseDown(params);
            };
        }

        public execute(): void {
            this.rangePolygon = this.event.target as Entity.Map.Shape.RangePolygon;
            if(this.rangePolygon.getEditable() && this.event.params['vertex'] != undefined) {
                this.initView();
            }
            else {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Brak wierzchołka do wyznaczenia linii cięcia');
                alert.show();
            }
        }

        protected initView(): void {
            this.path = this.rangePolygon.getPath();
            this.vertexIndex = this.event.params['vertex'];

            this.guidePolyline = new google.maps.Polyline({
                strokeColor: '#000000',
                strokeWeight: 3,
                zIndex: 100,
                path: [this.path.getAt(this.vertexIndex), this.path.getAt(this.vertexIndex)]
            });
            this.guidePolyline.setMap(this.app.service.map);

            this.stateBox.show('Wybierz drugi wierzchołek by wyznaczyć linię cięcia');
            this.stateBox.getCloseButton().addEventListener('click', this.proxyListenerOnStateBoxClose);

            this.mouseMoveListener = this.rangePolygon.polygon.addListener('mousemove', this.proxyListenerOnMouseMoveInPolygon);
            this.mouseOutListener = this.rangePolygon.polygon.addListener('mouseout', this.proxyListenerOnMouseOutOffPolygon);
            this.mouseDownListener = this.rangePolygon.polygon.addListener('mousedown', this.proxyListenerOnPolygonMouseDown);
        }

        protected onStateBoxClose(): void {
            this.dispose();
        }

        protected onMouseMoveInPolygon(params): void {
            if(params['vertex'] !== undefined) {
                this.guidePolyline.getPath().setAt(1, this.path.getAt(params['vertex']));
            }
            else {
                this.guidePolyline.getPath().setAt(1, this.path.getAt(this.vertexIndex));
            }
        }

        protected onPolygonMouseDown(params): void {
            if(params['vertex'] == undefined)
                return;

            let targetVertexIndex: number = params['vertex'];

            if(targetVertexIndex == this.vertexIndex) {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Nie można wybrać tego samego wierzchołka');
                alert.show();
            }
            else if(Math.abs(targetVertexIndex - this.vertexIndex) == 1) {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Nie można wykonać cięcia po sąsiednich wierzchołkach');
                alert.show();
            }
            else {
                this.rangePolygon.split(this.vertexIndex, targetVertexIndex);
                this.dispose();
            }
        }

        protected dispose(): void {
            if(this.stateBox) {
                let button = this.stateBox.getCloseButton();
                if(button)
                    button.removeEventListener('click', this.proxyListenerOnStateBoxClose);

                this.stateBox.dismiss();
            }

            this.guidePolyline.setMap(null);
            this.guidePolyline = null;

            google.maps.event.removeListener(this.mouseMoveListener);
            google.maps.event.removeListener(this.mouseOutListener);
            google.maps.event.removeListener(this.mouseDownListener);
        }
    }
}
