/**
 * Created by Mateusz Lipowski on 24.10.2016.
 */

namespace Entity.View.Window {
    interface DeviceRowStruct {
        editButton: HTMLInputElement,
        changeButton: HTMLInputElement,
        deleteButton: HTMLInputElement,
        cancelButton: HTMLInputElement,
        row: HTMLTableRowElement,
        device: Entity.Device.Device
    }

    export class DevicesWindow extends Entity.View.Window.Window {
        protected node: Entity.Map.Marker.Node.Node = null;
        protected deviceRequestResponse: Entity.Device.DeviceRequestResponse = null;
        protected devices: Entity.Device.Device[] = [];
        protected clientMode: boolean = false;
        protected initialized: boolean = false;
        protected updateCallbackId: number = null;
        protected loader: Entity.View.Loader = null;

        protected table: HTMLTableElement = null;
        protected addDeviceRow: HTMLTableRowElement = null;
        protected deviceRows: DeviceRowStruct[] = [];

        protected eventProxyOnEditButtonClick: (e: MouseEvent) => void;
        protected eventProxyOnChangeButtonClick: (e: MouseEvent) => void;
        protected eventProxyOnDeleteButtonClick: (e: MouseEvent) => void;
        protected eventProxyOnCancelButtonClick: (e: MouseEvent) => void;
        protected eventProxyOnAddButtonClick: (e: MouseEvent) => void;

        public constructor(windowContent: HTMLDivElement, windowContainerIndex: number = null) {
            super(windowContent, windowContainerIndex);
            this.deviceRequestResponse = new Entity.Device.DeviceRequestResponse();

            this.updateCallbackId = App.getInstance().service.callbackContainer.pushCallback('devices', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.reload();
                return true;
            });

            this.eventProxyOnEditButtonClick = (e: MouseEvent): void => {
                this.onEditButtonClick(this.getDeviceRowStruct('editButton', e.target as HTMLInputElement));
            };
            this.eventProxyOnChangeButtonClick = (e: MouseEvent): void => {
                this.onChangeButtonClick(this.getDeviceRowStruct('changeButton', e.target as HTMLInputElement));
            };
            this.eventProxyOnDeleteButtonClick = (e: MouseEvent): void => {
                this.onDeleteButtonClick(this.getDeviceRowStruct('deleteButton', e.target as HTMLInputElement));
            };
            this.eventProxyOnCancelButtonClick = (e: MouseEvent): void => {
                this.onCancelButtonClick();
            };
            this.eventProxyOnAddButtonClick = (e: MouseEvent): void => {
                this.onAddButtonClick();
            };
        }

        public showForNode(node: Entity.Map.Marker.Node.Node, clientMode: boolean = false): void {
            this.node = node;
            this.clientMode = clientMode;

            this.loader = App.getInstance().service.loader;
            this.loader.show('Pobieranie urządzeń');
            this.reload(true);
        }

        public reload(firstLoad: boolean = false): void {
            this.deviceRows = [];

            let deviceRequestResponseRequestFunctionName = 'requestDevicesForNode';
            let id: number = this.node.id;
            if(this.clientMode) {
                deviceRequestResponseRequestFunctionName = 'requestDevicesForClientService';
                id = (this.node as Entity.Map.Marker.Node.ClientNode).client_service_id;
            }

            this.deviceRequestResponse[deviceRequestResponseRequestFunctionName](id, (): void => {
                if (this.deviceRequestResponse.error) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Nie można pobrać urządzeń: ' + this.deviceRequestResponse.message);
                    alert.show();
                    this.loader.dismiss();
                    return;
                }

                this.devices = this.deviceRequestResponse.devices;

                if(firstLoad) {
                    this.show('devices');
                    this.initView();
                }

                this.populateDevices();
                this.loader.dismiss();
            });
        }

        public dismiss(): void {
            let callbackContainer: Control.CallbackContainer.CallbackContainer = App.getInstance().service.callbackContainer;
            callbackContainer.deleteCallbackById('devices', 'update', this.updateCallbackId);

            for(let deviceRowStruct of this.deviceRows) {
                deviceRowStruct.editButton.removeEventListener('click', this.eventProxyOnEditButtonClick);
            }

            super.dismiss();
        }

        protected initView(): void {
            if (this.initialized)
                return;

            if(this.clientMode)
                this.setWide();
            else
                this.setVeryWide();

            this.table = this.form.getElementsByTagName('table')[0] as HTMLTableElement;
            if(!this.table) {
                this.loader.dismiss();
                throw new Error('Entity.View.DevicesWindow.initView: Can\'t find table element');
            }

            this.initialized = true;
        }

        protected onEditButtonClick(deviceRowStruct: DeviceRowStruct): void {
            this.restoreTableRows();

            let deviceElements: HTMLCollection = deviceRowStruct.row.getElementsByClassName('device-element') as HTMLCollection;
            let editButtonElement: HTMLInputElement = deviceRowStruct.editButton as HTMLInputElement;

            for(let i = 0; i < deviceElements.length; i++) {
                let element: HTMLElement = deviceElements.item(i) as HTMLElement;
                let convertedElement: HTMLInputElement = this.convertToEditableInput(element);

                if(!convertedElement)
                    continue;

                element.parentElement.insertBefore(convertedElement, element);
                element.style.display = 'none';
            }

            if(editButtonElement) {
                this.replaceElement(editButtonElement, this.createChangeButtons(deviceRowStruct), true);
            }
        }

        protected onChangeButtonClick(deviceRowStruct: DeviceRowStruct): void {
            let app: App = App.getInstance();
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            let convertedElements: HTMLCollection = deviceRowStruct.row.getElementsByClassName('converted-element') as HTMLCollection;
            let formFields: Object = {id: deviceRowStruct.device.id};

            app.service.loader.show();

            for(let i = 0; i < convertedElements.length; i++) {
                let element: HTMLInputElement = convertedElements.item(i) as HTMLInputElement;
                let fieldName: string = element.dataset['name'];

                if(element.type == 'checkbox')
                    formFields[fieldName] = element.checked;
                else
                    formFields[fieldName] = element.value;
            }

            requestSender.sendRequest('EditDevice', formFields, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.message);
                    App.getInstance().service.callbackContainer.invokeCallbacks('devices', new Control.CallbackContainer.Event('update', this, null));
                    alert.show();
                }

                app.service.loader.dismiss();
            });
        }

        protected onDeleteButtonClick(deviceRowStruct: DeviceRowStruct): void {
            let app: App = App.getInstance();
            let requestSender: Logic.RequestSender = new Logic.RequestSender();

            if(!confirm('Na pewno?'))
                return;

            app.service.loader.show();

            requestSender.sendRequest('DeleteDevice', {id: deviceRowStruct.device.id}, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.message);
                    App.getInstance().service.callbackContainer.invokeCallbacks('devices', new Control.CallbackContainer.Event('update', this, null));
                    alert.show();
                }

                app.service.loader.dismiss();
            });
        }

        protected onCancelButtonClick(): void {
            this.restoreTableRows();
        }

        protected onAddButtonClick(): void {
            let app: App = App.getInstance();
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            let inputs: NodeListOf<HTMLInputElement> = this.addDeviceRow.getElementsByTagName('input') as unknown as NodeListOf<HTMLInputElement>;
            let formFields: Object = {wezel_id: this.node.id};

            app.service.loader.show();

            for(let i = 0; i < inputs.length; i++) {
                let input: HTMLInputElement = inputs.item(i) as HTMLInputElement;
                if(!input || !input.name || !input.value)
                    continue;

                if(input.type == 'checkbox')
                    formFields[input.name] = input.checked;
                else
                    formFields[input.name] = input.value;
            }

            requestSender.sendRequest('AddDevice', formFields, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.message);
                    App.getInstance().service.callbackContainer.invokeCallbacks('devices', new Control.CallbackContainer.Event('update', this, null));
                    alert.show();
                }

                app.service.loader.dismiss();
            });
        }

        protected restoreTableRows(): void {
            for(let deviceRowStruct of this.deviceRows) {
                let deviceElements: HTMLCollection = deviceRowStruct.row.getElementsByClassName('device-element') as HTMLCollection;
                let convertedElements: HTMLCollection = deviceRowStruct.row.getElementsByClassName('converted-element') as HTMLCollection;
                let editAndChangeButtonsElement: HTMLInputElement = deviceRowStruct.row.getElementsByClassName('edit-change-buttons')[0] as HTMLInputElement;
                let editButtonElement: HTMLInputElement = deviceRowStruct.editButton as HTMLInputElement;

                for(let i = 0; i < deviceElements.length; i++) {
                    (<HTMLElement>deviceElements.item(i)).style.display = 'block';
                }

                while(convertedElements.length > 0) {
                    let element: HTMLElement = convertedElements.item(0) as HTMLElement;
                    element.remove();
                }

                if(editAndChangeButtonsElement) {
                    editButtonElement.style.display = 'block';
                    editAndChangeButtonsElement.parentElement.removeChild(editAndChangeButtonsElement);
                    editAndChangeButtonsElement = null;
                    deviceRowStruct.changeButton = null;
                    deviceRowStruct.deleteButton = null;
                    deviceRowStruct.cancelButton = null;
                }
            }
        }

        protected populateDevices(): void {
            if(!this.table) {
                if(this.loader)
                    this.loader.dismiss();
                throw new Error('Entity.View.DevicesWindow.populateDevices: Table element is undefined');
            }

            this.clearTable();
            this.createTableHeader();

            for(let device of this.devices) {
                this.appendDeviceRow(device);
            }

            this.appendNewDeviceRow();
        }

        protected appendDeviceRow(device: Entity.Device.Device): void {
            let deviceRowStruct: DeviceRowStruct = {
                editButton: null,
                changeButton: null,
                deleteButton: null,
                cancelButton: null,
                device: device,
                row: null
            };

            let linkedDeviceNode: Entity.Map.Marker.Node.DeviceNode[] = App.getInstance().entityContainer.findNodesByDevicesId(device.id);
            let linkedDeviceNodeList: string = '';

            linkedDeviceNode.map((value, index) => {
                if(index != 0)
                    linkedDeviceNodeList += ', ';
                linkedDeviceNodeList += value.getShortenedDeviceName();
            });

            let ip = this.createSpan(device.ip, null, 'device-element ip');
            let mac = this.createSpan(device.mac, null, 'device-element mac');
            let name = this.createSpan(device.name, null, 'device-element name');
            let ssid = this.createSpan(device.ssid, null, 'device-element ssid');
            let freq = this.createSpan(device.freq, null, 'device-element freq');
            let mikrotik = this.createCheckbox(device.mikrotik, null, 'device-element mikrotik', null, true);
            let ubiquiti = this.createCheckbox(device.ubiquiti, null, 'device-element ubiquiti', null, true);
            let nodeLink = linkedDeviceNode.length > 0 ? linkedDeviceNodeList : null;
            let button = this.createEditButton(deviceRowStruct);

            ip.innerHTML = Logic.Util.generateIpAddressLink(device.ip);

            this.setAttributes(ip, {'data-name': 'ip'});
            this.setAttributes(mac, {'data-name': 'mac'});
            this.setAttributes(name, {'data-name': 'name'});
            this.setAttributes(ssid, {'data-name': 'ssid'});
            this.setAttributes(freq, {'data-name': 'freq'});
            this.setAttributes(mikrotik, {'data-name': 'mikrotik'});
            this.setAttributes(ubiquiti, {'data-name': 'ubiquiti'});

            let row: HTMLTableRowElement = null;
            if(this.clientMode)
                row = this.createTableRow('td', ip, mac, name, ssid, freq, mikrotik, ubiquiti);
            else
                row = this.createTableRow('td', ip, mac, name, ssid, freq, mikrotik, ubiquiti, nodeLink, button);

            this.table.appendChild(row);

            deviceRowStruct.row = row;
            this.deviceRows.push(deviceRowStruct);
        }

        protected appendNewDeviceRow(): void {
            if(this.clientMode)
                return;

            let ip = this.createTextInput('', null, 'device-element ip', 'ip');
            let mac = this.createTextInput('', null, 'device-element mac', 'mac');
            let name = this.createTextInput('', null, 'device-element name', 'name');
            let ssid = this.createTextInput('', null, 'device-element ssid', 'ssid');
            let freq = this.createTextInput('', null, 'device-element freq', 'freq');
            let mikrotik = this.createCheckbox(false, null, 'device-element mikrotik', 'mikrotik');
            let ubiquiti = this.createCheckbox(false, null, 'device-element ubiquiti', 'ubiquiti');
            let button = this.createAddButton();

            let row = this.createTableRow('td', ip, mac, name, ssid, freq, mikrotik, ubiquiti, '', button);
            this.table.appendChild(row);
            this.addDeviceRow = row;
        }

        protected createTableHeader(): void {
            let row: HTMLTableRowElement = null;
            if(this.clientMode)
                row = this.createTableRow('th', 'IP', 'MAC', 'Nazwa', 'SSID', 'Freq', 'Mikrotik', 'Ubiquiti');
            else
                row = this.createTableRow('th', 'IP', 'MAC', 'Nazwa', 'SSID', 'Freq', 'Mikrotik', 'Ubiquiti', 'Powiązanie', '');
            this.table.appendChild(row);
        }

        protected createEditButton(deviceRowStruct: DeviceRowStruct): HTMLInputElement {
            let editButton: HTMLInputElement = this.createButton('Edytuj', null, 'editButton');
            editButton.addEventListener('click', this.eventProxyOnEditButtonClick);
            deviceRowStruct.editButton = editButton;
            return editButton;
        }

        protected createAddButton(): HTMLInputElement {
            let editButton: HTMLInputElement = this.createButton('Dodaj', null, 'editButton');
            editButton.addEventListener('click', this.eventProxyOnAddButtonClick);
            return editButton;
        }

        protected createChangeButtons(deviceRowStruct: DeviceRowStruct): HTMLSpanElement {
            let changeButton: HTMLInputElement = this.createButton('Zmień', null, 'editButton');
            let deleteButton: HTMLInputElement = this.createButton('Usuń', null, 'editButton');
            let cancelButton: HTMLInputElement = this.createButton('Anuluj', null, 'editButton');
            let span: HTMLSpanElement = document.createElement('span') as HTMLSpanElement;
            span.appendChild(changeButton);
            span.appendChild(deleteButton);
            span.appendChild(cancelButton);
            span.className += 'edit-change-buttons';

            changeButton.addEventListener('click', this.eventProxyOnChangeButtonClick);
            deleteButton.addEventListener('click', this.eventProxyOnDeleteButtonClick);
            cancelButton.addEventListener('click', this.eventProxyOnCancelButtonClick);

            deviceRowStruct.changeButton = changeButton;
            deviceRowStruct.deleteButton = deleteButton;
            deviceRowStruct.cancelButton = cancelButton;

            return span;
        }

        protected clearTable(): void {
            for(let i = 0; i < this.deviceRows.length; i++) {
                if(this.deviceRows[i].editButton)
                    this.deviceRows[i].editButton.removeEventListener('click', this.eventProxyOnEditButtonClick);

                this.deviceRows[i] = null;
            }

            this.removeAllChildren(this.table);
        }

        protected getDeviceRowStruct(field: string, value: any): DeviceRowStruct {
            for(let deviceRowStruct of this.deviceRows) {
                if(deviceRowStruct[field] == value)
                    return deviceRowStruct;
            }

            return null;
        }
    }
}
