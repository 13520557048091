/**
 * Created by Mateusz Lipowski on 06.10.2016.
 */

namespace Entity.Map.Type {
    export class Type extends Entity.Map.MapEntity {
        protected _id: number = 0;
        protected _category_id: number = 0;
        protected _name: string = '';
        protected _symbol: string = '';
        protected _color: string = null;
        protected _category: Entity.Map.Type.Category = null;
        protected _isVisible: boolean = true;

        public constructor(rowData: Object) {
            super();
            this._id = parseInt(rowData['id']);
            this._category_id = parseInt(rowData['kategoria_id']);
            this._name = rowData['nazwa'];
            this._symbol = rowData['symbol'];
            this._color = rowData['kolor'];

            this.update(rowData);
        }

        public update(rowData = null) {
            if(!this._id)
                throw new Error('Błędne ID dla typu (podane id: ' + rowData['id'] + ')');

            if(this._category_id > 0) {
                this._category = App.getInstance().entityContainer.findCategory(this._category_id);
            }

            if(!this._name || this._name.length <= 0)
                throw new Error('Błędna nazwa dla typu ' + this._id + ' (podana nazwa: ' + rowData['nazwa'] + ')');

            if(!this._color || this._color.length <= 0)
                this._color = 'FFFFFF';
        }

        get id(): number {
            return this._id;
        }

        get category_id(): number {
            return this._category_id;
        }

        set category_id(category_id: number) {
            this._category_id = category_id;
            this.update();
        }

        get name(): string {
            return this._name;
        }

        set name(name) {
            this._name = name;
        }

        get symbol(): string {
            return this._symbol;
        }

        set symbol(symbol) {
            this._symbol = symbol;
        }

        get color(): string {
            return this._color;
        }

        set color(color) {
            this._color = color;
        }

        get category(): Entity.Map.Type.Category {
            return this._category;
        }

        get isVisible(): boolean {
            return this._isVisible;
        }

        set isVisible(isVisible) {
            this._isVisible = isVisible;
        }
    }
}
