/**
 * Created by Mateusz Lipowski on 04.01.2017.
 */

namespace Control {
    export class Settings {
        protected requestSender: Logic.RequestSender = null;
        protected settings: Object = null;
        protected loadCallback: () => void;
        protected errorCallback: () => void;
        protected updateCallback: () => void;

        public constructor(loadCallback: () => void, errorCallback: () => void) {
            this.loadCallback = loadCallback;
            this.errorCallback = errorCallback;
            this.requestSender = new RequestSender();
            this.getSettings();
        }

        public get(key: string): string | number {
            if (!this.settings)
                return null;

            if (!this.settings.hasOwnProperty(key))
                return null;

            return this.settings[key];
        }

        public set(key: string, value: string | number): boolean {
            if (!this.settings)
                return false;

            this.settings[key] = value;

            return true;
        }

        public save(updateCallback: () => void = null): void {
            this.updateCallback = updateCallback;
            this.updateSettings();
        }

        protected getSettings(): void {
            this.requestSender.sendRequest('GetSettings', { }, (action: string, fields: Object, response: any): void => {
                this.settings = null;
                if (!this.requestSender.isError()) {
                    try {
                        if (response)
                            this.settings = JSON.parse(response);
                        else
                            this.settings = {};

                        if (this.loadCallback) {
                            this.loadCallback();
                        }
                    }
                    catch (e) {
                        let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Settings.getSettings: ' + e.message);
                        alert.show();
                        this.settings = null;

                        if (this.errorCallback)
                            this.errorCallback();
                    }
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Settings.getSettings: ' + this.requestSender.message);
                    alert.show();

                    if (this.errorCallback)
                        this.errorCallback();
                }
            });
        }

        protected updateSettings(): void {
            this.requestSender.sendRequest('SetSettings', {settings: this.settingsJson}, (action: string, fields: Object, response: any): void => {
                if (!this.requestSender.isError()) {
                    if (this.updateCallback)
                        this.updateCallback();
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.requestSender.message);
                    alert.show();
                }
            });
        }

        protected get settingsJson(): string {
            return JSON.stringify(this.settings);
        }
    }
}