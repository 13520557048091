/**
 * Created by Mateusz Lipowski on 25.11.2016.
 */

namespace Entity.Map.NetworkRange {
    export class Offer extends Entity.Map.MapEntity {
        protected _id: number;
        protected _name: string;
        protected _description: string;
        protected _color: string;

        public constructor(rowData: Object) {
            super();
            this._id = parseInt(rowData['id']);
            this._name = rowData['nazwa'];
            this._description = rowData['opis'];
            this._color = rowData['kolor'];
        }

        public get id(): number {
            return this._id;
        }

        public get name(): string {
            return this._name;
        }

        public get description(): string {
            return this._description;
        }

        public get color(): string {
            return this._color;
        }

        public set id(id: number) {
            this._id = id;
        }

        public set name(name: string) {
            this._name = name;
        }

        public set description(description: string) {
            this._description = description;
        }

        public set color(color: string) {
            this._color = color;
        }
    }
}
