/**
 * Created by Mateusz Lipowski on 25.10.2016.
 */

namespace Control {
    export class WindowClass {
        public static WINDOW = 'window';
        public static PHOTOS_WINDOW = 'photos_window';
        public static PHOTO_ADD_WINDOW = 'photo_add_window';
        public static DEVICES_WINDOW = 'devices_window';
        public static ASSIFN_OFFER_WINDOW = 'offer_window';
    }

    export class WindowContainer {
        protected windowContainerElement: HTMLDivElement = null;
        protected windowTemplateElement: HTMLDivElement = null;
        protected windowInstances: Entity.View.Window.Window[] = [];

        public constructor(windowContainerElement: HTMLDivElement, windowContentTemplateElement: HTMLDivElement) {
            //super();
            this.windowContainerElement = windowContainerElement;
            this.windowTemplateElement = windowContentTemplateElement;
        }

        public createWindow(windowClass: string = Control.WindowClass.WINDOW): Entity.View.Window.Window {
            let index: number = this.nextIndex();
            let windowContent = this.createWindowContentElement(index);
            let window: Entity.View.Window.Window = null;

            if(windowClass == Control.WindowClass.WINDOW)
                window = new Entity.View.Window.Window(windowContent, index);
            else if(windowClass == Control.WindowClass.PHOTOS_WINDOW)
                window = new Entity.View.Window.PhotosWindow(windowContent, index);
            else if(windowClass == Control.WindowClass.PHOTO_ADD_WINDOW)
                window = new Entity.View.Window.PhotoAddWindow(windowContent, index);
            else if(windowClass == Control.WindowClass.DEVICES_WINDOW)
                window = new Entity.View.Window.DevicesWindow(windowContent, index);
            else if(windowClass == Control.WindowClass.ASSIFN_OFFER_WINDOW)
                window = new Entity.View.Window.AssignOfferWindow(windowContent, index);
            else
                throw new Error('WindowContainer.createWindow: Can\'t create class ' + windowClass);

            this.windowContainerElement.appendChild(windowContent);
            this.windowInstances[index] = window;

            return window;
        }

        public disposeWindow(index: number): void {
            if(!this.windowInstances[index])
                throw new Error('WindowContainer.disposeWindow: Can\'t dispose - there is no instance at index ' + index);

            let windowContent = document.getElementById(this.getWindowContentIdByIndex(index));
            if(windowContent)
                this.windowContainerElement.removeChild(windowContent);

            delete this.windowInstances[index];
        }

        protected getWindowContentIdByIndex(index: number) {
            return 'window-content-instance-' + index;
        }

        protected createWindowContentElement(index: number): HTMLDivElement {
            let windowContent: HTMLDivElement = this.windowTemplateElement.cloneNode(true) as HTMLDivElement;
            windowContent.id = this.getWindowContentIdByIndex(index);

            return windowContent;
        }

        protected nextIndex(): number {
            return this.windowInstances.length;
        }
    }
}
