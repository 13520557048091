/**
 * Created by Mateusz on 22.09.2016.
 */
namespace Entity.View.Menu {
    export class ContextMenu {
        private _menuDom: HTMLScriptElement;
        private _menus: HTMLUListElement[] = [];
        private _documentEventListener;

        private shownUl: HTMLUListElement = null;
        private currentShape = null;
        private event: Action.UserActionEvent = null;

        private _preventOutsideMenuClickDismiss: boolean = false;

        constructor(menuDom: HTMLScriptElement) {
            this._menuDom = menuDom;

            let lists = menuDom.getElementsByTagName('ul');
            for (let i = 0; i < lists.length; i++) {
                let ul: HTMLUListElement = lists[i] as HTMLUListElement;

                let liElements = ul.getElementsByTagName('li');

                for (let j = 0; j < liElements.length; j++) {
                    liElements[j].addEventListener('click', (e: Event) => {
                        this.onClick(liElements[j]);
                    });
                }

                this._menus[ul.id] = ul;
            }

            App.getInstance().service.inputManager.setupDOMEvent(document, 'click', (e: MouseEvent) => {
                if (e.which === 3) return;

                this.onOutsideMenuClickListener();
            });
        }

        public show(shape: any, event: Action.UserActionEvent): void {
            if (App.getInstance().service.mapContainer.isDrag())
                return;

            this.dismiss();

            for (let id in this._menus) {
                let ul: HTMLUListElement = this._menus[id];
                let ulClassname: string = ul.dataset['shape'];
                let ulOntrueMethodName: string = ul.dataset['shape_ontrue'];
                let ulOnfalseMethodName: string = ul.dataset['shape_onfalse'];
                let shapeClassname: string = shape.constructor.name;
                let windowWidth: number = window.innerWidth;
                let windowHeight: number = window.innerHeight;

                //dobór odpowiedniego UL na podstawie nazwy klasy i atrybutów data
                if (ulClassname == shapeClassname) {
                    if (ulOntrueMethodName && shape[ulOntrueMethodName] && shape[ulOntrueMethodName]() != true)
                        continue;

                    if (ulOnfalseMethodName && shape[ulOnfalseMethodName] && shape[ulOnfalseMethodName]() != false)
                        continue;

                    //wyświetlanie menu kontekstowego
                    ul.style.display = 'block';

                    let coordinates = null;
                    let liList = ul.getElementsByTagName('li');

                    if (event.coordinates)
                        coordinates = event.coordinates;
                    else
                        coordinates = App.getInstance().service.overlay.getProjection().fromLatLngToContainerPixel(event.params['latLng']);

                    //przesunięcie względem strzałki
                    coordinates.x -= 12;
                    coordinates.y += 10;

                    //obliczanie przesunięcia gdy otworzymy menu zbyt blisko krawędzi ekranu:
                    if (coordinates.x + ul.offsetWidth > windowWidth)
                        coordinates.x = windowWidth - ul.offsetWidth - 2;
                    if (coordinates.y + ul.offsetHeight > windowHeight)
                        coordinates.y = windowHeight - ul.offsetHeight - 8;


                    ul.style.left = `${coordinates.x}px`;
                    ul.style.top = `${coordinates.y - 10}px`;

                    //wyświetlanie odpowiednich podpunktów listy na podstawie atrybutów data:
                    for (let key in liList) {
                        if (!liList.hasOwnProperty(key))
                            continue;

                        let eventDefined: string = liList[key].dataset['event_defined'];
                        let shapeOntrue: string = liList[key].dataset['shape_ontrue'];
                        let shapeOnfalse: string = liList[key].dataset['shape_onfalse'];
                        if (eventDefined) {
                            if (event[eventDefined] == undefined && event.params[eventDefined] == undefined)
                                liList[key].style.display = 'none';
                            else
                                liList[key].style.display = 'block';
                        }
                        if (shapeOntrue) {
                            if (shape[shapeOntrue] && shape[shapeOntrue]() == true)
                                liList[key].style.display = 'block';
                            else
                                liList[key].style.display = 'none';
                        }


                        if (shapeOnfalse) {
                            if (shape[shapeOnfalse] && shape[shapeOnfalse]() == false)
                                liList[key].style.display = 'block';
                            else
                                liList[key].style.display = 'none';
                        }
                    }
                    //wyświetlanie odpowiednich podpunktów listy na podstawie atrybutów data - end

                    this.shownUl = ul;
                    this.currentShape = shape;
                    this.event = event;
                    break;
                }
            }
        }

        public dismiss(): void {
            if (this.shownUl)
                this.shownUl.style.display = 'none';

            this.shownUl = null;
            this.currentShape = null;
            this.event = null;
        }

        protected onClick(liElement: HTMLLIElement) {
            let action: string = liElement.dataset['action'];

            try {
                Factory.UserActionFactory.invokeAction(action, 'ContextMenu', this.event);
            }
            catch (e) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, e.message);
                alert.show();
            }

            this.dismiss()
        }

        private onOutsideMenuClickListener(): void {
            if (this.preventOutsideMenuClickDismiss)
                this.preventOutsideMenuClickDismiss = false;
            else
                this.dismiss();
        }

        public get preventOutsideMenuClickDismiss(): boolean {
            return this._preventOutsideMenuClickDismiss;
        }

        public set preventOutsideMenuClickDismiss(value: boolean) {
            this._preventOutsideMenuClickDismiss = value;
        }

        public get shown(): boolean {
            return this.shownUl != null;
        }
    }
}