/**
 * Created by Mateusz Lipowski on 24.10.2016.
 */

namespace Entity.View.Window {
    export class AssignOfferWindow extends Entity.View.Window.Window {
        protected updateCallbackId: number;
        protected rangePolygon: Entity.Map.Shape.RangePolygon;
        protected initialized: boolean = false;

        protected table: HTMLTableElement = null;
        protected addOfferSelect: HTMLSelectElement = null;
        protected addOfferButton: HTMLInputElement = null;

        protected proxyListenerAddOfferButtonClick: (e: MouseEvent) => void;
        protected proxyListenerRemoveOfferButtonClick: (e: MouseEvent) => void;
        protected proxyListenerPrimaryOfferRadioChange: (e: Event) => void;

        public constructor(windowContent: HTMLDivElement, windowContainerIndex: number = null) {
            super(windowContent, windowContainerIndex);

            this.updateCallbackId = App.getInstance().service.callbackContainer.pushCallback('offers', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.reload();
                return true;
            });

            this.proxyListenerAddOfferButtonClick = (e: MouseEvent): void => {
                this.onAddOfferButtonClick();
            };

            this.proxyListenerRemoveOfferButtonClick = (e: MouseEvent): void => {
                this.onRemoveOfferButtonClick(e.target as HTMLInputElement);
            };

            this.proxyListenerPrimaryOfferRadioChange = (e: Event): void => {
                this.onPrimaryOfferRadioChange(e.target as HTMLInputElement);
            };
        }

        public showForRange(rangePolygon: Entity.Map.Shape.RangePolygon): void {
            this.rangePolygon = rangePolygon;
            this.reload(true);
        }

        public reload(firstLoad: boolean = false): void {
            if(firstLoad) {
                this.show('assign_offers');
                this.initView();
            }

            this.populateOffers();
        }

        public dismiss(): void {
            let callbackContainer: Control.CallbackContainer.CallbackContainer = App.getInstance().service.callbackContainer;
            callbackContainer.deleteCallbackById('offers', 'update', this.updateCallbackId);
            this.addOfferButton.removeEventListener('click', this.proxyListenerAddOfferButtonClick);
            super.dismiss();
        }

        protected onAddOfferButtonClick(): void {
            let offerId: number = parseInt(this.addOfferSelect.value);
            if(offerId <= 0)
                throw new Error('Nie można dodać oferty (błędne ID)');

            let offer: Entity.Map.NetworkRange.Offer = App.getInstance().entityContainer.findOffer(offerId);
            if(!offer)
                throw new Error('Nie ma takiej oferty');

            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('AssignOffer', {range_id: this.rangePolygon.id, offer_id: offer.id}, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.message);
                    alert.show();

                    this.rangePolygon.addOffer(offer);
                    App.getInstance().service.callbackContainer.invokeCallbacks('offers', new Control.CallbackContainer.Event('update', this, {}));
                }
            });
        }

        protected onRemoveOfferButtonClick(button: HTMLInputElement): void {
            let offerId: number = parseInt(button.dataset['offer_id']);
            if(offerId <= 0)
                throw new Error('Nie można usunąć oferty (błędne ID)');

            if(!confirm('Na pewno'))
                return;

            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('RemoveOffer', {range_id: this.rangePolygon.id, offer_id: offerId}, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.message);
                    alert.show();

                    this.rangePolygon.removeOffer(offerId);
                    App.getInstance().service.callbackContainer.invokeCallbacks('offers', new Control.CallbackContainer.Event('update', this, {}));
                }
            });
        }

        protected onPrimaryOfferRadioChange(radio: HTMLInputElement): void {
            let offerId: number = parseInt(radio.value);
            if(offerId <= 0)
                throw new Error('Nie można ustawić oferty głównej (błędne ID)');

            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('SetPrimaryOffer', {range_id: this.rangePolygon.id, offer_id: offerId}, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                }
                else {
                    let offer: Entity.Map.NetworkRange.Offer = null;
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.message);
                    alert.show();

                    for(let rangeOffer of this.rangePolygon.rangeOffers) {
                        if(!rangeOffer)
                            continue;

                        rangeOffer.primary = false;
                        if(rangeOffer.offer.id == offerId) {
                            offer = rangeOffer.offer;
                            rangeOffer.primary = true;
                        }
                    }

                    App.getInstance().service.callbackContainer.invokeCallbacks('offers', new Control.CallbackContainer.Event('update', this, {}));
                    App.getInstance().service.callbackContainer.invokeCallbacks('offer', new Control.CallbackContainer.Event('update', offer, {}));
                }
            });
        }

        protected initView(): void {
            if(this.initialized)
                return;

            let availableOffers: Entity.Map.NetworkRange.Offer[] = App.getInstance().entityContainer.offers;

            this.setWide();

            this.table = this.form.getElementsByTagName('table')[0] as HTMLTableElement;
            this.addOfferSelect = this.form.getElementsByClassName('add-offer-select')[0] as HTMLSelectElement;
            this.addOfferButton = this.form.getElementsByClassName('add-offer')[0] as HTMLInputElement;
            if(!this.table) {
                throw new Error('Entity.View.DevicesWindow.initView: Can\'t find table element');
            }

            for(let offer of availableOffers) {
                if(!offer)
                    continue;

                let option: HTMLOptionElement = document.createElement('option');
                option.textContent = offer.name;
                option.value = offer.id.toString();
                this.addOfferSelect.appendChild(option);
            }

            this.addOfferButton.addEventListener('click', this.proxyListenerAddOfferButtonClick);

            this.initialized = true;
        }

        protected populateOffers(): void {
            if(!this.table) {
                throw new Error('Entity.View.DevicesWindow.populateDevices: Table element is undefined');
            }

            this.removeAllChildren(this.table);
            this.createTableHeader();

            for(let rangeOffers of this.rangePolygon.rangeOffers) {
                this.appendOfferRow(rangeOffers);
            }
        }

        protected appendOfferRow(rangeOffer: Entity.Map.NetworkRange.RangeOffer): void {
            let primary = this.createRadio(rangeOffer.primary, rangeOffer.offer.id.toString(), null, 'offer-element primary', 'primary_offer');
            let id = this.createSpan(rangeOffer.offer.id.toString(), null, 'offer-element id');
            let name = this.createSpan(rangeOffer.offer.name, null, 'offer-element name');
            let color = this.createColor(rangeOffer.offer.color, null, 'offer-element color');
            let button = document.createElement('input') as HTMLInputElement;

            button.type = 'button';
            button.value = 'Usuń';
            button.setAttribute('data-offer_id', rangeOffer.offer_id.toString());

            primary.addEventListener('change', this.proxyListenerPrimaryOfferRadioChange);
            button.addEventListener('click', this.proxyListenerRemoveOfferButtonClick);

            let row: HTMLTableRowElement = null;
            row = this.createTableRow('td', primary, id, name, color, button);

            this.table.appendChild(row);
        }

        protected createTableHeader(): void {
            let row: HTMLTableRowElement = this.createTableRow('th', 'Główna', 'ID', 'Nazwa', 'Kolor', '');
            this.table.appendChild(row);
        }
    }
}
