/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class LinkTypeEdit extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected linkType: Entity.Map.Type.LinkType = null;
        protected form: HTMLFormElement = null;
        protected deleteButton: HTMLInputElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.linkType = this.event.target as Entity.Map.Type.LinkType;
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('link_type_edit');
            this.form = this.window.getForm();
            this.deleteButton = this.form['delete'] as HTMLInputElement;

            this.deleteButton.addEventListener('click', (e: MouseEvent): void => {
                if(confirm('Na pewno?')) {
                    this.sendDeleteRequest();
                }
            });

            this.initView();
            this.initFormSender();
        }

        protected initView(): void {
            this.form['id']['value'] = this.linkType.id;
            this.form['name']['value'] = this.linkType.name;
            this.form['color'].value = '#' + this.linkType.color;
            this.form['weight'].value = this.linkType.weight;
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    this.linkType.name = this.form['name']['value'];
                    this.linkType.color = this.form['remove_color'].checked ? '' : this.form['color'].value.substring(1);
                    this.linkType.weight = this.form['weight'].value;
                    this.app.service.callbackContainer.invokeCallbacks('linkType', new Control.CallbackContainer.Event('update', this.linkType, {}));

                    for(let link of this.app.entityContainer.findLinksByLinkTypeId(this.linkType.id)) {
                        if(!link)
                            continue;

                        link.update();
                    }

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }

        protected sendDeleteRequest(): void {
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('DeleteLinkType', {id: this.linkType.id}, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(requestSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.getMessage());
                else {
                    this.app.entityContainer.deleteLinkType(this.linkType.id);
                    this.app.service.callbackContainer.invokeCallbacks('linkType', new Control.CallbackContainer.Event('delete', this.linkType, {}));
                    this.app.service.callbackContainer.invokeCallbacks('linkType', new Control.CallbackContainer.Event('update', this.linkType, {}));
                    this.linkType = null;

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
