/**
 * Created by Mateusz Partyka on 16.09.2016.
 */
namespace Entity.Map.Marker.Node {
    export class Ticket extends Entity.Map.Marker.Marker {
        protected _id: number;
        protected _temat: string;
        protected _tresc: string;
        protected _adres: string;
        protected _autor: string;

        constructor(lat: number, lng: number, data: Object, map: google.maps.Map) {
            super(lat, lng, map);

            this._id = parseInt(data['id']);
            this._temat = data['temat'];
            this._tresc = data['tresc'];
            this._adres = data['adres'];
            this._autor = data['autor'];

            this.initializeMarker();
            this.update();
        }

        public update(): void {
            if(this._marker) {
                this._marker.setIcon(this.getIconObject());
                this.setMap(this._map);
            }

            this.determineVisibility();
        }

        public getDetails(): string {
            let lines: string[] = [];
            let details: Object = {};
            let detailsTable: string = '';
            lines.push('Ticket ' + this.ticketLink);
            lines.push('Autor: ' + this.author);
            lines.push(this._temat);
            lines.push(this._adres);

            details = {
                Treść: this.tresc,
            };

            for(let key in details) {
                detailsTable += '<tr><td style="text-align: right;">' + key + ': </td><td>' + details[key] + '</td></tr>';
            }
            detailsTable = '<table>' + detailsTable + '</table>';

            lines.push('<a href="#" onclick="this.parentNode.getElementsByClassName(\'infoDetails\')[0].style.display = \'block\'; this.style.display = \'none\'">Szczegóły</a><div class="infoDetails">' + detailsTable + '</div>');
            return lines.join('<br>');
        }

        protected onClick(params): void {
            let infoWindow: Entity.View.InfoWindows.InfoWindow = App.getInstance().service.infoWindow;

            infoWindow.setContent(this.getDetails());
            infoWindow.setPosition(params.latLng);
            infoWindow.open(this._map, this);
        }

        protected getIconObject(): Object {
            let svgPath = 'M0 1v16.981h4v5.019l7-5.019h13v-16.981h-24zm13 12h-8v-1h8v1zm6-3h-14v-1h14v1zm0-3h-14v-1h14v1z';
            return {
                path: svgPath,
                fillOpacity: 1,
                fillColor: '#ff40ff',
                strokeColor: '#000000',
                scale: 1.4,
                anchor: new google.maps.Point(4, 24)
            };
        }

        protected get ticketLink(): string {
            return '<a target="_blank" href="' + config.panelUrl + '?co=dziennik&temat=1&id=' + this._id + '">' + this._id + '</a>';
        }

        public get id(): number {
            return this._id;
        }

        public get tresc(): string {
            return this._tresc ? this._tresc : '?';
        }

        public get author(): string {
            return this._autor ? this._autor : ' - nieznany - ';
        }
    }
}
