
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuRange extends Action.SingularUserActionBase {
        protected stateBox: Entity.View.StateBox = null;
        protected menuItem: HTMLInputElement = null;
        protected switchDraggableCheckbox: HTMLInputElement= null;
        protected regionSelect: HTMLSelectElement = null;
        protected addRangeButton: HTMLInputElement = null;
        protected saveButton: HTMLInputElement = null;
        protected cancelButton: HTMLInputElement = null;
        protected regionId: number = 0;

        protected onRangePolygonChangeCallbackId: number;

        protected proxyListenerSelectChange: (e: Event) => void;
        protected proxyListenerStateBoxClose: (e: MouseEvent) => void;
        protected proxyListenerAddRangeButtonClick: (e: MouseEvent) => void;
        protected proxyListenerSaveButtonClick: (e: MouseEvent) => void;
        protected proxyListenerCancelButtonClick: (e: MouseEvent) => void;
        protected proxyListenerSwitchDraggableCheckboxChange: (e: Event) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);

            this.stateBox = new Entity.View.StateBox(document.getElementById('state-box') as HTMLScriptElement);

            this.proxyListenerSelectChange = (e: Event): void => {
                this.onSelectChange();
            };
            this.proxyListenerStateBoxClose = (e: MouseEvent): void => {
                this.onStateBoxClose();
            };
            this.proxyListenerAddRangeButtonClick = (e: MouseEvent): void => {
                this.addRangeButtonClick();
            };
            this.proxyListenerSaveButtonClick = (e: MouseEvent): void => {
                this.saveClick();
            };
            this.proxyListenerCancelButtonClick = (e: MouseEvent): void => {
                this.cancelClick();
            };
            this.proxyListenerSwitchDraggableCheckboxChange = (e: MouseEvent): void => {
                this.switchDraggable();
            };
        }

        /**
         * Wykonanie akcji, na podstawie statycznego pola "włącza i wyłącza" akcję, zamiast wykonywać wiele akcji pod rząd
         * @return void
         */
        public execute(): void {
            if(MenuRange.inExecution) {
                (MenuRange.inExecution as Action.MainMenu.MenuRange).exitEditMode();
                MenuRange.inExecution = null;
            }
            else {
                MenuRange.inExecution = this;
                this.menuItem = this.event.target as HTMLInputElement;
                this.menuItem.checked = true;
                this.enterEditMode();
            }
        }

        /**
         * Metoda wywoływana po kliknięciu krzyżyka w StateBox (zamknięcie, koniec akcji)
         * @return void
         */
        protected onStateBoxClose(): void {
            this.exitEditMode();
            MenuRange.inExecution = null;
        }

        /**
         * Metoda wywoływana po zmianie opcji selecta w StateBox (wybór regionu)
         * @return void
         */
        protected onSelectChange(): void {
            this.regionId = parseInt(this.regionSelect.value);
            this.app.service.rangeManager.editRegion(this.regionId);
        }

        /**
         * Metoda wywoływana po kliknięciu przycisku w StateBox (dodanie nowego obszaru)
         * @return void
         */
        protected addRangeButtonClick(): void {
            this.app.service.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON, Control.ManagerModes.DRAW_RANGE);
        }

        /**
         * Metoda wywoływana po kliknięciu przycisku w StateBox (zapis)
         * @return void
         */
        protected saveClick(): void {
            this.app.service.rangeManager.saveEdit();
            this.saveButton.disabled = true;
            this.cancelButton.disabled = true;
        }

        /**
         * Metoda wywoływana po kliknięciu przycisku w StateBox (anuluj)
         * @return void
         */
        protected cancelClick(): void {
            this.app.service.rangeManager.cancelEdit();
            this.saveButton.disabled = true;
            this.cancelButton.disabled = true;
        }

        /**
         * Metoda wywoływana po zmianie checkboxa w StateBox (włączenie i wyłączenie przenoszenia obszarów)
         * @return void
         */
        protected switchDraggable(): void {
            let checked: boolean = this.switchDraggableCheckbox.checked;
            App.getInstance().service.rangeManager.setDraggable(checked);
        }

        /**
         * Metoda rozpoczynająca edycję - gdy MenuRange.execute() wykonuje akcję
         * @return void
         */
        protected enterEditMode(): void {
            this.initView();
            this.regionSelect.dispatchEvent(new Event('change'));
        }

        /**
         * Metoda kończąca edycję - gdy MenuRange.execute() kończy akcję lub zamkniemy state box
         * @return void
         */
        protected exitEditMode(): void {
            this.app.service.rangeManager.endEdit();
            this.menuItem.checked = false;
            this.menuItem = null;
            this.app.service.callbackContainer.deleteCallbackById(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'change', this.onRangePolygonChangeCallbackId);
            this.regionSelect.removeEventListener('change', this.proxyListenerSelectChange);
            this.stateBox.getCloseButton().removeEventListener('click', this.proxyListenerStateBoxClose);
            this.addRangeButton.removeEventListener('click', this.proxyListenerAddRangeButtonClick);
            this.saveButton.removeEventListener('click', this.proxyListenerSaveButtonClick);
            this.stateBox.dismiss();
        }

        /**
         * Inicjalizacja widoku, czyli dodanie i wyszukanie odpowiednich elementów DOM, dodanie eventów do elementów
         * @return void
         */
        protected initView(): void {
            let stateBoxDiv: HTMLDivElement = document.createElement('div') as HTMLDivElement;
            let addRangeButtonRow: HTMLDivElement = document.createElement('div') as HTMLDivElement;
            let saveRangeButtonRow: HTMLDivElement = document.createElement('div') as HTMLDivElement;
            let switchDraggableLabel: HTMLLabelElement = document.createElement('label') as HTMLLabelElement;
            let switchDraggableSpan: HTMLSpanElement = document.createElement('span') as HTMLSpanElement;
            this.switchDraggableCheckbox = document.createElement('input') as HTMLInputElement;
            this.regionSelect = document.createElement('select') as HTMLSelectElement;
            this.addRangeButton = document.createElement('input') as HTMLInputElement;
            this.saveButton = document.createElement('input') as HTMLInputElement;
            this.cancelButton = document.createElement('input') as HTMLInputElement;

            addRangeButtonRow.className += 'range-region-button-row';
            saveRangeButtonRow.className += 'range-region-button-row';
            switchDraggableLabel.className += 'range-region-draggable-label';
            switchDraggableSpan.textContent = 'Przenoszenie obszarów';
            this.switchDraggableCheckbox.type = 'checkbox';
            this.switchDraggableCheckbox.checked = false;
            this.regionSelect.className += 'range-region custom-select';
            this.addRangeButton.type = 'button';
            this.addRangeButton.value = 'Dodaj obszar';
            this.saveButton.type = 'button';
            this.saveButton.value = 'Zapisz zmiany';
            this.saveButton.className += 'range-region-save';
            this.saveButton.disabled = true;
            this.cancelButton.type = 'button';
            this.cancelButton.value = 'Confnij zmiany';
            this.cancelButton.className += 'range-region-cancel';
            this.cancelButton.disabled = true;

            switchDraggableLabel.appendChild(switchDraggableSpan);
            switchDraggableLabel.appendChild(this.switchDraggableCheckbox);
            addRangeButtonRow.appendChild(this.addRangeButton);
            saveRangeButtonRow.appendChild(this.saveButton);
            saveRangeButtonRow.appendChild(this.cancelButton);
            stateBoxDiv.appendChild(this.regionSelect);
            stateBoxDiv.appendChild(switchDraggableLabel);
            stateBoxDiv.appendChild(addRangeButtonRow);
            stateBoxDiv.appendChild(saveRangeButtonRow);
            this.stateBox.show('Edycja zasięgu', stateBoxDiv);

            for(let region of this.app.entityContainer.regions) {
                if(!region)
                    continue;

                let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                option.value = region.id.toString();
                option.textContent = region.name;
                this.regionSelect.appendChild(option);
            }

            this.regionSelect.addEventListener('change', this.proxyListenerSelectChange);
            this.stateBox.getCloseButton().addEventListener('click', this.proxyListenerStateBoxClose);
            this.addRangeButton.addEventListener('click', this.proxyListenerAddRangeButtonClick);
            this.saveButton.addEventListener('click', this.proxyListenerSaveButtonClick);
            this.cancelButton.addEventListener('click', this.proxyListenerCancelButtonClick);
            this.switchDraggableCheckbox.addEventListener('change', this.proxyListenerSwitchDraggableCheckboxChange);

            this.onRangePolygonChangeCallbackId = this.app.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'change', (e: Control.CallbackContainer.Event): boolean => {
                this.saveButton.disabled = false;
                this.cancelButton.disabled = false;
                return true;
            });
        }
    }
}
