namespace Control.CallbackContainer {
    type EventCallback = (e: CallbackContainer.Event) => boolean;

    export class CallbackContainer {
        protected callbackStackSet: CallbackStackSet = new CallbackStackSet();

        public invokeCallbacks(source: string, e: CallbackContainer.Event): void {
            let callbacks: Callback[] = this.getCallbackArray(source, e);
            if(callbacks == null) {
                return;
            }

            for(let callback of callbacks)
            {
                let result = callback.callback(e);
                if(!result) {
                    break;
                }
            }

        }

        public pushCallback(source: string, event: string, callback: EventCallback): number {
            return this.callbackStackSet.pushCallback(source, event, callback);
        }

        public popCallback(source: string, event: string): boolean {
            return this.callbackStackSet.popCallback(source, event);
        }

        public deleteCallbackById(source: string, event: string, id: number): boolean {
            return this.callbackStackSet.deleteCallbackById(source, event, id);
        }

        public getCallbackArray(source: string, e: CallbackContainer.Event): Callback[] {
            return this.callbackStackSet.getCallbackArray(source, e.type);
        }
    }

    class CallbackStackSet {
        protected callbacks: Object = {};
        protected idCounter: number = 0;

        public pushCallback(source: string, event: string, callback: EventCallback): number {
            if(!this.callbacks.hasOwnProperty(source))
                this.callbacks[source] = {};

            if(!this.callbacks[source].hasOwnProperty(event))
                this.callbacks[source][event] = [];

            let id: number = this.generateId();
            let callbackObject: Callback = new Callback(callback, id);
            this.callbacks[source][event].unshift(callbackObject);

            return id;
        }

        public popCallback(source: string, event: string): boolean {
            if(!this.callbacks.hasOwnProperty(source))
                return false;

            if(!this.callbacks[source].hasOwnProperty(event))
                return false;

            return this.callbacks[source][event].shift() !== undefined;
        }

        public deleteCallbackById(source: string, event: string, id: number): boolean {
            if(!this.callbacks.hasOwnProperty(source))
                return false;

            if(!this.callbacks[source].hasOwnProperty(event))
                return false;

            for(let i = 0; i < this.callbacks[source][event].length; i++) {
                if(this.callbacks[source][event][i].id === id) {
                    return this.callbacks[source][event].splice(i, 1).length > 0;
                }
            }

            return false;
        }

        public getCallbackArray(source: string, event: string): Callback[] {
            if(!this.callbacks.hasOwnProperty(source))
                return null;

            if(!this.callbacks[source].hasOwnProperty(event))
                return null;

            return this.callbacks[source][event];
        }

        protected generateId(): number {
            this.idCounter++;
            return this.idCounter;
        }
    }
}