
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class RangePolygonJoin extends Action.UserActionBase {
        protected rangePolygon: Entity.Map.Shape.RangePolygon = null;
        protected stateBox: Entity.View.StateBox = null;

        protected proxyListenerOnStateBoxClose: (e: MouseEvent) => void;
        protected onClickCallbackId: number = 0;

        public constructor(event: Action.UserActionEvent) {
            super(event);
            this.stateBox = new Entity.View.StateBox(document.getElementById('sub-state-box') as HTMLScriptElement);

            this.onClickCallbackId = this.app.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'click', (e: Control.CallbackContainer.Event): boolean => {
                this.onPolygonClick(e.target, e.params);
                return true;
            });

            this.proxyListenerOnStateBoxClose = (e: MouseEvent): void => {
                this.onStateBoxClose();
            };
        }

        public execute(): void {
            this.rangePolygon = this.event.target as Entity.Map.Shape.RangePolygon;
            if(this.rangePolygon.getEditable()) {
                this.initView();
            }
            else {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Nie wybrano obszaru');
                alert.show();
            }
        }

        protected onPolygonClick(targetRangePolygon: Entity.Map.Shape.RangePolygon, params: any): void {
            if(targetRangePolygon == this.rangePolygon) {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Obszar nie może być połączony sam ze sobą');
                alert.show();
            }
            else {
                if(this.rangePolygon.join(targetRangePolygon)) {
                    let alert = new Entity.View.Alert(Entity.View.AlertType.INFO, 'Połączono dwa obszary zasięgu');
                    alert.show();
                }
                else {
                    let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Nie można połączyć tych obszarów');
                    alert.show();
                }
                this.dispose();
            }
        }

        protected onStateBoxClose(): void {
            this.dispose();
        }

        protected initView(): void {
            this.stateBox.show('Wybierz drugi obszar');
            this.stateBox.getCloseButton().addEventListener('click', this.proxyListenerOnStateBoxClose);
        }


        protected dispose(): void {
            if(this.stateBox) {
                let button = this.stateBox.getCloseButton();
                if(button)
                    button.removeEventListener('click', this.proxyListenerOnStateBoxClose);

                this.stateBox.dismiss();
            }

            this.app.service.callbackContainer.deleteCallbackById(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'click', this.onClickCallbackId);
        }
    }
}
