/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class MarkerDevices extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let node: Entity.Map.Marker.Node.Node = this.event.target as Entity.Map.Marker.Node.Node;
            let devicesWindow: Entity.View.Window.DevicesWindow = this.app.service.windowContainer.createWindow(Control.WindowClass.DEVICES_WINDOW) as Entity.View.Window.DevicesWindow;
            devicesWindow.showForNode(node);
        }
    }
}
