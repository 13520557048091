/**
 * Created by Mateusz Lipowski on 03.10.2016.
 */

namespace Action.ContextMenu {
    export class MarkerAddLink extends UserActionBase {
        protected window: Entity.View.Window.Window
        protected selectedNode: Entity.Map.Marker.Node.Node = null;
        protected stateBox: Entity.View.StateBox = null;
        protected formSender: Logic.FormSender = null;
        protected deviceNode: Entity.Map.Marker.Node.DeviceNode = null;

        protected onStateBoxClosed: (e: Event) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);

            this.deviceNode = event.target as Entity.Map.Marker.Node.DeviceNode;
            this.window = App.getInstance().service.windowContainer.createWindow();

            /**
             * Akcja po zamknięciu stateboxa
             *
             * @param e
             */
            this.onStateBoxClosed = (e: Event) => {
                App.getInstance().service.callbackContainer.popCallback('node', 'click');
                App.getInstance().entityContainer.toggleClients(true);

                this.stateBox.dismiss();
                this.stateBox.getCloseButton().removeEventListener('click', this.onStateBoxClosed);
            };
        };

        public execute(): void {
            this.stateBox = new Entity.View.StateBox(document.getElementById('state-box') as HTMLScriptElement);

            this.stateBox.show('Wybierz węzeł do połączenia');
            this.stateBox.getCloseButton().addEventListener('click', this.onStateBoxClosed);

            App.getInstance().entityContainer.toggleClients(false);

            App.getInstance().service.callbackContainer.pushCallback('node', 'click', (e: Control.CallbackContainer.Event): boolean => {
                this.selectedNode = e.target as Entity.Map.Marker.Node.Node;
                this.onStateBoxClosed(null);

                App.getInstance().entityContainer.toggleClients(true);

                this.showAddLinkWindow();

                return false;
            });
        }

        /**
         * Pokazuje i inicjuje okno dodawania połączenia
         */
        protected showAddLinkWindow() {
            this.window.show('link_add');
            let form: HTMLFormElement = this.window.getForm() as HTMLFormElement;


            this.window.setOnDismiss(() => {
                if(this.formSender) {
                    this.formSender.dispose();
                    this.formSender = null;
                }
            });


            //uzupełnianie formularza:
            let categories: Entity.Map.Type.Category[] = App.getInstance().entityContainer.categories;
            let linkTypes: Entity.Map.Type.LinkType[] = App.getInstance().entityContainer.linkTypes;
            let node1Info = form.getElementsByClassName('node1-info')[0];
            let node2Info = form.getElementsByClassName('node2-info')[0];

            let subnodes: Object[] = [];
            for(let subnode of this.selectedNode.getSubnodes()) {
                if(!subnode)
                    continue;

                let subnodeDataObject: Object = {};
                subnodeDataObject['id'] = subnode.id;
                subnodeDataObject['name'] = subnode.id;

                if(subnode instanceof DeviceNode) {
                    let deviceNode: DeviceNode = subnode as DeviceNode;
                    subnodeDataObject['name'] = deviceNode.id + ': ' + deviceNode.getShortenedDeviceName() + (deviceNode.type ? ' (' + deviceNode.type.name + ')' : '');
                }

                subnodes.push(subnodeDataObject);
            }

            node1Info.textContent = this.getNodeInfo(this.deviceNode);
            node2Info.textContent = this.getNodeInfo(this.selectedNode);

            this.populateSelect(form['podwezel_id'], subnodes, 'id', 'name', null, ' - brak - ');
            this.populateSelect(form['kategoria_id'], categories, 'id', 'name', null, 'Auto');
            this.populateSelect(form['polaczenie_typ'], linkTypes, 'id', 'name', null, ' - brak - ');

            form['wezel1_id'].value = this.deviceNode.id;
            form['wezel2_id'].value = this.selectedNode.id;
            //uzupełnianie formularza - end


            this.formSender = new Logic.FormSender(form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    let selectedNode: Entity.Map.Marker.Node.Node = this.selectedNode;

                    if(parseInt(fields['podwezel_id']) > 0)
                        selectedNode = App.getInstance().entityContainer.findNode(fields['podwezel_id']);

                    let link = {
                        id: response.id,
                        kategoria_id: fields['kategoria_id'],
                        polaczenie_typ: fields['polaczenie_typ'],
                        wezel1_id: this.deviceNode.id,
                        wezel2_id: selectedNode.id,
                        parametry: fields['parametry'] ? fields['parametry'] : '',
                        backup: fields['backup']
                    };
                    App.getInstance().generateSingleLink(link, this.deviceNode, selectedNode);
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }

                alert.show();
            });
        }
    }
}
