/**
 * Created by Mateusz Partyka on 23.09.2016.
 */

namespace Entity.Map.Shape {
    import LatLng = google.maps.LatLng;

    export class Polygon extends Entity.Map.MapEntity {
        protected _polygon: google.maps.Polygon;
        private _polygonId: number;
        protected _description: string = '';
        private static _count: number = 1;

        constructor(polygon: google.maps.Polygon, text: string = '') {
            super();
            if (polygon)
                this.setPolygon(polygon);

            this._description = text;
        };

        protected setPolygon(polygon: google.maps.Polygon) {
            this._polygonId = Polygon._count++;
            this._polygon = polygon;

            this._polygon.addListener('rightclick', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.POLYGON, new Control.CallbackContainer.Event('rightclick', this, params));
            });

            this._polygon.addListener('click', (params) => {
                this.onClick(params['latLng']);
            });

            this.body = this._polygon;
            this.setupInputEvent(InputEvent.RightClick, (params => {
                App.getInstance().service.contextMenu.show(this, new Action.UserActionEvent(this, params, null));
            }));
        }

        get polygon() {
            return this._polygon;
        }

        get id() {
            return this._polygonId;
        }

        public toggleEditable(): void {
            let isEditable = this._polygon.getEditable();
            this._polygon.setEditable(!isEditable);
            this._polygon.setDraggable(!isEditable);
        }

        public getEditable(): boolean {
            return this._polygon.getEditable();
        }

        public setEditable(editable: boolean): void {
            this._polygon.setEditable(editable);
        }

        public getDraggable(): boolean {
            return this._polygon.getDraggable();
        }

        public setDraggable(draggable: boolean): void {
            this._polygon.setDraggable(draggable);
        }

        public getVisible(): boolean {
            return this._polygon.getVisible();
        }

        public setVisible(visible: boolean): void {
            this._polygon.setVisible(visible);
        }

        public getPath(): google.maps.MVCArray {
            return this._polygon.getPath();
        }

        public getBounds(): google.maps.LatLngBounds {
            let bounds = new google.maps.LatLngBounds();
            this.polygon.getPath().forEach((element: google.maps.LatLng, index: number): void => {
                if (!element)
                    return;

                bounds.extend(element);
            });
            return bounds;
        }

        public deleteVertex(vertexIndex: number): void {
            if (this._polygon.getPath().getLength() <= 2)
                return;

            this._polygon.getPath().removeAt(vertexIndex);
        }

        public dispose(): void {
            this._polygon.setMap(null);
            google.maps.event.clearListeners(this._polygon, 'rightclick');
            this._polygon = null;
        }

        public getLink(): string {
            let polygonLink = 'https://' + location.hostname + '?polygon=';
            polygonLink += JSON.stringify(this._polygon.getPath().getArray()) + '&';
            return polygonLink;
        }

        public onClick(latLng: google.maps.LatLng): void {
            let infoWindow: Entity.View.InfoWindows.InfoWindow = App.getInstance().service.infoWindow;
            let content: string[] = [];
            let gps: string = Logic.Util.shortenGPS(latLng.lat() + ',' + latLng.lng());
            let zoom: number = App.getInstance().service.map.getZoom();
            let geocode: Logic.Geocode = new Logic.Geocode();
            let elevation: Logic.Elevation = new Logic.Elevation(latLng);
            let polygonPath = this._polygon.getPath().getArray();
            let area = google.maps.geometry.spherical.computeArea(polygonPath);
            let length = google.maps.geometry.spherical.computeLength(polygonPath.concat(polygonPath[0]));
            let link = this.getLink() + 'center=' + gps + '&zoom=' + zoom;
            let textarea = document.createElement('textarea');
            let container = document.createElement('div');
            let a: HTMLAnchorElement;
            container.className = 'iw-container';


            geocode.getAddress(latLng.lat(), latLng.lng(), (address: string): void => {
                content[0] = address;
                content[1] = '<a href="?center=' + gps + '&zoom=' + zoom + '">' + gps + '</a>';
                content[2] = 'Wysokość NPM: ...';

                infoWindow.setPosition(latLng);
                container.innerHTML = content.join('<br>');
                infoWindow.open(App.getInstance().service.map);

                history.replaceState({
                    lat: latLng.lat(),
                    lng: latLng.lng(),
                    address
                }, 'Mapy Wave - ' + address, link);

                content[3] = "<hr style='border: 0; margin: 0.25em;'><a href='" + link + '&text=' + encodeURIComponent(this._description) + "' class='polygon-link'>Link do obszaru</a>";
                content[4] = 'Powierzchnia: ' + (area / 1000000).toFixed(2).replace('.', ',') + ' km²';
                content[5] = 'Obwód: ' + (length / 1000).toFixed(2).replace('.', ',') + ' km';

                elevation.requestElevation((height: number): void => {
                    content[2] = 'Wysokość NPM: ' + (height === null ? 'Nie można pozyskać wysokości' : (Logic.Util.formatDecimal(height, ' m')));
                    container.innerHTML = content.join('<br>');
                    container.appendChild(textarea);
                    infoWindow.setContent(container);
                });

                textarea.className = 'link-description';
                textarea.value = this._description;
                textarea.addEventListener('keyup', (event: Event) => {
                    this._description = textarea.value;
                    if (!a) a = document.querySelector('.polygon-link');
                    if (this._description)
                        a.href = link + '&text=' + encodeURIComponent(this._description);
                    textarea.classList.add('is-visible');
                });

                if (this._description)
                    textarea.classList.add('is-visible');


                container.appendChild(textarea);
            });
        }
    }
}