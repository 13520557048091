/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class MarkerDelete extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let node: Entity.Map.Marker.Node.DeviceNode = this.event.target as Entity.Map.Marker.Node.DeviceNode;
            if (confirm(`Na pewno chcesz usunąć węzeł ${node.id}?`)) {
                let sender = new Logic.RequestSender();

                sender.sendRequest('DeleteNode', {id: node.id}, (action: string, fields: Object, response: any): void => {
                    if (sender.isError()) {
                        let alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, sender.getMessage());
                        alert.show();
                    } else {
                        this.app.entityContainer.deleteDevice(node.id);
                        node.remove();
                        node = null;

                        let alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, sender.getMessage());
                        alert.show();
                    }
                });
            }
        }
    }
}
