/**
 * Created by Mateusz Partyka on 16.09.2016.
 */
namespace Entity.Map.Marker.Node {
    export class DeviceNode extends Node {
        protected _name: string;
        protected _ip: string;
        protected _address: string;
        protected _vlan: string;
        protected _comment: string;
        protected _gps: string;

        protected _clientNodes: Entity.Map.Marker.Node.ClientNode[] = [];

        private _clientIps: string[];
        private _associatedIps: string[];

        constructor(id: number, lat: number, lng: number, data: Object, map: google.maps.Map) {
            super(id, lat, lng, data, map);

            this._name = data['nazwa'];
            this._ip = data['ip'];
            this._address = data['adres'];
            this._vlan = data['vlan'];
            this._comment = data['komentarz'];
            this._gps = data['gps'];

            this.update();
        }

        public update(): void {
            if(!this._name)
                this._name = '';
            if(!this._ip)
                this._ip = '';
            if(!this._address)
                this._address = '';
            if(!this._vlan)
                this._vlan = '';
            if(!this._comment)
                this._comment = '';
            if(!this._gps)
                this._gps = '';

            if(!this._ip)
                this._ip = '0.0.0.0';
            else if(this._ip != '0.0.0.0') {
                this._ip = this._ip.split('.').map(function (elem) {
                    return parseInt(elem, 10);
                }).join('.');
            }

            //gdy jest podwęzłem:
            if(this._node_id > 0) {
                this._parent = App.getInstance().entityContainer.findNode(this._node_id.toString());
                if(this._parent) {
                    let position: number = 0;

                    if(this.parent.getSubnode(this.id))
                        position = this._parent.getSubnodePosition(this.id);
                    else
                        position = this.parent.getSubnodesCount() + 1;

                    let offset: number = 0.00025 * position;
                    let parentLatLng: google.maps.LatLng = this._parent.getPositionObject();
                    let subnodeLatLng: google.maps.LatLng = new google.maps.LatLng(parentLatLng.lat(), parentLatLng.lng() + offset);

                    this._lat = subnodeLatLng.lat();
                    this._lng = subnodeLatLng.lng();
                    this._marker.setPosition(subnodeLatLng);

                    if(!this.parent.getSubnode(this.id))
                        this._parent.addSubnode(this);
                }
            }
            else {
                this._parent = null;
            }
            //gdy jest podwęzłem - end

            this._gps = this._lat + ',' + this._lng;
            this.updateMarker();
            this._marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1);
        }

        public registerClientNode(clientNode: Entity.Map.Marker.Node.ClientNode): void {
            this._clientNodes.push(clientNode);
        }

        public showNode(): void {
            super.showNode();

            if(!this._clientNodes)
                return;

            for(let clientNode of this._clientNodes) {
                if(!clientNode)
                    continue;

                clientNode.showNode();
            }
        }

        public hideNode(): void {
            super.hideNode();

            if(!this._clientNodes)
                return;

            for(let clientNode of this._clientNodes) {
                if(!clientNode)
                    continue;

                clientNode.hideNode();
            }
        }

        public toggleDraggable(): void {
            if(!this._marker)
                return;

            let isDraggable = this._marker.getDraggable();
            this._marker.setDraggable(!isDraggable);

            if (!isDraggable) {
                this._latBeforeDrag = this._lat;
                this._lngBeforeDrag = this._lng;
                this._marker.setIcon(Node.ICON_DRAG);
            } else {
                let requestSender: Logic.RequestSender = new Logic.RequestSender();
                let newGps: string = '';

                this._lat = this._marker.getPosition().lat();
                this._lng = this._marker.getPosition().lng();

                newGps = this._lat + ',' + this._lng;

                if(this._gps != newGps) {
                    this._gps = newGps;

                    requestSender.sendRequest('EditNode', {
                        id: this._id,
                        gps: newGps
                    }, (action: string, fields: Object, response: any): void => {
                        if (requestSender.isError()) {
                            let alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.getMessage());

                            this._lat = this._latBeforeDrag;
                            this._lng = this._lngBeforeDrag;
                            this._marker.setPosition(new google.maps.LatLng(this._lat, this._lng));
                            for(let polyline of this._polylines)
                                polyline.update();
                            alert.show();
                        } else {
                            let alert = new Entity.View.Alert(Entity.View.AlertType.INFO, requestSender.getMessage());

                            this.update();

                            alert.show();
                        }
                    });
                }
                this._marker.setIcon(this.getIconPath());
            }
        }

        public getDetails(additionalData: Object = null): string {
            let subnodes: Object = this.getSubnodes();
            let lines: string[] = [];
            let details: Object = {};
            let detailsTable: string = '';
            let subnodesTable: string = '';
            let devicesTable: string = '';
            let elevation: string = '';

            //górne linijki (podstawowe informacje o węźle):
            lines.push('<div class="infoRow"><a href="https://panel.wave.com.pl/?co=wezly&akcja=wezel&id=' + this._id + '">' + this.getShortenedDeviceName() + '</a> - ' + this._ip + '</div>'); //TG-EDIT 2020-12-17
            if(this.type) {
                if(this.type.category)
                    lines.push('<div class="infoRow">' + this.type.name + ' (' + this.type.category.name + ')' + '</div>');
                else {
                    lines.push('<div class="infoRow">' + this.type.name + '</div>');
                }
            }
            else
                lines.push('<div class="infoRow"><b style="color: red;">BRAK TYPU</b></div>');
            lines.push('<div class="infoRow">' + this._address + '</div>');

            if(this.device)
                lines.push('<div class="infoRow">' + this.device.detailedName + '</div>');
            //górne linijki (podstawowe informacje o węźle) - end


            //szczegóły:
            details = {
                ID: this.id,
                Nazwa: this.name,
                Komentarz: this.comment,
                VLAN: this.vlan,
                GPS: this.gps,
                Region: this.regionName
            };

            for(let key in details) {
                detailsTable += '<tr><td style="text-align: right;">' + key + ': </td><td>' + details[key] + '</td></tr>';
            }
            detailsTable = '<table>' + detailsTable + '</table>';
            //szczegóły - end


            //podwęzły:
            for(let key in subnodes) {
                if(subnodes.hasOwnProperty(key) && subnodes[key]) {
                    let subnode: Entity.Map.Marker.Node.DeviceNode = subnodes[key];
                    subnodesTable += '<tr><td style="text-align: right;">' + subnode.ip + ': </td><td>' + subnode.name + '</td></tr>';
                }
            }
            if(subnodesTable)
                subnodesTable = '<div class="infoRow infoSubnodesHeader">Podwęzły:</div><table cellspacing="0">' + subnodesTable + '</table>';
            //podwęzły - end


            //dodatkowe dane, ładowane po wyświetleniu info window:
            if(additionalData && additionalData['devices']) {
                for(let key in additionalData['devices']) {
                    if(additionalData['devices'].hasOwnProperty(key) && additionalData['devices'][key]) {
                        let device: Entity.Device.Device = additionalData['devices'][key];
                        devicesTable += '<tr><td style="text-align: right;">'
                            + device.name + '</td><td>'
                            + Logic.Util.generateIpAddressLink(device.ip) + '</td><td>'
                            + device.ssid + '</td><td>'
                            + device.freq + '</td><td>'
                            + (device.mikrotik ? 'mikrotik ' : '')
                            + (device.ubiquiti ? 'ubiquiti ' : '')
                            + '</td></tr>';
                    }
                }
                if(devicesTable)
                    devicesTable = '<div class="infoRow infoSubnodesHeader">Urządzenia:</div><table cellspacing="2" style="text-align: center;">' + devicesTable + '</table>';
            }
            if(additionalData && additionalData['elevation']) {
                elevation = '<div class="infoRow infoSubnodesHeader" style="display: inline-block">Wysokość NPM:&nbsp;&nbsp;</div>' + Logic.Util.formatDecimal(additionalData['elevation'], ' m');
            }
            //dodatkowe dane, ładowane po wyświetleniu info window - end

            lines.push('<a href="#" onclick="this.parentNode.getElementsByClassName(\'infoDetails\')[0].style.display = \'block\'; this.style.display = \'none\'">Szczegóły</a><div class="infoDetails">' + detailsTable + subnodesTable + devicesTable + elevation + '</div>');
            return lines.join('');
        }

        public getShortenedDeviceName(): string {
            /*
            if(!this._adres)
                return '?';

            let letter = this._adres.split(' ')[1][0]; // np. Gdynia -> G
            let digit = parseInt(this._ip.split('.')[2]);
            return `${letter}${digit}`;
            */

            return this.name ? this.name : ' - brak nazwy - ';
        }

        public getShortAddress():string {
            return this._address ? this._address.split(',')[1] : '';
        }

        public getDistanceFrom(point: google.maps.LatLng): number {
            if(!point)
                return null;
            return google.maps.geometry.spherical.computeDistanceBetween(point, this.getPositionObject());
        }

        public isSubnode(): boolean {
            return this._node_id > 0;
        }

        protected onClick(params): void {
            super.onClick(params);

            let clientsInRadius: Entity.Map.Marker.Node.ClientNode[] = App.getInstance().entityContainer.findClientsInRadius(this.getPositionObject(), Entity.Map.Marker.Node.ClientNode.clientsRadius);
            if(clientsInRadius.length > 0) {
                for(let clientNode of clientsInRadius) {
                    if(!clientNode)
                        continue;

                    clientNode.moveBasedOnNearbyMarkers();
                }
            }
        }

        get name(): string {
            return this._name;
        }

        set name(name: string) {
            this._name = name;
        }

        get ip(): string {
            return this._ip ? this._ip : '';
        }

        set ip(ip:string) {
            this._ip = ip;
        }

        get address(): string {
            return this._address ? this._address : '';
        }

        set address(address: string) {
            this._address = address;
        }

        get vlan(): string {
            return this._vlan ? this._vlan : '';
        }

        set vlan(vlan:string) {
            this._vlan = vlan;
        }

        get comment():string {
            return this._comment ? this._comment : '';
        }

        set comment(comment: string) {
            this._comment = comment;
        }

        get gps(): string {
            return this._gps ? Logic.Util.shortenGPS(this._gps) : '';
        }

        set gps(gps:string) {
            this._gps = gps;
        }
    }
}
