/**
 * Created by Mateusz Lipowski on 19.09.2016.
 */

namespace Entity.View.Window {
    import Link = Entity.Map.Shape.Link.Link;
    interface Offset {
        x: number,
        y: number
    }

    export class Window extends Entity.View.ViewBase {
        protected windowContainerIndex: number;

        protected windowContent: HTMLDivElement;
        protected windowHeader: HTMLScriptElement;
        protected windowHeaderLabel: HTMLScriptElement;
        protected dismissButton: HTMLScriptElement;
        protected form: HTMLFormElement = null;
        protected offset: Offset = {x: 0, y: 0};
        protected isDragged: boolean = false;

        protected onMouseDown: (e) => void;
        protected onMouseUp: (e) => void;
        protected onMouseMove: (e) => void;
        protected onMouseLeave: (e) => void;
        protected onDismissButtonClick: (e) => void;

        protected onDismiss: () => void;

        public constructor(windowContent: HTMLDivElement, windowContainerIndex: number = null) {
            super();

            if(windowContent.tagName.toLowerCase() != 'div' || windowContent.className != 'window-content')
                throw new Error('Podany button nie jest właściwym elementem window (powinien być to div o klasie window-content)');

            this.windowContainerIndex = windowContainerIndex;

            this.windowContent = windowContent;
            this.windowHeader = this.windowContent.getElementsByClassName('window-header')[0] as HTMLScriptElement;
            this.windowHeaderLabel = this.windowHeader.getElementsByClassName('window-header-label')[0] as HTMLScriptElement;
            this.dismissButton = this.windowHeader.getElementsByClassName('dismiss-button')[0] as HTMLScriptElement;
        }

        public show(type: string):void {
            let self = this;
            let formClass = 'window-form-' + type;
            let forms = this.windowContent.getElementsByClassName(formClass);
            if (forms.length <= 0)
                throw new Error('WindowModel.show(): Nie znaleziono formularza o klasie "' + formClass + '"');

            if (forms.length != 1)
                throw new Error('WindowModel.show(): Znaleziono więcej niż jeden formularz o klasie "' + formClass + '"');

            this.form = forms[0] as HTMLFormElement;
            this.windowHeaderLabel.textContent = this.form.dataset['label'];

            this.onMouseDown = function (e) {
                let modalElement = e.target.parentNode;
                let rect = modalElement.getBoundingClientRect();
                self.offset.x = e.clientX - rect.left;
                self.offset.y = e.clientY - rect.top;

                self.isDragged = true;
            };

            this.onMouseUp = function (e) {
                self.isDragged = false;
            };

            this.onMouseMove = function (e) {
                if (!self.isDragged)
                    return true;

                e.preventDefault();
                self.moveModal(e.clientX, e.clientY);

                return false;
            };

            this.onMouseLeave = function (e) {
                if (!self.isDragged)
                    return true;

                self.moveModal(e.clientX, e.clientY);
            };


            this.onDismissButtonClick = function (e) {
                self.dismiss();
            };

            this.windowHeader.addEventListener('mousedown', this.onMouseDown);
            this.windowHeader.addEventListener('mouseup', this.onMouseUp);
            this.windowHeader.addEventListener('mousemove', this.onMouseMove);
            this.windowHeader.addEventListener('mouseleave', this.onMouseLeave);
            this.dismissButton.addEventListener('click', this.onDismissButtonClick);

            this.form.style.display = 'block';
            this.windowContent.style.display = 'block';
        };

        public moveModal(x: number, y: number):void {
            let left:number = x - this.offset.x;
            let top:number = y - this.offset.y;
            this.windowContent.style.left = left + 'px';
            this.windowContent.style.top = top + 'px';
        };

        public moveModalTo(x: number, y:number) : void {
            this.windowContent.style.left = x + 'px';
            this.windowContent.style.top = y + 'px';
        }

        public dismiss(dispose: boolean = true):void {
            this.windowHeader.removeEventListener('mousedown', this.onMouseDown);
            this.windowHeader.removeEventListener('mouseup', this.onMouseUp);
            this.windowHeader.removeEventListener('mousemove', this.onMouseMove);
            this.windowHeader.removeEventListener('mouseleave', this.onMouseLeave);
            this.dismissButton.removeEventListener('click', this.onDismissButtonClick);

            if (this.form != null) {
                this.form.style.display = 'none';
                this.form = null;
            }

            let children = this.windowContent.getElementsByTagName('form');
            for(let i = 0; i < children.length; i++) {
                let child = children[i];
                if(!child)
                    continue;

                child.style.display = 'none';
            }
            this.windowContent.style.display = 'none';

            if(this.onDismiss)
                this.onDismiss();

            if(dispose && this.windowContainerIndex != null)
                App.getInstance().service.windowContainer.disposeWindow(this.windowContainerIndex);
        };

        public setFormField(name: string, value: string): void {
            this.form[name].value = value;
        }

        public setOnDismiss(callback) {
            this.onDismiss = callback;
        }

        public getForm(): HTMLFormElement {
            return this.form;
        }

        public addWindowContentClass(className: string): void {
            this.windowContent.className += ' ' + className;
        }

        public setWide(): void {
            this.addWindowContentClass('window-wide');
        }

        public setVeryWide(): void {
            this.addWindowContentClass('window-very-wide');
        }

        public getHeight() : number {
            return this.windowContent.clientHeight;
        }

        public getWindowOffset() : Offset {
            return {x: this.windowContent.offsetLeft, y: this.windowContent.offsetTop};
        }
    }
}
