/**
 * Created by Mateusz Lipowski on 18.10.2016.
 */

namespace Control {
    export class EntityContainer {
//<editor-fold desc="Collections">
        private _regions: Entity.Map.Type.Region[] = [];
        private _categories: Entity.Map.Type.Category[] = [];
        private _types: Entity.Map.Type.Type[] = [];
        private _linkTypes: Entity.Map.Type.LinkType[] = [];
        private _devices: Entity.Map.Marker.Node.DeviceNode[] = [];
        private _clients: Entity.Map.Marker.Node.ClientNode[] = [];
        private _tickets: Entity.Map.Marker.Node.Ticket[] = [];
        private _links: Entity.Map.Shape.Link.Link[] = [];
        private _polygons: Entity.Map.Shape.Polygon[] = [];
        private _lines: Entity.Map.Shape.Line[] = [];
        private _markers: google.maps.Marker[] = [];
        private _polylines: google.maps.Polyline[] = [];
        private _offers: Entity.Map.NetworkRange.Offer[] = [];
        private _vehicles: Entity.Map.Marker.VehicleGPS.Vehicle[] = [];
//</editor-fold>

//<editor-fold desc="Getters - All">
        get regions(): Entity.Map.Type.Region[] {
            return this._regions;
        }

        get categories(): Entity.Map.Type.Category[] {
            return this._categories;
        }

        get types(): Entity.Map.Type.Type[] {
            return this._types;
        }

        get linkTypes(): Entity.Map.Type.LinkType[] {
            return this._linkTypes;
        }

        get devices(): Entity.Map.Marker.Node.DeviceNode[] {
            return this._devices;
        }

        get clients(): Entity.Map.Marker.Node.ClientNode[] {
            return this._clients;
        }

        get tickets(): Entity.Map.Marker.Node.Ticket[] {
            return this._tickets;
        }

        get links(): Entity.Map.Shape.Link.Link[] {
            return this._links;
        }

        get polygons(): Entity.Map.Shape.Polygon[] {
            return this._polygons;
        }

        get lines(): Entity.Map.Shape.Line[] {
            return this._lines;
        }

        get markers(): google.maps.Marker[] {
            return this._markers;
        }

        get polylines(): google.maps.Polyline[] {
            return this._polylines;
        }

        get offers(): Entity.Map.NetworkRange.Offer[] {
            return this._offers;
        }

        get vehicles(): Entity.Map.Marker.VehicleGPS.Vehicle[] {
            return this._vehicles;
        }

//</editor-fold>

//<editor-fold desc="Getters - Find">
        public findNode(nodeId: string): Entity.Map.Marker.Node.Node {
            if (this.devices[nodeId] === undefined)
                return null;

            return this.devices[nodeId];
        }

        public findNodesByDevicesId(deviceId: number): Entity.Map.Marker.Node.DeviceNode[] {
            let deviceNodes: Entity.Map.Marker.Node.DeviceNode[] = [];

            for (let deviceNode of this.devices) {
                if (!deviceNode)
                    continue;

                if (deviceNode.device_id == deviceId)
                    deviceNodes.push(deviceNode);
            }

            return deviceNodes;
        }

        public findNodesByTypeId(typeId: number): Entity.Map.Marker.Node.DeviceNode[] {
            let deviceNodes: Entity.Map.Marker.Node.DeviceNode[] = [];

            for (let deviceNode of this.devices) {
                if (!deviceNode)
                    continue;

                if (deviceNode.type_id == typeId)
                    deviceNodes.push(deviceNode);
            }

            return deviceNodes;
        }

        public findNodesByCategoryId(categoryId: number): Entity.Map.Marker.Node.DeviceNode[] {
            let deviceNodes: Entity.Map.Marker.Node.DeviceNode[] = [];

            for (let deviceNode of this.devices) {
                if (!deviceNode)
                    continue;

                if (deviceNode.type && deviceNode.type.category_id == categoryId)
                    deviceNodes.push(deviceNode);
            }

            return deviceNodes;
        }

        public findNodesByRegionId(regionId: number): Entity.Map.Marker.Node.DeviceNode[] {
            let deviceNodes: Entity.Map.Marker.Node.DeviceNode[] = [];

            for (let deviceNode of this.devices) {
                if (!deviceNode)
                    continue;

                if (deviceNode.region_id == regionId)
                    deviceNodes.push(deviceNode);
            }

            return deviceNodes;
        }

        public findDevicesInRadius(latLng: google.maps.LatLng, radius: number): Entity.Map.Marker.Node.DeviceNode[] {
            let result: Entity.Map.Marker.Node.DeviceNode[] = [];

            for (let node of this.devices) {
                if (!node)
                    continue;

                let distance: number = google.maps.geometry.spherical.computeDistanceBetween(latLng, node.getPositionObject());
                if (distance <= radius)
                    result.push(node);
            }

            return result;
        }

        public findClientsInRadius(latLng: google.maps.LatLng, radius: number): Entity.Map.Marker.Node.ClientNode[] {
            let result: Entity.Map.Marker.Node.ClientNode[] = [];

            for (let node of this.clients) {
                if (!node)
                    continue;

                let distance: number = google.maps.geometry.spherical.computeDistanceBetween(latLng, node.getPositionObject());
                if (distance <= radius)
                    result.push(node);
            }

            return result;
        }

        public findLinksByCategoryId(categoryId: number): Entity.Map.Shape.Link.Link[] {
            let links: Entity.Map.Shape.Link.Link[] = [];

            for (let link of this.links) {
                if (!link)
                    continue;

                if (link.category && link.category.id == categoryId)
                    links.push(link);
            }

            return links;
        }

        public findLinksByLinkTypeId(linkTypeId: number): Entity.Map.Shape.Link.Link[] {
            let links: Entity.Map.Shape.Link.Link[] = [];

            for (let link of this.links) {
                if (!link)
                    continue;

                if (link.linkType && link.linkType.id == linkTypeId)
                    links.push(link);
            }

            return links;
        }

        public findRegion(regionId: number): Entity.Map.Type.Region {
            if (this.regions[regionId] === undefined) {
                return null;
            }

            return this.regions[regionId];
        }

        public findCategory(categoryId: number): Entity.Map.Type.Category {
            if (this.categories[categoryId] === undefined)
                return null;

            return this.categories[categoryId];
        }

        public findType(typeId: number): Entity.Map.Type.Type {
            if (this.types[typeId] === undefined)
                return null;

            return this.types[typeId];
        }

        public findTypesByCategoryId(categoryId: number): Entity.Map.Type.Type[] {
            let types: Entity.Map.Type.Type[] = [];

            for (let type of this.types) {
                if (!type)
                    continue;

                if (type.category_id == categoryId)
                    types.push(type);
            }

            return types;
        }

        public findLinkType(linkTypeId: number): Entity.Map.Type.LinkType {
            if (this.linkTypes[linkTypeId] === undefined)
                return null;

            return this.linkTypes[linkTypeId];
        }

        public findOffer(offerId: number): Entity.Map.NetworkRange.Offer {
            if (this.offers[offerId] === undefined)
                return null;

            return this.offers[offerId];
        }

//</editor-fold>

//<editor-fold desc="Setters - Delete">
        public deletePolygon(id: number) {
            this.polygons.splice(id, 1);
        }

        public deleteLine(id: number) {
            this.lines.splice(id, 1);
        }

        public deleteLink(id: number) {
            this.links.splice(id, 1);
        }

        public deleteDevice(id: number) {
            let deviceNode: Entity.Map.Marker.Node.DeviceNode = this.devices[id];
            for(let subnode of deviceNode.getSubnodes()) {
                if(!subnode)
                    continue;

                this.devices.splice(subnode.id, 1);
                subnode.remove();
                subnode = null;
            }

            this.devices.splice(id, 1);
        }

        public deleteOffer(id: number) {
            this.offers.splice(id, 1);
        }

        public deleteType(id: number) {
            for (let deviceNode of this.findNodesByTypeId(id)) {
                deviceNode.type_id = 0;
                deviceNode.update();
            }

            this.types.splice(id, 1);
        }

        public deleteLinkType(id: number) {
            for (let link of this.findLinksByLinkTypeId(id)) {
                link.polaczenie_typ = 0;
                link.update();
            }

            this.linkTypes.splice(id, 1);
        }

        public deleteCategory(id: number) {
            for (let type of this.findTypesByCategoryId(id)) {
                type.category_id = 0;
                type.update();
            }

            for (let deviceNode of this.findNodesByCategoryId(id)) {
                deviceNode.update();
            }

            for (let link of this.findLinksByCategoryId(id)) {
                link.kategoria_id = 0;
                link.update();
            }

            this.categories.splice(id, 1);
        }

        public deleteRegion(id: number) {
            for (let deviceNode of this.findNodesByRegionId(id)) {
                deviceNode.region_id = 0;
                deviceNode.update();
            }

            this.regions.splice(id, 1);
        }

        public deleteTickets(): void {
            for (let ticketNode of this.tickets) {
                if (!ticketNode)
                    continue;

                ticketNode.marker.setMap(null);
                ticketNode = null;
            }

            this._tickets = [];
        }

        public toggleClients(visible: boolean) {
            let clients = this.clients;
            for (let client in clients) {
                if (clients.hasOwnProperty(client)) {
                    visible ? clients[client].showNode() : clients[client].hideNode();
                }
            }
        }

        public toggleDeviceType(type: string, visible: boolean) {
            for (let device of this.devices) {
                if (device && device.type && device.type.name === type)
                    visible ? device.showNode() : device.hideNode();
            }
        }

//</editor-fold>
    }
}