/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class PolygonDelete extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let polygon: Entity.Map.Shape.Polygon = this.event.target as Entity.Map.Shape.Polygon;
            this.app.entityContainer.deletePolygon(polygon.id);
            polygon.polygon.setMap(null);
            let alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, 'Usunięto obszar!');
            alert.show();
        }
    }
}
