/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action {
    export class UserActionEvent {
        public static CreateForCallbackEvent(event: Control.CallbackContainer.Event): Action.UserActionEvent {
            return new Action.UserActionEvent(event.target, event.params, null);
        }


        protected _target: any = null;
        protected _params: Object = null;
        protected _coordinates: google.maps.Point = null;

        public constructor(target: any, params: Object, coordinates: google.maps.Point = null) {
            this._target = target;
            this._params = params;
            this._coordinates = coordinates;
        }

        get target(): any {
            return this._target;
        }

        get params(): Object {
            return this._params;
        }

        get coordinates(): google.maps.Point {
            return this._coordinates;
        }
    }
}