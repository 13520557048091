
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuTicketsUpcoming extends Action.MenuTicketsBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);

        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            this.app.service.loader.show('Pobieranie przyszłch ticketów');
            requestSender.sendRequest('GetTickets', { upcoming: true }, (action: string, fields: Object, response: any) => {
                if (requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                    this.app.service.loader.dismiss();
                    return;
                }

                this.populateTickets(response);
                this.app.service.loader.dismiss();
            });
        }
    }
}
