
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LinkDelete extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let link: Entity.Map.Shape.Link.Link = this.event.target as Entity.Map.Shape.Link.Link;

            if (confirm(`Na pewno chcesz usunąć połączenie ${link.id}?`)) {
                let requestSender: Logic.RequestSender = new Logic.RequestSender();
                requestSender.sendRequest('DeleteLink', {id: link.id}, (action: string, fields: Object, response: any): void => {
                    if (requestSender.isError()) {
                        let alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.getMessage());
                        alert.show();
                    } else {
                        link.node1.removePolylineByID(link.id);
                        link.node2.removePolylineByID(link.id);
                        this.app.entityContainer.deleteLink(link.id);
                        link.remove();
                        link = null;

                        let alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.getMessage());
                        alert.show();
                    }
                });
            }
        }
    }
}
