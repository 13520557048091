/**
 * Created by Mateusz Lipowski on 27.10.2016.
 */

namespace Entity.Photo {
    export class Photo extends Entity.BaseEntity {
        protected _id: number;
        protected _node_id: number;
        protected _category_id: number;
        protected _name: string;
        protected _description: string;
        protected _register: boolean;


        public constructor(rowData: string[]) {
            super();
            this._id = parseInt(rowData['id']);
            this._node_id = parseInt(rowData['wezel_id']);
            this._category_id = parseInt(rowData['kategoria_id']);
            this._name = rowData['nazwa'];
            this._description = rowData['opis'];
            this._register = rowData['dziennik'] == '1';
        }

        public get src(): string {
            if(this.register) {
                return this.registerPhotoPath;
            }
            return '/photos?filename=' + this.id + '_' + this.name;
        }

        protected get registerPhotoPath(): string {
            return '';
        }

        public get id(): number {
            return this._id;
        }

        public get node_id(): number {
            return this._node_id;
        }

        public get category_id(): number {
            return this._category_id;
        }

        public get name(): string {
            return this._name;
        }

        public get description(): string {
            return this._description;
        }

        public get register(): boolean {
            return this._register;
        }


        public set id(id: number) {
            this._id = id;
        }

        public set node_id(node_id: number) {
            this._node_id = node_id;
        }

        public set category_id(category_id: number) {
            this._category_id = category_id;
        }

        public set name(name: string) {
            this._name = name;
        }

        public set description(description: string) {
            this._description = description;
        }

        public set register(register: boolean) {
            this._register = register;
        }
    }
}