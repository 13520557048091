/**
 * Created by Mateusz Lipowski on 27.10.2016.
 */

namespace Entity.Photo {
    export class Category extends Entity.BaseEntity {
        public _id: number;
        public _node_id: number;
        public _name: string;

        public constructor(rowData: string[]) {
            super();
            this._id = parseInt(rowData['id']);
            this._node_id = parseInt(rowData['wezel_id']);
            this._name = rowData['nazwa'];
        }

        public get id(): number {
            return this._id;
        }

        public get node_id(): number {
            return this._node_id;
        }

        public get name(): string {
            return this._name;
        }

        public set id(id: number) {
            this._id = id;
        }

        public set node_id(node_id: number) {
            this._node_id = node_id;
        }

        public set name(name: string) {
            this._name = name;
        }
    }
}
