/**
 * Created by Mateusz Lipowski on 13.10.2016.
 */


namespace Logic {
    import LocationSearchInput = Entity.View.LocationSearchInput;

    class URI {

        protected anchor: HTMLAnchorElement = null;

        public constructor(protected uri: string) {
            this.anchor = document.createElement('a') as HTMLAnchorElement;
            this.anchor.href = uri;
        }

        public get protocol(): string {
            return this.anchor.protocol;
        }

        public get hostname(): string {
            return this.anchor.hostname;
        }

        public get urn(): string {
            return this.anchor.pathname;
        }

        public get params(): string {
            return this.anchor.search;
        }
    }

    export class URIParser {
        protected uri: URI;

        public constructor() {
            this.uri = new URI(location.search);
        }

        public getParam(name: string): string {
            if (this.uri.params.length <= 1)
                return null;

            let paramsList = this.uri.params.substr(1); //pomija znak "?"
            for (let param of paramsList.split('&')) {
                if (param == name)
                    return '';

                let nameAndValue: string[] = param.split('=');
                if (nameAndValue[0] == name)
                    return decodeURIComponent(nameAndValue[1]);
            }
            return null;
        }

        public parseParamJSON(name: string): Object[] {
            let param = this.getParam(name);
            if (param == null)
                return null;

            try {
                let object: Object[] = JSON.parse(param);
                if (object == undefined || object == null || object.length <= 1)
                    return null;

                return object;
            }
            catch (e) {
                return null;
            }
        }

        public parseParamLatLng(name: string): google.maps.LatLng[] {
            let result: google.maps.LatLng[] = [];
            let object: Object[] = this.parseParamJSON(name);
            if (object == null)
                return null;

            for (let i = 0; i < object.length; i++) {
                result.push(new google.maps.LatLng(object[i]['lat'], object[i]['lng']));
            }

            return result;
        }
    }

    export class URIPolygonParser extends URIParser {
        protected static GET_PRAM_NAME = 'polygon';
        protected static GET_PRAM_NAME_TEXT = 'text';

        public constructor() {
            super();
        }

        public createPolygon(): Entity.Map.Shape.Polygon {
            if (this.parseParamJSON(URIPolygonParser.GET_PRAM_NAME) == null)
                return null;

            let googlePolygon: google.maps.Polygon = new google.maps.Polygon();

            googlePolygon.setPath(this.parseParamLatLng(URIPolygonParser.GET_PRAM_NAME));
            googlePolygon.setMap(App.getInstance().service.map);
            let text: string = this.getParam(URIPolygonParser.GET_PRAM_NAME_TEXT);

            return new Entity.Map.Shape.Polygon(googlePolygon, text);
        }
    }

    export class URILineParser extends URIParser {
        protected static GET_PRAM_NAME = 'line';
        protected static GET_PRAM_NAME_TEXT = 'text';
        protected lineParam: string;

        public constructor() {
            super();
        }

        public createLine(): Entity.Map.Shape.Line {
            if (this.parseParamJSON(URILineParser.GET_PRAM_NAME) == null)
                return null;

            let latLng: google.maps.LatLng[] = this.parseParamLatLng(URILineParser.GET_PRAM_NAME);
            let text: string = this.getParam(URILineParser.GET_PRAM_NAME_TEXT);
            return new Entity.Map.Shape.Line(latLng[0], latLng[1], text);
        }
    }

    export class URICenterParser extends URIParser {
        protected static GET_PRAM_NAME_CENTER = 'center';
        protected static GET_PRAM_NAME_ZOOM = 'zoom';
        protected static GET_PRAM_NAME_ADDRESS = 's';
        protected static GET_PRAM_NAME_TEXT = 'text';

        public constructor() {
            super();
        }

        public centerMap(map: google.maps.Map): void {
            let gps: string = this.getParam(URICenterParser.GET_PRAM_NAME_CENTER);
            let address: string = this.getParam(URICenterParser.GET_PRAM_NAME_ADDRESS);
            let zoom: string = this.getParam(URICenterParser.GET_PRAM_NAME_ZOOM);
            let text: string = this.getParam(URICenterParser.GET_PRAM_NAME_TEXT);


            if (gps != null) {
                let latLng: google.maps.LatLng = Util.gpsToLatLng(gps);
                if (latLng) {
                    try {
                        map.setCenter(latLng);
                        if (zoom) {
                            map.setZoom(parseInt(zoom));
                        }

                        let polygons = App.getInstance().entityContainer.polygons;
                        let lines = App.getInstance().entityContainer.lines;

                        if (polygons[0] && polygons[0] !== null)
                            polygons[0].onClick(latLng);
                        else if (lines[0] && lines[0] !== null)
                            lines[0].showInfoWindow();
                        else
                            google.maps.event.trigger(map, 'click', {latLng, text, writeHistory: false});
                    }
                    catch (e) {
                        console.dir('Logic.URICenterParser.centerMap: ' + e.getMessage());
                    }
                }
            }
            if (address != null) {
                App.getInstance().service.locationSearchInput.searchAddress(address);
            }
        }
    }

    export class URIRegionParser extends URIParser {
        protected static GET_PRAM_NAME = 'region';

        public constructor() {
            super();
        }

        public getRegionId(): string {
            let regionId: string = this.getParam(URIRegionParser.GET_PRAM_NAME);
            if (regionId != null) {
                return regionId;
            }
        }
    }

    export class URIMessageParser extends URIParser {
        protected static GET_PRAM_NAME = 'message';

        public constructor() {
            super();
        }

        public getMessage(): string {
            let message: string = this.getParam(URIMessageParser.GET_PRAM_NAME);
            if (message != null) {
                return decodeURIComponent(message).replace(/\+/g, ' ');
            }

            return null;
        }

        public showErrorAlert(): void {
            let message: string = this.getMessage();
            if (message) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, message);
                alert.show();
            }
        }
    }
}
