
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class MapGetRoute extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let gps1: google.maps.LatLng = new google.maps.LatLng(config.biuro.lat, config.biuro.lng);
            let gps2: google.maps.LatLng = this.event.params['latLng'];
            let directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();

            directionsService.route({
                origin: gps1,
                destination: gps2,
                travelMode: google.maps.TravelMode.DRIVING,
            }, (result: google.maps.DirectionsResult, status: google.maps.DirectionsStatus) => {
                if(status == google.maps.DirectionsStatus.OK) {
                    this.app.service.mapContainer.showRoute(result);
                }
            });
        }
    }
}
