
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class RangePolygonStickEdges extends Action.UserActionBase {
        protected rangePolygon: Entity.Map.Shape.RangePolygon = null;
        protected stateBox: Entity.View.StateBox = null;
        protected path: google.maps.MVCArray = null;
        protected edgeIndex: number = 0;
        protected vertexIndex1: number = 0;
        protected vertexIndex2: number = 0;
        protected invertCheckbox: HTMLInputElement = null;

        protected guidePolyline1: google.maps.Polyline = null;
        protected guidePolyline2: google.maps.Polyline = null;

        protected onPolygonClickCallbackId: number = 0;
        protected onMouseMoveInPolygonCallbackId: number = 0;
        protected onMouseOutOffPolygonCallbackId: number = 0;

        protected proxyListenerOnStateBoxClose: (e: MouseEvent) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);
            this.stateBox = new Entity.View.StateBox(document.getElementById('sub-state-box') as HTMLScriptElement);

            this.proxyListenerOnStateBoxClose = (e: MouseEvent): void => {
                this.onStateBoxClose();
            };
        }

        public execute(): void {
            this.rangePolygon = this.event.target as Entity.Map.Shape.RangePolygon;
            if(this.rangePolygon.getEditable() && this.event.params['edge'] != undefined) {
                this.initView();
            }
            else {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Brak krawędzi do przyklejenia');
                alert.show();
            }
        }

        protected initView(): void {
            let div: HTMLDivElement = document.createElement('div') as HTMLDivElement;
            let label: HTMLLabelElement = document.createElement('label') as HTMLLabelElement;
            this.path = this.rangePolygon.getPath();
            this.edgeIndex = this.event.params['edge'];
            this.vertexIndex1 = this.edgeIndex;
            this.vertexIndex2 = (this.edgeIndex + 1) % this.path.getLength();
            this.invertCheckbox = document.createElement('input') as HTMLInputElement;

            label.textContent = 'Odwróć';
            label.htmlFor = 'invert-checkbox';
            this.invertCheckbox.type = 'checkbox';
            this.invertCheckbox.id = 'invert-checkbox';
            div.appendChild(label);
            div.appendChild(this.invertCheckbox);

            this.stateBox.show('Wybierz drugą krawędź by przykleić', div);
            this.stateBox.getCloseButton().addEventListener('click', this.proxyListenerOnStateBoxClose);

            this.guidePolyline1 = new google.maps.Polyline({
                strokeColor: '#FF0000',
                strokeWeight: 2,
                zIndex: 200,
                path: [this.path.getAt(this.vertexIndex1), this.path.getAt(this.vertexIndex1)],
                map: this.app.service.map
            });
            this.guidePolyline2 = new google.maps.Polyline({
                strokeColor: '#FF0000',
                strokeWeight: 2,
                zIndex: 200,
                path: [this.path.getAt(this.vertexIndex2), this.path.getAt(this.vertexIndex2)],
                map: this.app.service.map
            });

            this.onPolygonClickCallbackId = this.app.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'click', (e: Control.CallbackContainer.Event): boolean => {
                this.onPolygonClick(e.target as Entity.Map.Shape.RangePolygon, e.params);
                return true;
            });
            this.onMouseMoveInPolygonCallbackId = this.app.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'mousemove', (e: Control.CallbackContainer.Event): boolean => {
                this.onMouseMoveInPolygon(e.target as Entity.Map.Shape.RangePolygon, e.params);
                return true;
            });
            this.onMouseOutOffPolygonCallbackId = this.app.service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'mouseout', (e: Control.CallbackContainer.Event): boolean => {
                this.onMouseMoveInPolygon(e.target as Entity.Map.Shape.RangePolygon, e.params);
                return true;
            });
        }

        protected onStateBoxClose(): void {
            this.dispose();
        }

        protected onMouseMoveInPolygon(targetPolygon: Entity.Map.Shape.RangePolygon, params): void {
            if(params['edge'] !== undefined) {
                let targetVertexIndex1: number = params['edge'];
                let targetVertexIndex2: number = (params['edge'] + 1) % targetPolygon.getPath().getLength();

                if(this.invertCheckbox.checked) {
                    targetVertexIndex1 = (params['edge'] + 1) % targetPolygon.getPath().getLength();
                    targetVertexIndex2 = params['edge'];
                }

                this.guidePolyline1.getPath().setAt(1, targetPolygon.getPath().getAt(targetVertexIndex1));
                this.guidePolyline2.getPath().setAt(1, targetPolygon.getPath().getAt(targetVertexIndex2));
            }
            else {
                this.guidePolyline1.getPath().setAt(1, this.path.getAt(this.vertexIndex1));
                this.guidePolyline2.getPath().setAt(1, this.path.getAt(this.vertexIndex2));
            }
        }

        protected onPolygonClick(targetPolygon: Entity.Map.Shape.RangePolygon, params): void {
            if(params['edge'] == undefined)
                return;

            let targetVertexIndex1: number = params['edge'];
            let targetVertexIndex2: number = (params['edge'] + 1) % targetPolygon.getPath().getLength();

            if(this.invertCheckbox.checked) {
                targetVertexIndex1 = (params['edge'] + 1) % targetPolygon.getPath().getLength();
                targetVertexIndex2 = params['edge'];
            }

            this.rangePolygon.getPath().setAt(this.vertexIndex1, targetPolygon.getPath().getAt(targetVertexIndex1));
            this.rangePolygon.getPath().setAt(this.vertexIndex2, targetPolygon.getPath().getAt(targetVertexIndex2));
            App.getInstance().service.rangeManager.registerEditedRangePolygon(this.rangePolygon);
            this.dispose();
        }

        protected dispose(): void {
            this.app.service.callbackContainer.deleteCallbackById(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'click', this.onPolygonClickCallbackId);
            this.app.service.callbackContainer.deleteCallbackById(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'mousemove', this.onMouseMoveInPolygonCallbackId);
            this.app.service.callbackContainer.deleteCallbackById(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'mouseout', this.onMouseOutOffPolygonCallbackId);

            this.guidePolyline1.setMap(null);
            this.guidePolyline2.setMap(null);
            this.guidePolyline1 = null;
            this.guidePolyline2 = null;

            if(this.stateBox) {
                let button = this.stateBox.getCloseButton();
                if(button)
                    button.removeEventListener('click', this.proxyListenerOnStateBoxClose);

                this.stateBox.dismiss();
            }
        }
    }
}
