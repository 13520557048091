/**
 * Created by Mateusz Lipowski on 06.10.2016.
 */

namespace Entity.Map.Type {
    export class Category extends Entity.Map.MapEntity {
        protected static DEFAULT_COLOR: string = '00E64D';

        protected _id: number = 0;
        protected _name: string = '';
        protected _color: string = null;
        protected _priority: number = 0;
        protected _isVisible: boolean = true;

        public constructor(rowData: Object) {
            super();
            this._id = parseInt(rowData['id']);
            this._name = rowData['nazwa'];
            this._color = rowData['kolor'];
            this._priority = parseFloat(rowData['priorytet_koloru']);

            App.getInstance().service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.APP, 'ready', (e: Control.CallbackContainer.Event): boolean => {
                this.onMapReady();
                return true;
            });
        }

        public update(rowData = null) {
            if(!this._id)
                throw new Error('Błędne ID dla kategorii (podane id: ' + rowData['id'] + ')');

            if(!this._name || this._name.length <= 0)
                throw new Error('Błędna nazwa dla kategorii ' + this._id + ' (podana nazwa: ' + rowData['nazwa'] + ')');

            if(!this._color || this._color.length <= 0)
                this._color = Category.DEFAULT_COLOR;
        }

        public updateNodes(): void {
            let collection: Control.EntityContainer = App.getInstance().entityContainer;
            collection.devices.map((device) => {
                if (device.type && device.type.category == this)
                    device.update();
            });
        }

        protected onMapReady(): void {
            let visible: string = Logic.Cookie.readCookie('category-' + this.id + '-visible');
            if(visible == 'false') {
                let menuInput: HTMLInputElement = document.getElementById('menu-category-' + this.id) as HTMLInputElement;
                this._isVisible = false;
                menuInput.checked = false;
                this.updateNodes();
            }
        }

        get id(): number {
            return this._id;
        }

        get name(): string {
            return this._name;
        }

        set name(name: string) {
            this._name = name;
        }

        get color(): string {
            return this._color;
        }

        set color(color: string) {
            this._color = color;
        }

        get priority(): number {
            return this._priority;
        }

        set priority(priority: number) {
            this._priority = priority;
        }

        get isVisible(): boolean {
            return this._isVisible;
        }

        set isVisible(isVisible) {
            this._isVisible = isVisible;
            Logic.Cookie.createCookie('category-' + this.id + '-visible', isVisible);
        }
    }
}
