
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LinkEditPathEnd extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let link: Entity.Map.Shape.Link.Link = this.event.target as Entity.Map.Shape.Link.Link;
            link.endPathEdit();

            let midPath: google.maps.MVCArray = link.midPath;
            let midPathSimpleObjectArray: Object[] = [];
            let sciezka: string = '';
            midPath.forEach((elem: any, i: number): void => {
                midPathSimpleObjectArray.push({lat: elem.lat(), lng: elem.lng()});
            });
            sciezka = JSON.stringify(midPathSimpleObjectArray);

            let sender: Logic.RequestSender = new Logic.RequestSender();
            sender.sendRequest('EditLink', {id: link.id, sciezka: sciezka}, (action: string, fields: Object, response: any): void => {
                if (sender.isError()) {
                    let alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, sender.getMessage());
                    alert.show();
                }
                else {
                    let alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, sender.getMessage());
                    alert.show();
                }
            });
        }
    }
}
