
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuEditRegion extends Action.UserActionBase {
        protected window: Entity.View.Window.Window = null;
        protected table: HTMLTableElement = null;

        protected onRegionUpdateCallbackId: number;
        protected proxyListenerEditRegionButtonClick: (e: MouseEvent) => void;
        protected proxyListenerAddRegionButtonClick: (e: MouseEvent) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);

            this.proxyListenerEditRegionButtonClick = (e: MouseEvent): void => {
                this.onEditRegionButtonClick(e.target as HTMLInputElement);
            };
            this.proxyListenerAddRegionButtonClick = (e: MouseEvent): void => {
                this.onAddRegionButtonClick(e.target as HTMLInputElement);
            };
        }

        public execute(): void {
            let menuItem = this.event.target;
            this.initView();
            this.populateRegions();

            this.onRegionUpdateCallbackId = this.app.service.callbackContainer.pushCallback('region', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.populateRegions();
                return true;
            });
        }

        protected onEditRegionButtonClick(button: HTMLInputElement): void {
            let regionId: number = parseInt(button.dataset['region_id']);
            let region: Entity.Map.Type.Region = this.app.entityContainer.findRegion(regionId);

            if(!region) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Wybrany region nie istnieje');
                alert.show();
                return;
            }

            Factory.UserActionFactory.invokeAction('RegionEdit', 'Other', new Action.UserActionEvent(region, {}));
        }

        protected onAddRegionButtonClick(button: HTMLInputElement): void {
            Factory.UserActionFactory.invokeAction('RegionAdd', 'Other', new Action.UserActionEvent(this, {}));
        }

        protected initView(): void {
            this.window = this.app.service.windowContainer.createWindow(Control.WindowClass.WINDOW);
            this.window.show('regions');
            this.window.setWide();
            this.window.setOnDismiss((): void => {
                this.dispose();
            });

            this.table = this.window.getForm().getElementsByClassName('regions-table')[0] as HTMLTableElement;
            if(!this.table)
                throw new Error('Brak tabeli regionów w oknie regionów');

            let newRegionButton: HTMLInputElement = this.window.getForm().getElementsByClassName('new-region')[0] as HTMLInputElement;
            newRegionButton.addEventListener('click', this.proxyListenerAddRegionButtonClick);
        }

        protected dispose(): void {
            this.app.service.callbackContainer.deleteCallbackById('region', 'update', this.onRegionUpdateCallbackId);
        }

        protected populateRegions(): void {
            if(!this.table) {
                throw new Error('UserAction.MainMenu.MenuEditRegion.populateRegions: Table element is undefined');
            }

            this.removeAllChildren(this.table);
            this.createTableHeader();

            for(let region of this.app.entityContainer.regions) {
                if(!region)
                    continue;

                this.appendRegionRow(region);
            }
        }

        protected appendRegionRow(region: Entity.Map.Type.Region): void {
            let id = this.createSpan(region.id.toString(), null, 'region-element id');
            let name = this.createSpan(region.name, null, 'region-element name');
            let prefix = this.createSpan(region.prefix, null, 'region-element prefix');
            let button = document.createElement('input') as HTMLInputElement;

            button.type = 'button';
            button.value = 'Edytuj';
            button.setAttribute('data-region_id', region.id.toString());
            button.addEventListener('click', this.proxyListenerEditRegionButtonClick);

            let row: HTMLTableRowElement = null;
            row = this.createTableRow('td', id, name, prefix, button);

            this.table.appendChild(row);
        }

        protected createTableHeader(): void {
            let row: HTMLTableRowElement = this.createTableRow('th', 'ID', 'Nazwa', 'Prefix', '');
            this.table.appendChild(row);
        }
    }
}
