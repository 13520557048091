/**
 * Created by Mateusz Lipowski on 24.11.2016.
 */

namespace Logic {
    export class Elevation {
        protected elevationService: google.maps.ElevationService = null;

        public constructor(protected latLng: google.maps.LatLng) {
            this.elevationService = new google.maps.ElevationService();
        }

        public requestElevation(callback: (elevetion: number) => void) {
            this.elevationService.getElevationForLocations({
                locations: [this.latLng]
            }, (results: google.maps.ElevationResult[], status: google.maps.ElevationStatus): void => {
                if(status === google.maps.ElevationStatus.OK && results[0]) {
                    let elevetion: number = results[0].elevation;
                    callback(Math.round(elevetion * 10) / 10);
                }
                else {
                    callback(null);
                }
            });
        }
    }
}
