
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class RangePolygonDelete extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let rangePolygon: Entity.Map.Shape.RangePolygon = this.event.target as Entity.Map.Shape.RangePolygon;
            rangePolygon.delete();
        }
    }
}
