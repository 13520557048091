/**
 * Created by Mateusz Partyka on 30.09.2016.
 */
namespace Entity.View {
    export class Alert extends Entity.View.ViewBase {
        private _element;
        private _type;
        private _title;
        private _message;
        private _header;
        private _body;
        private _closeButton;

        private static ALERT_MODEL = document.getElementsByClassName('alert-content')[0];

        constructor(type: number, message: string = '') {
            super();
            this._type = type;
            this._message = message;

            this._element = Alert.ALERT_MODEL.cloneNode(true);
            Alert.ALERT_MODEL.parentElement.appendChild(this._element);

            this.initializeTypeDependentValues();

            this._header = this._element.getElementsByClassName('alert-header')[0].getElementsByTagName('h2')[0];
            this._body = this._element.getElementsByClassName('alert-body')[0];
            this._closeButton = this._header.parentNode.getElementsByClassName('alert-close')[0];

            this._header.textContent = this._title;
            this._body.textContent = this._message;

            this._closeButton.addEventListener('click', () => {
                this.dismiss();
            });
        }

        private initializeTypeDependentValues() {
            switch (this._type) {
                case AlertType.ERROR:
                    this._title = 'Błąd!';
                    this._element.classList.add('alert-error');
                    break;

                case AlertType.WARNING:
                    this._title = 'Uwaga!';
                    this._element.classList.add('alert-warning');
                    break;

                case AlertType.SUCCESS:
                    this._title = 'Sukces!';
                    this._element.classList.add('alert-success');
                    break;

                case AlertType.INFO:
                default:
                    this._title = 'Informacja';
                    this._element.classList.add('alert-info');
                    break;
            }
        }

        public show(time = 5000): void {
            this._element.classList.add('visible');

            this.setTime(time);
        }

        public dismiss(): void {
            this._element.classList.remove('visible');
            this._element.parentElement.removeChild(this._element);
        }

        set message(message) {
            this._message = message;
        }

        get message() {
            return this._message;
        }

        private setTime(time:number = 5000) {
            let self = this;
            setTimeout(function() {
                self.dismiss();
            }, time);
        }
    }

    export class AlertType {
        public static INFO = 0;
        public static ERROR = 1;
        public static WARNING = 2;
        public static SUCCESS = 3;
    }
}



