/**
 * Created by Mateusz Lipowski on 11.10.2016.
 */

namespace Entity.Map.Shape {
    export class Line extends Entity.Map.MapEntity {
        protected _isComplete: boolean;
        protected _line: google.maps.Polyline = null;
        protected _marker1: google.maps.Marker = null;
        protected _marker2: google.maps.Marker = null;
        protected _infoWindow: Entity.View.InfoWindows.InfoWindow = null;
        protected _infoWindowContext: Object = null;
        protected _selectedVertexIndex: number = null;
        protected _selectedEdgeIndex: number = null;
        protected _description: string = '';

        protected onMarker1Click: (params: any) => void;
        protected onMarker2Click: (params: any) => void;
        protected onLineClick: (params: any) => void;
        protected onRightClick: (params: any) => void;
        protected onMouseMove: (params: any) => void;
        protected onMouseUp: (params: any) => void;
        protected onDrag: (params: any) => void;

        constructor(protected _point1: google.maps.LatLng, protected _point2: google.maps.LatLng = null, text: string = '') {
            super();
            this._infoWindow = App.getInstance().service.infoWindow;
            this._description = text;

            let markerOptions: google.maps.MarkerOptions = {
                position: null,
                animation: google.maps.Animation.DROP,
            };

            this.onMarker1Click = (params: any): void => {
                this._selectedVertexIndex = null;
                this._selectedEdgeIndex = null;
                this._infoWindowContext = this._marker1;
                this.openInfoWindow();
                this.updateInfoWindow();
            };
            this.onMarker2Click = (params: any): void => {
                this._selectedVertexIndex = null;
                this._selectedEdgeIndex = null;
                this._infoWindowContext = this._marker2;
                this.openInfoWindow();
                this.updateInfoWindow();
            };

            this.onLineClick = (params: any): void => {
                this._selectedVertexIndex = params['vertex'] === undefined ? null : params['vertex'];
                this._selectedEdgeIndex = params['edge'] === undefined ? null : params['edge'];
                this._infoWindowContext = this._line;
                this.openInfoWindow();
            };

            this.onRightClick = (params: any): void => {
                //App.getInstance().service.contextMenu.show(this, new Action.UserActionEvent(this, params));
            };

            this.onMouseMove = (params: any): void => {
                // this.updateInfoWindow();
            };

            this.onMouseUp = (params: any): void => {
                this.updateInfoWindow();
            };

            this.onDrag = (params: any): void => {
                if (this._marker1 && this._point1) {
                    this._point1 = this._marker1.getPosition();
                }

                if (this._marker2 && this._point2) {
                    this._point2 = this._marker2.getPosition();
                }

                this.updateLine();
                this.updateInfoWindow();
            };

            this._isComplete = _point2 != null;

            this._marker1 = new google.maps.Marker(markerOptions);
            this._marker2 = new google.maps.Marker(markerOptions);

            this.updateLine();
        }

        public deleteVertex(vertexIndex: number): void {
            if (vertexIndex <= 0 || vertexIndex >= this._line.getPath().getLength() - 1) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Nie można usunąć tego wierzchołka');
                alert.show();
                return;
            }

            this._line.getPath().removeAt(vertexIndex);
        }

        protected openInfoWindow(): void {
            let map: google.maps.Map = App.getInstance().service.map;
            this.updateInfoWindow();
            this._infoWindow.open(map, this._infoWindowContext);
        }

        protected updateInfoWindow(): void {
            if (this._infoWindow.isOpen && (this._infoWindow.context == this._line || this._infoWindow.context == this._marker1 || this._infoWindow.context == this._marker2)) {
                if (this._infoWindowContext instanceof google.maps.Marker) {
                    let marker: google.maps.Marker = this._infoWindowContext as google.maps.Marker;
                    this._infoWindow.setPosition(marker.getPosition());
                }
                else if (this._infoWindowContext instanceof google.maps.Polyline) {
                    let polyline: google.maps.Polyline = this._infoWindowContext as google.maps.Polyline;

                    if (this._selectedVertexIndex != null)
                        this._infoWindow.setPosition(this._line.getPath().getAt(this._selectedVertexIndex));
                    else
                        this._infoWindow.setPosition(this.middle);
                }

                if (this._selectedEdgeIndex != null)
                    this._infoWindow.setContent('Odległość części linii: ' + this.formattedLength);
                else {
                    let latLng = this._marker2.getPosition();
                    let gps: string = Logic.Util.shortenGPS(latLng.lat() + ',' + latLng.lng());
                    let zoom: number = App.getInstance().service.map.getZoom();
                    let link = this.getLink() + 'center=' + gps + '&zoom=' + zoom;
                    let container = document.createElement('div');
                    container.className = 'iw-container';
                    let textarea = document.createElement('textarea');
                    let a = document.createElement('a');
                    let distance = document.createTextNode('Odległość: ' + this.formattedLength);
                    a.href = link + '&text=' + encodeURI(this._description);
                    a.innerHTML = 'Link do pomiaru';
                    a.style.display = 'block';

                    textarea.className = 'link-description';
                    textarea.value = this._description;
                    textarea.addEventListener('keyup', (event: Event) => {
                        this._description = textarea.value;
                        a.href = link + '&text=' + encodeURIComponent(this._description);
                        if (this._description)
                            textarea.classList.add('is-visible');
                    });

                    if (this._description)
                        textarea.classList.add('is-visible');

                    container.appendChild(a);
                    container.appendChild(distance);
                    container.appendChild(textarea);

                    this._infoWindow.setContent(container);
                }
            }
        }

        protected updateLine() {
            this.updateMarker1();
            this.updateMarker2();

            if (this._point1 != null && this._point2 != null) {
                if (this._line == null) { //gdy linia nie została jeszcze utworzona
                    this._line = new google.maps.Polyline({
                        strokeColor: '#FF0000',
                        strokeWeight: 4,
                        editable: true
                    });
                    this._line.setPath([this._point1, this._point2]);
                    this._line.setMap(App.getInstance().service.map);

                    this._line.addListener('click', this.onLineClick);
                    //this._line.addListener('rightclick', this.onRightClick);
                    this._line.addListener('mousemove', this.onMouseMove);
                    this._line.addListener('mouseup', this.onMouseMove);

                    this.body = this._line;
                    this.setupInputEvent(InputEvent.RightClick, (params => {
                        App.getInstance().service.contextMenu.show(this, new Action.UserActionEvent(this, params, null));
                    }));

                    this.onMarker2Click({});
                }
                else { //gdy musimy tylko zmienić jej ścieżkę
                    this._line.getPath().setAt(0, this.point1);
                    this._line.getPath().setAt(this._line.getPath().getLength() - 1, this.point2);
                }

                this._isComplete = true; //czy zakończono rysowania linii (postawiono drugi marker)
            }
            else {
                if (this._line) {
                    google.maps.event.clearListeners(this._line, 'click');
                    google.maps.event.clearListeners(this._line, 'rightclick');
                    this._line.setMap(null);
                }
                this._isComplete = false;
            }
        }

        protected updateMarker1(): void {
            if (this._point1 == null) {
                google.maps.event.clearListeners(this._marker1, 'click');
                google.maps.event.clearListeners(this._marker1, 'rightclick');
                google.maps.event.clearListeners(this._marker1, 'drag');
                this._marker1.setMap(null);
            }
            else {
                this._marker1.setPosition(this._point1);

                if (this._marker1.getMap() == null) {
                    this._marker1.setMap(App.getInstance().service.map);
                    this._marker1.setDraggable(true);
                    this._marker1.addListener('click', this.onMarker1Click);
                    this._marker1.addListener('rightclick', this.onRightClick);
                    this._marker1.addListener('drag', this.onDrag);
                }
            }
        }

        protected updateMarker2(): void {
            if (this._point2 == null) {
                google.maps.event.clearListeners(this._marker2, 'click');
                google.maps.event.clearListeners(this._marker2, 'rightclick');
                google.maps.event.clearListeners(this._marker2, 'drag');
                this._marker2.setMap(null);
            }
            else {
                this._marker2.setPosition(this._point2);

                if (this._marker2.getMap() == null) {
                    this._marker2.setMap(App.getInstance().service.map);
                    this._marker2.setDraggable(true);
                    this._marker2.addListener('click', this.onMarker2Click);
                    this._marker2.addListener('rightclick', this.onRightClick);
                    this._marker2.addListener('drag', this.onDrag);
                }
            }
        }

        public dispose(): void {
            if (this._line != null) {
                google.maps.event.clearListeners(this._line, 'click');
                google.maps.event.clearListeners(this._line, 'rightclick');
                this._line.setMap(null);
            }

            if (this._marker1 != null) {
                google.maps.event.clearListeners(this._marker1, 'click');
                google.maps.event.clearListeners(this._marker1, 'rightclick');
                google.maps.event.clearListeners(this._marker1, 'drag');
                this._marker1.setMap(null);
            }

            if (this._marker2 != null) {
                google.maps.event.clearListeners(this._marker2, 'click');
                google.maps.event.clearListeners(this._marker2, 'rightclick');
                google.maps.event.clearListeners(this._marker2, 'drag');
                this._marker2.setMap(null);
            }


            this._line = null;
            this._marker1 = null;
            this._marker2 = null;
            this._point1 = null;
            this._point2 = null;
        }

        public get middle(): google.maps.LatLng {
            if (this._selectedEdgeIndex != null) {
                let latLng1: google.maps.LatLng = this._line.getPath().getAt(this._selectedEdgeIndex);
                let latLng2: google.maps.LatLng = this._line.getPath().getAt(this._selectedEdgeIndex + 1);
                return google.maps.geometry.spherical.interpolate(latLng1, latLng2, 0.5);
            }

            return this._line.getPath().getAt(Math.floor(this._line.getPath().getLength() / 2));
        }

        public get length(): number {
            if (this._selectedEdgeIndex == null) {
                let result: number = 0;

                let path: google.maps.MVCArray = this._line.getPath();
                for (let i = 1; i < path.getLength(); i++) {
                    let prevLatLng: google.maps.LatLng = path.getAt(i - 1);
                    let currLatLng: google.maps.LatLng = path.getAt(i);

                    result += google.maps.geometry.spherical.computeDistanceBetween(prevLatLng, currLatLng);
                }

                return result;
            }
            else {
                let latLng1: google.maps.LatLng = this._line.getPath().getAt(this._selectedEdgeIndex);
                let latLng2: google.maps.LatLng = this._line.getPath().getAt(this._selectedEdgeIndex + 1);
                return google.maps.geometry.spherical.computeDistanceBetween(latLng1, latLng2);
            }
        }

        public getLink(): string {
            let lineLink = 'https://' + location.hostname + '?line=';
            lineLink += JSON.stringify(this._line.getPath().getArray()) + '&';
            return lineLink;
        }

        public showInfoWindow(): void {
            this.onMarker2Click({});
        }

        public get formattedLength(): string {
            return this.length.toFixed(0).toString().replace('.', ',') + ' m';
        }

        public get isComplete(): boolean {
            return this._isComplete;
        }

        public get point1(): google.maps.LatLng {
            return this._point1;
        }

        public set point1(latLng: google.maps.LatLng) {
            this._point1 = latLng;
            this.updateLine();
        }

        public get point2(): google.maps.LatLng {
            return this._point2;
        }

        public set point2(latLng: google.maps.LatLng) {
            this._point2 = latLng;
            this.updateLine();
        }
    }
}
