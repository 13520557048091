/**
 * Created by Mateusz Lipowski on 25.11.2016.
 */

namespace Entity.View.InfoWindows {
    export class OfferInfoWindow {
        protected infoWindow: Entity.View.InfoWindows.InfoWindow;
        protected rangePolygon: Entity.Map.Shape.RangePolygon = null;
        protected rangeOffers: Entity.Map.NetworkRange.RangeOffer[] = [];

        protected guestMode: boolean = false;

        public constructor(rangePolygon: Entity.Map.Shape.RangePolygon) {
            this.rangePolygon = rangePolygon;
            this.rangeOffers = this.rangePolygon.rangeOffers;

            this.infoWindow = App.getInstance().service.infoWindow;

            try {
                if(GuestApp.getInstance())
                    this.guestMode = true;
            }
            catch (e) {
                this.guestMode = false;
            }
        }

        public show(latLng: google.maps.LatLng = null): void {
            this.setContent();

            if(latLng)
                this.infoWindow.setPosition(latLng);
            else {
                this.infoWindow.setPosition(this.rangePolygon.getBounds().getCenter());
            }
            this.infoWindow.open(App.getInstance().service.map);


            let foundOrderServiceAnchor: HTMLAnchorElement = document.getElementById('order-service-anchor-' + this.rangePolygon.id) as HTMLAnchorElement;
            if(foundOrderServiceAnchor) {
                foundOrderServiceAnchor.addEventListener('click', (e: Event): void => {
                    Factory.UserActionFactory.invokeAction('OrderService', 'Other', new Action.UserActionEvent(this.rangePolygon, {}));
                });
            }
        }

        protected isEmpty(): boolean {
            for(let offer of this.rangeOffers) {
                if(!offer)
                    continue;

                return false;
            }

            return true;
        }

        protected setContent(): void {
            let contentDiv: HTMLDivElement = document.createElement('div') as HTMLDivElement;
            let offerContainerDiv: HTMLDivElement = document.createElement('div') as HTMLDivElement;
            offerContainerDiv.className += 'offer-container';

            if(this.isEmpty()) {
                this.infoWindow.setContent('Brak ofery dla tego obszaru');
                return;
            }

            for(let rangeOffer of this.rangeOffers) {
                if(!rangeOffer)
                    continue;

                let offer: Entity.Map.NetworkRange.Offer = rangeOffer.offer;
                let primary: boolean = rangeOffer.primary;

                let offerDiv: HTMLDivElement = document.createElement('div') as HTMLDivElement;
                let offerHeading: HTMLHeadingElement = document.createElement('h1') as HTMLHeadingElement;
                let offerSection: HTMLSpanElement = document.createElement('section') as HTMLSpanElement;
                offerDiv.className += 'offer';
                if(primary)
                    offerDiv.className += ' primary-offer';

                offerHeading.className += 'title';
                offerHeading.textContent = offer.name;
                offerSection.className += 'description';
                offerSection.innerHTML = offer.description;
                offerDiv.appendChild(offerHeading);
                offerDiv.appendChild(offerSection);
                offerContainerDiv.appendChild(offerDiv);
            }

            contentDiv.appendChild(offerContainerDiv);

            if(this.guestMode) {
                let orderServiceDiv: HTMLDivElement = document.createElement('div') as HTMLDivElement;
                let orderServiceAnchor: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;

                orderServiceDiv.className += 'order-service';

                orderServiceAnchor.textContent = 'Zamów internet';
                orderServiceAnchor.href = '#';
                orderServiceAnchor.id = 'order-service-anchor-' + this.rangePolygon.id;

                orderServiceDiv.appendChild(orderServiceAnchor);
                contentDiv.appendChild(orderServiceDiv);
            }

            this.infoWindow.setContent(contentDiv.outerHTML);
        }
    }
}
