namespace Action.ContextMenu {
    export class MapAddNode extends UserActionBase {
        protected window: Entity.View.Window.Window;
        protected windowType: string = 'node_add';

        protected form: HTMLFormElement = null;
        protected stateBox: Entity.View.StateBox = null;
        protected formSender: Logic.FormSender = null;
        protected latLng: google.maps.LatLng = null;
        protected onChooseSupernodeEventId: number = null;

        protected onChooseWezelId: (e: Event) => void;
        protected onWezelIdChanged: (e: Event) => void;
        protected onStateBoxClosed: (e: Event) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);
            this.latLng = event.params['latLng'];
            this.window = App.getInstance().service.windowContainer.createWindow();

            /**
             * Akcja po kliknięciu na przycisk wyboru węzła nadrzędnego
             *
             * @param e
             */
            this.onChooseWezelId = (e: Event) => {
                this.stateBox = new Entity.View.StateBox(document.getElementById('state-box') as HTMLScriptElement);
                this.stateBox.show('Wybierz węzeł');

                this.app.entityContainer.toggleClients(false);

                this.window.dismiss(false);
                this.stateBox.getCloseButton().addEventListener('click', this.onStateBoxClosed);

                this.onChooseSupernodeEventId = App.getInstance().service.callbackContainer.pushCallback('node', 'click', (e: Control.CallbackContainer.Event): boolean => {
                    let node: Entity.Map.Marker.Node.Node = e.target as Entity.Map.Marker.Node.Node;
                    let options: HTMLOptionElement[] = this.form['region_id'].getElementsByTagName('option');

                    this.form['wezel_id'].value = node.id; //ustawianie pola "wezel_id" na "id" wybranego wezla
                    this.onWezelIdChanged(null);

                    for(let key in options) { //ustawianie opcji "region_id" na "region_id" wybranego wezla
                        let option: HTMLOptionElement = options[key];
                        if(option instanceof  HTMLElement && option.tagName.toLowerCase() == 'option') {
                            option.selected = (option.value == node.region_id.toString());
                        }
                    }

                    this.app.entityContainer.toggleClients(true);

                    this.onStateBoxClosed(null);
                    return false;
                })
            };

            this.onWezelIdChanged = (e: Event) => {
                this.refreshDevicesSelect();
            };

            /**
             * Akcja po zamknięciu stateboxa
             *
             * @param e
             */
            this.onStateBoxClosed = (e: Event) => {
                if(this.onChooseSupernodeEventId != null) {
                    App.getInstance().service.callbackContainer.deleteCallbackById('node', 'click', this.onChooseSupernodeEventId);
                    this.onChooseSupernodeEventId = null;
                }

                this.app.entityContainer.toggleClients(true);

                this.stateBox.dismiss();
                this.window.show(this.windowType);
                this.stateBox.getCloseButton().removeEventListener('click', this.onStateBoxClosed);
                this.form['choose_wezel_id'].addEventListener('click', this.onChooseWezelId);
                this.initFormSender();
            };
        }

        public execute(): void {
            this.window.show(this.windowType);

            this.initForm();

            this.form['choose_wezel_id'].addEventListener('click', this.onChooseWezelId);
            this.form['wezel_id'].addEventListener('change', this.onWezelIdChanged);
            this.window.setOnDismiss(() => {
                if(this.formSender) {
                    this.formSender.dispose();
                    this.formSender = null;
                }
                this.form['choose_wezel_id'].removeEventListener('click', this.onChooseWezelId);
                this.form['wezel_id'].removeEventListener('click', this.onWezelIdChanged);
            });

            this.initFormSender();
        }

        /**
         * Pobiera button formularza, uzupełnia formularz o domyślne dane
         */
        protected initForm() {
            this.form = this.window.getForm();

            let selectType: HTMLSelectElement = this.form['typ_id'] as HTMLSelectElement;
            let selectRegion: HTMLSelectElement = this.form['region_id'] as HTMLSelectElement;

            let types: Entity.Map.Type.Type[] = App.getInstance().entityContainer.types;
            let regions: Entity.Map.Type.Region[] = App.getInstance().entityContainer.regions;

            let geocode = new Logic.Geocode();

            this.populateSelect(selectType, types, 'id', 'name', null, '- brak -');
            this.populateSelect(selectRegion, regions, 'id', 'name');

            this.refreshDevicesSelect();

            geocode.getAddress(this.latLng.lat(), this.latLng.lng(), (address) => {
                this.form['adres'].value = address;
            });
            this.window.setFormField('gps', Logic.Util.shortenGPS(this.latLng.lat() + ',' + this.latLng.lng()));
        }

        /**
         * Tworzy i inicjuje Logic.FormSender, definuje akcję po wykonaniu formularza
         */
        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert = null;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    //tworzenie węzła na mapie:
                    let node = fields;
                    node['id'] = response.wezel_id;

                    App.getInstance().populateNodes({node}, Factory.NodeSupertype.DEVICE);
                    //tworzenie węzła na mapie - end

                    //komunikaty:
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                    //komunikaty - end

                    //rozpoczynanie dodawania połączenia:
                    let generatedNode: Entity.Map.Marker.Node.Node = App.getInstance().entityContainer.findNode(response.wezel_id);
                    if(generatedNode) {
                        generatedNode.setMap(this.app.service.map);
                        if (!generatedNode.isSubnode()) {
                            let nextAction: Action.ContextMenu.MarkerAddLink = new Action.ContextMenu.MarkerAddLink(new Action.UserActionEvent(generatedNode, {}));
                            nextAction.execute();
                        }
                    }
                    //rozpoczynanie dodawania połączenia - end
                }
                alert.show();
            });
        }

        protected refreshDevicesSelect(): void {
            let supernodeId: number = this.form['wezel_id'].value;
            let node: Entity.Map.Marker.Node.DeviceNode = null;
            let select: HTMLSelectElement = this.form['komputer_id'] as HTMLSelectElement;

            if(supernodeId > 0)
                node = App.getInstance().entityContainer.findNode(supernodeId.toString()) as DeviceNode;

            if(node) {
                let devicesRequestResponse: Entity.Device.DeviceRequestResponse = new Entity.Device.DeviceRequestResponse();
                devicesRequestResponse.requestDevicesForNode(node.id, (): void => {
                    this.populateSelect(select, devicesRequestResponse.devices, 'id', 'detailedName', null, ' - brak - ');
                });
                select.disabled = false;
            }
            else {
                this.populateSelect(select, [{id: 0, name: 'Wybierz węzeł nadrzędny'}], 'id', 'name');
                select.disabled = true;
            }
        }
    }
}