/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class PolygonGetLink extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let polygon: Entity.Map.Shape.Polygon = this.event.target as Entity.Map.Shape.Polygon;
            let polygonLink = 'https://' + location.hostname + '?polygon=';
            polygonLink += JSON.stringify(polygon.getPath().getArray()) + '&';
            window.prompt("Skopiuj: Ctrl+C, Enter", polygonLink);
        }
    }
}
