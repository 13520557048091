
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuCategory extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            collection.categories.map((category) => {
                if (category.id == menuItem.dataset.category) {
                    category.isVisible = menuItem.checked;
                    category.updateNodes();
                }
            });
        }
    }
}
