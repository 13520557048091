
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LinkEditPathMoveVertex extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let link: Entity.Map.Shape.Link.Link = this.event.target as Entity.Map.Shape.Link.Link;
            if(link.isEditEnabled() && this.event.params['vertex'] != undefined) {
                let latLng: google.maps.LatLng = link.path.getAt(this.event.params['vertex']);
                let lat: number = 0;
                let lng: number = 0;
                let gps = prompt('Podaj GPS wierzchołka', latLng.lat() + ',' + latLng.lng());

                lat = parseFloat(gps.split(',')[0]);
                lng = parseFloat(gps.split(',')[1]);
                link.path.setAt(this.event.params['vertex'], new google.maps.LatLng(lat, lng));
            }
            else {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Brak wierzchołka do usunięcia');
                alert.show();
            }
        }
    }
}
