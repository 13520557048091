/**
 * Created by Mateusz Lipowski on 19.09.2016.
 */

namespace Logic {
    import Loader = Entity.View.Loader;
    export class RequestSender {
        protected apiAddress: string;
        protected action: string;
        protected fields: Object;
        protected callback: (action: string, fields: Object, response: any) => void;
        protected error: boolean = false;
        public message: string = null;

        public constructor() {
            this.apiAddress = location.origin + '/ajax?';
        }

        public sendRequest(action: string, fields: Object, callback: (action: string, fields: Object, response: any) => void, formData: FormData = null): void {
            let request: XMLHttpRequest = this.prepareRequest(action, fields, 'POST');

            this.action = action;
            this.fields = fields;
            this.callback = callback;

            request.onreadystatechange = () => {
                if (request.readyState == 4 && request.status == 200) {
                    try {
                        let response = JSON.parse(request.responseText);

                        if (response.status == 'success') {
                            this.error = false;
                            this.message = response.message;
                        } else {
                            this.error = true;
                            this.message = response.message;
                        }

                        if (this.callback) {
                            try {
                                this.callback(action, fields, response.data);
                            }
                            catch (e) {
                                this.error = true;
                                this.message = 'Callback żądania rzucił wyjątek: ' + e.message;
                                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.message);
                                alert.show();
                            }
                        }
                    }
                    catch(e) {
                        this.error = true;
                        this.message = 'Błąd parsowania odpowiedzi (' + e.message + '): ' + request.responseText.replace(/<(?:.|\n)*?>/gm, '');

                        if (this.callback) {
                            this.callback(action, fields, null);
                        }
                    }
                }
            };
            request.send(formData);
        }

        public getApiAddress(): string {
            return this.apiAddress;
        }

        public getMessage(): string {
            return this.message;
        }

        public isError(): boolean {
            return this.error;
        }

        protected prepareRequest(action: string, params: Object = {}, method = 'GET') {
            let request = new XMLHttpRequest();
            request.open(method, `${this.apiAddress}action=${action}&params=${encodeURIComponent(JSON.stringify(params))}`, true);

            return request;
        }
    }
}