/**
 * Created by Mateusz Lipowski on 24.03.2017.
 */

namespace Entity.Map {
    export abstract class MapEntity extends Entity.BaseEntity {
        private _body: google.maps.MVCObject;

        public constructor() {
            super();

            this.body = null;
        }

        protected setupInputEvent(inputEvent: InputEvent, callback: (params: any) => void): void {
            let eventName: string = '';

            if(inputEvent == InputEvent.RightClick)
                eventName = 'rightclick';

            App.getInstance().service.inputManager.setupGoogleMapsEvent(this.body, eventName, callback);
        }

        protected set body(newBody: google.maps.MVCObject) {
            this._body = newBody;
        }

        protected get body(): google.maps.MVCObject {
            if(this._body == null)
                throw new Error('Entity.Map.MapEntity.body is null');

            return this._body;
        }
    }
}
