/**
 * Created by Mateusz Lipowski on 26.01.2017.
 */

namespace Entity {
    export enum InputEvent {
        RightClick
    }

    export abstract class BaseEntity {
        public constructor() {
        }

        protected setupInputEvent(inputEvent: InputEvent, callback: () => void): void {

        }
    }
}
