/**
 * Created by Mateusz Lipowski on 18.10.2016.
 */

namespace Control {
    export class ManagerModes {
        public static DRAW_ON_MAP = 'draw_on_map';
        public static DRAW_RANGE = 'draw_range';
    }

    export class DrawingManager  {
        protected _drawingManager: google.maps.drawing.DrawingManager = null;
        protected managerMode: string = '';

        public constructor() {
            //super();
            let map: google.maps.Map = App.getInstance().service.map;

            this._drawingManager = new google.maps.drawing.DrawingManager(<google.maps.drawing.DrawingManagerOptions>{
                    drawingControl: true,
                    drawingControlOptions: {
                        position: google.maps.ControlPosition.TOP_CENTER,
                        drawingModes: [google.maps.drawing.OverlayType.POLYGON]
                    },
                    polygonOptions: {
                        clickable: true
                    }
                });

            this._drawingManager.setMap(map);
            this.addListeners();
            this.pushCallbacks();
        }

        public setDrawingMode(drawingMode: google.maps.drawing.OverlayType, managerMode: string = Control.ManagerModes.DRAW_ON_MAP): void {
            this.managerMode = managerMode;
            this._drawingManager.setDrawingMode(drawingMode);
        }

        protected addListeners(): void {
            this._drawingManager.addListener('polygoncomplete', (drawnPolygon) => {
                if(this.managerMode == Control.ManagerModes.DRAW_ON_MAP) {
                    let polygon = new Entity.Map.Shape.Polygon(drawnPolygon);
                    App.getInstance().entityContainer.polygons.push(polygon);
                    this._drawingManager.setDrawingMode(null);
                }
                else if(this.managerMode == Control.ManagerModes.DRAW_RANGE) {
                    let newRangePolygon: Entity.Map.Shape.RangePolygon = Factory.RangePolygonFactory.createNewRangePolygon(drawnPolygon);
                    this._drawingManager.setDrawingMode(null);
                    App.getInstance().service.rangeManager.registerEditedRangePolygon(newRangePolygon);
                }
            });

            this._drawingManager.addListener('markercomplete', (drawnMarker) => {
                if(this.managerMode == Control.ManagerModes.DRAW_ON_MAP) {
                    let lastLine = App.getInstance().entityContainer.lines.slice(-1)[0];

                    if (lastLine == undefined || lastLine.isComplete) {
                        App.getInstance().entityContainer.lines.push(new Entity.Map.Shape.Line(drawnMarker.position));
                    }
                    else {
                        lastLine.point2 = drawnMarker.position;
                        this._drawingManager.setDrawingMode(null);
                    }

                    drawnMarker.setMap(null);
                    drawnMarker = null;
                }
            });
        }

        protected pushCallbacks(): void {
            let callbackContainer: Control.CallbackContainer.CallbackContainer = App.getInstance().service.callbackContainer;
            let contextMenu: Entity.View.Menu.ContextMenu = App.getInstance().service.contextMenu;
            let infoWindow: Entity.View.InfoWindows.InfoWindow = App.getInstance().service.infoWindow;
            let map: google.maps.Map = App.getInstance().service.map;

            callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.POLYGON, 'rightclick', (event: Control.CallbackContainer.Event): boolean => {
                contextMenu.show(event.target, Action.UserActionEvent.CreateForCallbackEvent(event));
                return true;
            });
        }
    }
}
