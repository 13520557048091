/**
 * Created by Mateusz Lipowski on 18.10.2016.
 */

namespace Control {
    export class ServiceContainer {
//<editor-fold desc="Services">
        private _mapCanvas: HTMLCanvasElement = null;

        private _map: Entity.Map.Map = null;
        // private _map: google.maps.Map = null;
        private _overlay: google.maps.OverlayView;
        private _directionsRenderer: google.maps.DirectionsRenderer;

        private _markerClusterer: MarkerClusterer;

        private _drawingManager: Control.DrawingManager;
        private _infoWindow: Entity.View.InfoWindows.InfoWindow = null;
        private _windowContainer: Control.WindowContainer = null;
        private _loader: Entity.View.Loader = null;
        private _locationSearchInput: Entity.View.LocationSearchInput = null;

        private _contextMenu: Entity.View.Menu.ContextMenu;
        private _mainMenu: Entity.View.Menu.MainMenu;

        private _callbackContainer: Control.CallbackContainer.CallbackContainer;
        private _rangeManager: Entity.Map.NetworkRange.RangeManager;
        private _settings: Control.Settings;
        private _inputManager: Control.InputManager;
//</editor-fold>

//<editor-fold desc="Getters">
        get map(): google.maps.Map {
            return this._map.map;
        }

        get mapContainer(): Entity.Map.Map {
            return this._map;
        }

        get overlay(): google.maps.OverlayView {
            return this._overlay;
        }

        get directionsRenderer(): google.maps.DirectionsRenderer {
            return this._directionsRenderer;
        }

        get markerClusterer(): MarkerClusterer {
            return this._markerClusterer;
        }

        get drawingManager(): Control.DrawingManager {
            return this._drawingManager;
        }

        get infoWindow(): Entity.View.InfoWindows.InfoWindow {
            return this._infoWindow;
        }

        get windowContainer(): Control.WindowContainer {
            return this._windowContainer;
        }

        get loader(): Entity.View.Loader {
            return this._loader;
        }

        get locationSearchInput(): Entity.View.LocationSearchInput {
            return this._locationSearchInput;
        }

        get contextMenu(): Entity.View.Menu.ContextMenu {
            return this._contextMenu;
        }

        get mainMenu(): Entity.View.Menu.MainMenu {
            return this._mainMenu;
        }

        get callbackContainer(): Control.CallbackContainer.CallbackContainer {
            return this._callbackContainer;
        }

        get rangeManager(): Entity.Map.NetworkRange.RangeManager {
            return this._rangeManager;
        }

        get settings(): Control.Settings {
            return this._settings;
        }

        get inputManager(): Control.InputManager {
            return this._inputManager;
        }

//</editor-fold>

//<editor-fold desc="Initializers">
        public initMap(mapCanvas: HTMLCanvasElement): void {
            let params = this.parseParams(location.search);

            this._mapCanvas = mapCanvas;
            this._map = new Entity.Map.Map(this._mapCanvas, params);
        };

        public initInfoWindow(): void {
            this._infoWindow = new Entity.View.InfoWindows.InfoWindow();
        }

        public initOverlay(): void {
            this._overlay = new google.maps.OverlayView();
            this._overlay.draw = function () {
            };
            this._overlay.setMap(this.map);
        }

        public initDirectionsRenderer(): void {
            this._directionsRenderer = new google.maps.DirectionsRenderer({
                draggable: true
            });
        }

        public initMarkerClusterer(markers: google.maps.Marker[]): void {
            this._markerClusterer = new MarkerClusterer(this.map, markers, {imagePath: 'img/m', maxZoom: 14});
        }

        public initDrawingManager(): void {
            this._drawingManager = new Control.DrawingManager();
        }

        public initWindowContainer(): void {
            let windowContainerElement: HTMLDivElement = document.getElementById('window-container') as HTMLDivElement;
            let windowContentTemplateElement: HTMLDivElement = document.getElementById('window-content-template') as HTMLDivElement;

            this._windowContainer = new Control.WindowContainer(windowContainerElement, windowContentTemplateElement);
        }

        public initLoader(): void {
            let loaderElement = document.getElementById('loader');
            if (loaderElement)
                this._loader = new Entity.View.Loader(loaderElement as HTMLScriptElement);
        }

        public initMenus(context: boolean = true, main: boolean = true): void {
            if (context)
                this._contextMenu = new Entity.View.Menu.ContextMenu(document.getElementById('context-menu') as HTMLScriptElement);

            if (!main) return;

            this._mainMenu = new Entity.View.Menu.MainMenu(document.getElementById('main-menu-container'));

            this._locationSearchInput = new Entity.View.LocationSearchInput(document.getElementById('menu-search-loaction-form') as HTMLFormElement, document.getElementById('menu-search-location-results') as HTMLUListElement);
        }

        public initCallbackContainer(): void {
            this._callbackContainer = new Control.CallbackContainer.CallbackContainer();
        }

        public initRangeManager(): void {
            this._rangeManager = new Entity.Map.NetworkRange.RangeManager();
        }

        public initSettings(loadCallback: () => void, errorCallback: () => void): void {
            this._settings = new Control.Settings(loadCallback, errorCallback);
        }

        public initInputManager(): void {
            this._inputManager = new InputManager();
        }

//</editor-fold>

//<editor-fold desc="Utilities">
        protected parseParams(params): any {
            let searchArray = location.search ? params.substring(1).split('&') : [];
            let object : any = {lat: config.center.lat, lng: config.center.lng, zoom: (searchArray.length ? 16 : config.center.zoom)};
            let searchArrayLength = searchArray.length;

            for (let i = 0; i < searchArrayLength; i++) {
                let param = searchArray[i];
                let paramsSplitted = param.split('=').map(decodeURIComponent);

                object[paramsSplitted[0]] = paramsSplitted[1];
            }

            return object;
        };

//</editor-fold>
    }
}