/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LineGetLink extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let line: Entity.Map.Shape.Line = this.event.target as Entity.Map.Shape.Line;
            let lineLink: string = location.hostname + '?line=';
            lineLink += encodeURIComponent(JSON.stringify([line.point1, line.point2]));
            window.prompt("Skopiuj: Ctrl+C, Enter", lineLink);
        }
    }
}
