
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class TicketShowCard extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let ticketNode: Entity.Map.Marker.Node.Ticket = this.event.target as Entity.Map.Marker.Node.Ticket;
            let url = config.panelUrl + '/?co=dziennik&temat=1&id=' + ticketNode.id;
            var win = window.open(url, '_blank');
            win.focus();
        }
    }
}
