/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class RangePolygonOffer extends Action.UserActionBase {
        protected rangePolygon: Entity.Map.Shape.RangePolygon = null;
        protected rangeOffers: Entity.Map.NetworkRange.RangeOffer[] = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.rangePolygon = this.event.target as Entity.Map.Shape.RangePolygon;
            if(!this.rangePolygon.id) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Przed edycją ofert, należy zapisać obszar w bazie danych');
                alert.show();
            }
            else {
                this.rangeOffers = this.rangePolygon.rangeOffers;

                let offerWindow: Entity.View.Window.AssignOfferWindow = this.app.service.windowContainer.createWindow(Control.WindowClass.ASSIFN_OFFER_WINDOW) as Entity.View.Window.AssignOfferWindow;
                offerWindow.showForRange(this.rangePolygon);
            }
        }
    }
}
