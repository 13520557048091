/**
 * Created by Mateusz Lipowski on 05.10.2016.
 */

namespace Entity.Map.Type {
    export class Region extends Entity.Map.MapEntity {
        protected _id: number = 0;
        protected _name: string = '';
        protected _prefix: string = null;
        protected _isVisible: boolean = true;

        public constructor(rowData: Object) {
            super();
            this._id = parseInt(rowData['id']);
            this._name = rowData['nazwa'];
            this._prefix = rowData['prefix'];

            App.getInstance().service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.APP, 'ready', (e: Control.CallbackContainer.Event): boolean => {
                this.onMapReady();
                return true;
            });

            this.update(rowData);
        }

        public update(rowData = null) {
            if(!this._id)
                throw new Error('Błędne ID dla regionu (podane id: ' + rowData['id'] + ')');

            if(!this._name || this._name.length <= 0)
                throw new Error('Błędna nazwa dla regionu ' + this._id + ' (podana nazwa: ' + rowData['nazwa'] + ')');

            if(!this._prefix || this._prefix.length <= 0)
                this._prefix = null;
        }

        public updateNodes(): void {
            let collection: Control.EntityContainer = App.getInstance().entityContainer;
            collection.devices.map((device) => {
                if (device.region == this)
                    device.update();
            });
        }

        protected onMapReady(): void {
            let visible: string = Logic.Cookie.readCookie('region-' + this.id + '-visible');
            if(visible == 'false') {
                let menuInput: HTMLInputElement = document.getElementById('menu-region-' + this.id) as HTMLInputElement;
                this._isVisible = false;
                menuInput.checked = false;
                this.updateNodes();
            }
        }

        get id(): number {
            return this._id;
        }

        get name(): string {
            return this._name;
        }

        set name(name: string) {
            this._name = name;
        }

        get prefix(): string {
            return this._prefix;
        }

        set prefix(prefix: string) {
            this._prefix = prefix;
        }

        get isVisible() {
            return this._isVisible;
        }

        set isVisible(isVisible: boolean) {
            this._isVisible = isVisible;
            Logic.Cookie.createCookie('region-' + this.id + '-visible', isVisible);
        }
    }
}
