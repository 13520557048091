/**
 * Created by Mateusz Lipowski on 21.10.2016.
 */

namespace Action.ContextMenu {
    interface NodeAndDistance {
        node: Entity.Map.Marker.Node.DeviceNode,
        distance: number,
        showLine: ShowLine
    }

    class ShowLine {
        protected _button: HTMLInputElement;
        protected _color: string;
        protected _polyLine: google.maps.Polyline;
        protected _lineLocked: boolean = false;
        protected _colors: string[] = ['ff0000', 'ff9400', 'ffff00', '8cc700', '007800', '00a3c7', '0064b5', '0010a5', '6300a5', 'c5007c'];

        protected static TXT_HIDDEN: string = 'Pokaż linię';
        protected static TXT_SHOWN: string = 'Ukryj linię';
        protected static DEFAULT_BUTTON_COLOR: string = '#FFFFFF';

        public constructor(protected center: google.maps.LatLng, protected node: Entity.Map.Marker.Node.DeviceNode, protected offset: number, protected total: number) {
            this._button = document.createElement('input');
            this._button.type = 'button';
            this._button.value = ShowLine.TXT_HIDDEN;
            this._button.style.backgroundColor = ShowLine.DEFAULT_BUTTON_COLOR;

            this._color = '#' + this.getColor();

            this._polyLine = new google.maps.Polyline();
            this._polyLine.setPath([this.center, this.node.getPositionObject()]);
            this._polyLine.setOptions({
                strokeColor: this._color
            });

            this._button.addEventListener('click', (e: Event) => {
                e.preventDefault();
                e.stopPropagation();
                this.onClick();
                return false;
            });
        }

        public onClick(): void {
            if (!this._lineLocked) {
                this.showLine();
                this._lineLocked = true;
                this._button.value = ShowLine.TXT_SHOWN;
                this._button.style.backgroundColor = this._color;
            }
            else {
                this.hideLine();
                this._lineLocked = false;
                this._button.value = ShowLine.TXT_HIDDEN;
                this._button.style.backgroundColor = ShowLine.DEFAULT_BUTTON_COLOR;
            }
        }

        public showLine(): void {
            this._polyLine.setMap(App.getInstance().service.map);
        }

        public hideLine(): void {
            this._polyLine.setMap(null);
        }

        public mouseOver(): void {
            if (!this._lineLocked)
                this.showLine();
        }

        public mouseLeave(): void {
            if (!this._lineLocked)
                this.hideLine();
        }

        public getColor() {
            if (this.offset < this._colors.length) {
                return this._colors[this.offset];
            }

            let hMultiplier = (this.offset - this._colors.length) / (30 - this._colors.length);
            let h: number = Math.round((hMultiplier) * 100) / 100;
            let s: number = 1;
            let v: number = 0.5;
            let rgb: number[] = Logic.Util.HSVtoRGB(h, s, v);
            return Logic.Util.rgbToHex(rgb[0], rgb[1], rgb[2]);
        }

        public get button(): HTMLInputElement {
            return this._button;
        }
    }

    export class MapGetNodes extends UserActionBase {
        protected maxDistance: number;
        protected maxCount: number;
        protected allowedTypes: string[];
        protected currentCount: number;

        protected center: google.maps.LatLng = null;
        protected closestNodes: NodeAndDistance[] = null;
        protected window: Entity.View.Window.Window = null;
        protected table: HTMLTableElement = null;
        protected moreButton: HTMLButtonElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
            this.center = event.params['latLng'];
            this.maxDistance = config.closestMarkers.maxDistance;
            this.maxCount = config.closestMarkers.maxCount;
            this.currentCount = config.closestMarkers.startCount;
            this.allowedTypes = config.closestMarkers.allowedTypes;
        }

        public execute(): void {
            this.closestNodes = [];
            let app: App = App.getInstance();
            let nodes: Entity.Map.Marker.Node.DeviceNode[] = app.entityContainer.devices;
            for (let node of nodes) {
                if (!node)
                    continue;

                if (!node.type || this.allowedTypes.indexOf(node.type.name) == -1)
                    continue;

                let distance = google.maps.geometry.spherical.computeDistanceBetween(this.center, node.getPositionObject());
                this.closestNodes.push({
                    node:     node,
                    distance: distance,
                    showLine: null
                });
            }

            this.sortClosestNodesTable();

            this.closestNodes.length = this.maxCount;

            this.showWindow();
            this.refreshTable();
            this.setCompactSize();
        }

        protected onWindowDissmissed(): void {
            for (let i = 0; i < this.closestNodes.length && i < this.maxCount; i++) {
                let nodeAndDistance: NodeAndDistance = this.closestNodes[i];
                if (!nodeAndDistance)
                    continue;

                nodeAndDistance.showLine && nodeAndDistance.showLine.hideLine();
                nodeAndDistance.showLine = null;
            }
        }

        protected sortClosestNodesTable(): void {
            this.closestNodes.sort(function (a: NodeAndDistance, b: NodeAndDistance): number {
                if (a.distance > b.distance)
                    return 1;
                else if (a.distance < b.distance)
                    return -1;

                return 0;
            });
        }

        protected showWindow(): void {
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('get_nodes');
            this.window.setOnDismiss((): void => {
                this.onWindowDissmissed();
            });
            this.table = this.window.getForm().getElementsByClassName('nodes-table')[0] as HTMLTableElement;
            this.moreButton = this.window.getForm().getElementsByClassName('more-nodes-button')[0] as HTMLButtonElement;

            this.moreButton.addEventListener('click', (e: MouseEvent) => {
                e.preventDefault();

                this.currentCount += 5;

                this.refreshTable();
            });
        }

        protected refreshTable(): void {
            if (!this.table)
                return;

            let oldHeight = this.window.getHeight();

            this.removeAllChildren(this.table as HTMLElement);

            if (this.currentCount >= this.maxCount)
                this.moreButton.style.display = 'none';

            for (let i = 0; i < this.closestNodes.length && i < this.currentCount; i++) {
                let nodeAndDistance: NodeAndDistance = this.closestNodes[i];
                if (!nodeAndDistance)
                    continue;

                nodeAndDistance.showLine = new ShowLine(this.center, nodeAndDistance.node, i, this.closestNodes.length - 1);

                let row1 = nodeAndDistance.node.getShortenedDeviceName();
                let row2 = Math.round(nodeAndDistance.distance) + ' m';
                let row3 = document.createElement('div');
                row3.style.backgroundColor = '#' + nodeAndDistance.showLine.getColor();

                let row = this.createTableRow('td', row1, row2, row3);


                this.table.appendChild(row);

                row.addEventListener('mouseover', (e: MouseEvent) => {
                    nodeAndDistance.showLine.mouseOver();
                });
                row.addEventListener('mouseleave', (e: MouseEvent) => {
                    nodeAndDistance.showLine.mouseLeave();
                });
                row.addEventListener('click', (e: MouseEvent) => {
                    nodeAndDistance.showLine.onClick();
                    row.className = row.className ? '' : 'is-active';
                });
            }

            let offset = this.window.getWindowOffset();
            let yOffset = offset.y - (this.window.getHeight() - oldHeight);
            this.window.moveModalTo(offset.x, yOffset < 0 ? 0 : yOffset);
        }

        protected setCompactSize(): void {
            this.window.addWindowContentClass('window-compact');
            this.window.moveModalTo(25, window.innerHeight - this.window.getHeight() - 25);
        }
    }
}



