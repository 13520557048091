/**
 * Created by Mateusz Lipowski on 16.11.2016.
 */

namespace Factory {
    export class RangePolygonFactory {

        /**
         * Tworzy instancję Shapes.RangePolygon na podstawie danych z bazy danych, umieszcza na mapie i rejestruje w range manager
         * @param rowData Object
         * @returns void
         */
        public static createExistingRangePolygon(rowData: Object): void {
            let regionId: number = parseInt(rowData['region_id']);
            let path: google.maps.LatLng[] = Logic.Util.generatePathArray(rowData['wierzcholki']);
            let rangePolygon: Entity.Map.Shape.RangePolygon = null;

            if(!path)
                return null;

           if(!App.getInstance().entityContainer.findRegion(rowData['region_id']))
               return null;

            rangePolygon = new Entity.Map.Shape.RangePolygon(path, rowData);
            App.getInstance().service.rangeManager.registerRangePolygon(rangePolygon);
        }

        /**
         * Tworzy instancję Shapes.RangePolygon na podstawie narysowanego wielokątu i rejestruje w range manager
         * @param polygon google.maps.Polygon
         * @returns void
         */
        public static createNewRangePolygon(polygon: google.maps.Polygon): Entity.Map.Shape.RangePolygon {
            if(!polygon)
                return null;

            let rangeManager: Entity.Map.NetworkRange.RangeManager = App.getInstance().service.rangeManager;
            let regionId: number = 0;
            if(rangeManager.editedRegion)
                regionId = rangeManager.editedRegion.id;

            let rangePolygon: Entity.Map.Shape.RangePolygon = new Entity.Map.Shape.RangePolygon(null, {
                id: 0,
                region_id: regionId,
                wierzcholki: Logic.Util.generatePathJson(polygon.getPath()),
                range_offers: []
            }, polygon);

            App.getInstance().service.rangeManager.registerRangePolygon(rangePolygon);

            rangePolygon.setEditable(true);
            rangePolygon.setVisible(true);
            return rangePolygon;
        }
    }
}
