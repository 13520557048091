/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class TypeAdd extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected form: HTMLFormElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('type_add');
            this.form = this.window.getForm();


            for(let category of this.app.entityContainer.categories) {
                if(!category)
                    continue;

                let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                option.value = category.id.toString();
                option.textContent = category.name;
                this.form['category_id'].appendChild(option);
            }


            this.initFormSender();
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    let rowData: Object = {
                        id: response.id,
                        kategoria_id: this.form['category_id'].value,
                        nazwa: this.form['name']['value'],
                        symbol: this.form['symbol'].value,
                        kolor: this.form['color'].value.substring(1)
                    };
                    let newType: Entity.Map.Type.Type = new Entity.Map.Type.Type(rowData);
                    this.app.entityContainer.types[newType.id] = newType;
                    this.app.service.callbackContainer.invokeCallbacks('type', new Control.CallbackContainer.Event('update', newType, {}));
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
