/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LineDelete extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let line: Entity.Map.Shape.Line = this.event.target as Entity.Map.Shape.Line;
            let lines: Entity.Map.Shape.Line[] = this.app.entityContainer.lines;
            for(let i = 0; i < lines.length; i++) {
                if(lines[i] && lines[i] == line) {
                    this.app.entityContainer.deleteLine(i);
                    line.dispose();
                    line = null;
                    break;
                }
            }
        }
    }
}
