
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class ClientShowCard extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let clientNode: Entity.Map.Marker.Node.ClientNode = this.event.target as Entity.Map.Marker.Node.ClientNode;
            let url = config.panelUrl + '?co=klienci&klient=1&id=' + clientNode.client_id;
            var win = window.open(url, '_blank');
            win.focus();
        }
    }
}
