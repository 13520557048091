/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class OrderService extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected form: HTMLFormElement = null;
        protected rangePolygon: Entity.Map.Shape.RangePolygon = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('order_service');
            this.form = this.window.getForm();
            this.rangePolygon = this.event.target as Entity.Map.Shape.RangePolygon;

            this.initView();
            this.initFormSender();
        }

        protected initView(): void {
            let offerSelect: HTMLSelectElement = this.form['offer_id'];
            for(let rangeOffer of this.rangePolygon.rangeOffers) {
                if(!rangeOffer)
                    continue;

                let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                option.textContent = rangeOffer.offer.name;
                option.value = rangeOffer.offer.id.toString();
                offerSelect.appendChild(option);
            }
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
