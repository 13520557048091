/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LineDeleteVertex extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let line: Entity.Map.Shape.Line = this.event.target as Entity.Map.Shape.Line;
            line.deleteVertex(this.event.params['vertex']);
        }
    }
}
