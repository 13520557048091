
class AppMobile extends App {
    private mainMenuShown: boolean = false;

    private mainMenuContainer: HTMLDivElement;
    private expandersContainer: HTMLDivElement;
    private mainMenuIcon: HTMLSpanElement;

    private expandedListItem: HTMLLIElement;

    public initBasicModules(mapCanvas) {
        super.initBasicModules(mapCanvas);

        this.service.inputManager.mobile = true;
    }

    public postFill() {
        super.postFill();
        this.setupMobileFuncions();
    }

    private setupMobileFuncions(): void {
        this.mainMenuContainer = document.getElementById('main-menu-container') as HTMLDivElement;
        this.expandedListItem = null;

        if(this.mainMenuContainer) {
            this.setupMainMenu();
        }
    }

    private setupMainMenu(): void {
        this.expandersContainer = document.getElementById('expanders-container') as HTMLDivElement;
        this.mainMenuIcon = document.getElementById('main-menu-icon') as HTMLSpanElement;

        let expandersContainerOffsetFromTopOfTheScreen: number = this.expandersContainer.offsetTop;
        let sliderContainers: NodeListOf<HTMLLIElement> = this.mainMenuContainer.getElementsByClassName('slider-container') as unknown as NodeListOf<HTMLLIElement>;

        this.mainMenuContainer.classList.remove('main-menu-hover');

        this.mainMenuIcon.addEventListener('click', () => {
            if(this.mainMenuShown) {
                this.revertMainMenu();
                this.mainMenuShown = false;
            }
            else {
                this.expandMainMenu();
                this.mainMenuShown = true;
            }
        });

        for (let i = 0; i < sliderContainers.length; i++) {
            if(!sliderContainers.item(i)) continue;

            let li: HTMLLIElement = sliderContainers.item(i);
            let input: HTMLInputElement = li.getElementsByClassName('slider-expand')[0] as HTMLInputElement;
            li.classList.remove('slider-container-hover'); //usuwa klasę która rozsuwa podmenu automatycznie


            input.addEventListener('click', (e: Event): void => {
                e.stopPropagation();
                e.preventDefault();

                let aside: HTMLDivElement = li.getElementsByTagName('aside')[0] as HTMLDivElement;
                aside.classList.remove('slider-upper');
                aside.classList.remove('slider-lower');
                let asideOffsetFromTopOfTheScreen: number = aside.getBoundingClientRect().top;

                aside.style.left = '-300px';
                aside.style.top = '-' + (asideOffsetFromTopOfTheScreen - expandersContainerOffsetFromTopOfTheScreen) + 'px';

                this.expandersContainer.style.right = '-300px';
                this.expandersContainer.style.width = '600px';
                this.expandersContainer.style.paddingLeft = '300px';


                let goBackInputElement: HTMLInputElement = document.createElement('input') as HTMLInputElement;
                let goBackLabelElement: HTMLLabelElement = document.createElement('label') as HTMLLabelElement;
                goBackInputElement.type = 'checkbox';

                goBackLabelElement.className += 'go-back';
                goBackLabelElement.innerHTML = 'Wróć <i class="icon-fastright"></i>';
                goBackLabelElement.appendChild(goBackInputElement);

                goBackInputElement.addEventListener('click', () => {
                    this.revertExpanedListItem();
                });

                aside.insertAdjacentElement('afterbegin', goBackLabelElement);

                this.expandedListItem = li;
            });
        }
    }

    private expandMainMenu(): void {
        this.mainMenuContainer.style.right = '0px';
        this.mainMenuContainer.style.transitionDelay = '0s';
        this.mainMenuIcon.innerHTML = '<i class="icon-fastright"></i>';
    }

    private revertMainMenu(): void {
        this.mainMenuContainer.style.right = '-300px';
        this.mainMenuContainer.style.transitionDelay = '0s';
        this.mainMenuIcon.innerHTML = '<i class="icon-menu"></i>';

        this.revertExpanedListItem();
    }

    private revertExpanedListItem(): void {
        this.expandersContainer.style.right = '0px';
        this.expandersContainer.style.width = '300px';
        this.expandersContainer.style.paddingLeft = '0';

        if(this.expandedListItem != null) {
            let aside: HTMLDivElement = this.expandedListItem.getElementsByTagName('aside')[0] as HTMLDivElement;
            let goBackLabel: HTMLLabelElement = aside.getElementsByClassName('go-back')[0] as HTMLLabelElement;

            aside.style.left = '300px';
            aside.style.top = '0';
            aside.removeChild(goBackLabel);

            this.expandedListItem = null;
        }
    }
}