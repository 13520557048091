/**
 * Created by Mateusz Lipowski on 19.09.2016.
 */

namespace Entity.View {
    export class Loader extends Entity.View.ViewBase {
        protected loaderText: HTMLScriptElement;

        public constructor(protected loader: HTMLScriptElement) {
            super();
            if(this.loader.tagName.toLowerCase() != 'div' || this.loader.className != 'loader')
                throw new Error('Podany button nie jest właściwym loaderem (powinien to być div o klasie loader)');

            this.loaderText = this.loader.getElementsByClassName('loader-text')[0] as HTMLScriptElement;
        }

        public show(text: string = 'Zapisywanie zmian...'):void {
            if(this.loader.style.display != 'flex') {
                this.loaderText.textContent = text;
                this.loader.style.display = 'flex';
            }
        };

        public dismiss():void {
            this.loader.style.display = 'none';
        };
    }
}
