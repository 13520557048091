/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Factory {
    export class UserActionFactory {
        public static actionToClassName(action: string): string {
            let index: number = action.indexOf('-');
            while(index >= 0) {
                if(index != action.length - 1)
                    action = action.substr(0, index) + action.charAt(index + 1).toUpperCase() + action.substr(index + 2);
                else
                    action = action.substr(0, index);

                index = action.indexOf('-');
            }
            return action.charAt(0).toUpperCase() + action.substr(1);
        }

        public static invokeAction(actionName: string, namespace: string, event: Action.UserActionEvent): void {
            let classname: string = Factory.UserActionFactory.actionToClassName(actionName);
            let userAction: Action.UserActionBase = null;

            if(Action[namespace][classname] === undefined)
                throw new Error('Nie istnieje klasa obsługująca akcję "' + actionName + '"');

            userAction = new Action[namespace][classname](event);
            userAction.execute();
        }
    }
}
