/**
 * Created by Mateusz Lipowski on 04.11.2016.
 */

namespace Entity.View {
    export class ViewBase extends BaseEntity {
        protected createTableRow(cellTag: string, ...contents: any[]): HTMLTableRowElement {
            let tr: HTMLTableRowElement = document.createElement('tr') as HTMLTableRowElement;

            if(cellTag != 'td' && cellTag != 'th')
                cellTag = 'td';

            for(let tdContent of contents) {
                let td: HTMLTableCellElement = document.createElement(cellTag) as HTMLTableCellElement;

                if(tdContent instanceof HTMLElement)
                    td.appendChild(tdContent);
                else
                    td.textContent = tdContent ? tdContent.toString() : '';
                tr.appendChild(td);
            }

            return tr;
        }

        protected createCheckbox(checked: boolean = false, id: string = null, className: string = null, name: string = null, disabled: boolean = false): HTMLInputElement {
            let checkbox: HTMLInputElement = document.createElement('input') as HTMLInputElement;
            checkbox.type = 'checkbox';
            checkbox.checked = checked;
            if(id) checkbox.id = id;
            if(className) checkbox.className += className;
            if(name) checkbox.name = name;
            checkbox.disabled = disabled;
            return checkbox;
        };

        protected createRadio(checked: boolean = false, value: string, id: string = null, className: string = null, name: string = null, disabled: boolean = false): HTMLInputElement {
            let radio: HTMLInputElement = document.createElement('input') as HTMLInputElement;
            radio.type = 'radio';
            radio.checked = checked;
            if(value) radio.value = value;
            if(id) radio.id = id;
            if(className) radio.className += className;
            if(name) radio.name = name;
            radio.disabled = disabled;
            return radio;
        };

        protected createTextInput(value: string, id: string = null, className: string = null, name: string = null, disabled: boolean = false): HTMLInputElement {
            let input: HTMLInputElement = document.createElement('input') as HTMLInputElement;
            input.type = 'text';
            if(value) input.value = value;
            if(id) input.id = id;
            if(className) input.className += className;
            if(name) input.name = name;
            input.disabled = disabled;
            return input;
        };

        protected createButton(value: string, id: string = null, className: string = null, name: string = null, disabled: boolean = false): HTMLInputElement {
            let button: HTMLInputElement = document.createElement('input') as HTMLInputElement;
            button.type = 'button';
            button.value = value;
            if(id) button.id = id;
            if(className) button.className += className;
            if(name) button.name = name;
            button.disabled = disabled;
            return button;
        };

        protected createSpan(content: Element|string, id: string = null, className: string = null): HTMLSpanElement {
            let span: HTMLSpanElement = document.createElement('span') as HTMLSpanElement;

            if(content instanceof Element)
                span.appendChild(content);
            else
                span.textContent = content;

            if(id) span.id = id;
            if(className) span.className += className;

            return span;
        }

        protected createColorInput(color: string, id: string = null, className: string = null): HTMLInputElement {
            let input: HTMLInputElement = document.createElement('input') as HTMLInputElement;

            if(id) input.id = id;
            if(className) input.className += className;

            input.type = 'color';
            input.value = color;

            return input;
        }

        protected createColor(color: string, id: string = null, className: string = null): HTMLSpanElement{
            let colorSpan: HTMLSpanElement = document.createElement('span') as HTMLSpanElement;

            if(id) colorSpan.id = id;
            if(className) colorSpan.className += className;

            colorSpan.textContent = '';
            colorSpan.className += ' color-span';

            if(!color || color == '#null')
                colorSpan.className += ' color-span color-span-empty';
            else
                colorSpan.style.background = color;

            return colorSpan;
        }

        protected setAttributes(element: HTMLElement, attributes: Object): void {
            for(let attribute in attributes) {
                if(!attributes.hasOwnProperty(attribute))
                    continue;

                let value = attributes[attribute];
                element.setAttribute(attribute, value);
            }
        }

        protected populateSelect(select: HTMLSelectElement, _array: any[], valueKey: string, nameKey: string, selectedValue: string = null, emptyOptionName: string = null) {
            let array = _array.slice(); //by skopiować tablicę a nie referencję (w przypadku referencji, pusta opcja dodaje się do globalnej listy elementów w App)
            array.sort((a: any, b: any): number => {
                if(a[nameKey] > b[nameKey])
                    return 1;
                else if(a[nameKey] < b[nameKey])
                    return -1;
                else
                    return 0;
            });

            this.removeAllChildren(select);

            if(emptyOptionName !== null) {
                let emptyOption = {};
                emptyOption[valueKey] = 0;
                emptyOption[nameKey] = emptyOptionName;
                array.unshift(emptyOption);
            }

            for(let key in array) {
                let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                option.textContent = array[key][nameKey];
                option.value = array[key][valueKey].toString();

                if(selectedValue !== null && selectedValue == array[key][valueKey])
                    option.selected = true;

                select.appendChild(option);
            }
        }

        protected convertToEditableInput(element: HTMLElement): HTMLInputElement {
            let newElement: HTMLInputElement = document.createElement('input') as HTMLInputElement;
            newElement.disabled = false;
            newElement.className += 'converted-element ';
            newElement.className += element.className;
            newElement.classList.remove('device-element');
            newElement.id = element.id;
            newElement.setAttribute('data-name', element.dataset['name']);

            if(element instanceof HTMLInputElement) {
                newElement.type = element.type;
                newElement.name = element.name;
                newElement.value = element.value;

                if(element.type == 'checkbox')
                    newElement.checked = element.checked;
            }
            else if(element instanceof HTMLSpanElement) {
                newElement.value = element.textContent;
            }
            else {
                return null;
            }

            return newElement;
        }

        protected replaceElement(elementToReplace: HTMLElement, replacingElement: HTMLElement, hide: boolean = false): void {
            if(!elementToReplace.parentElement)
                throw new Error('Entity.View.ViewBase.replaceElement: element to replace has no parent element');

            elementToReplace.parentElement.insertBefore(replacingElement, elementToReplace);
            if(hide)
                elementToReplace.style.display = 'none';
            else
                elementToReplace.parentElement.removeChild(elementToReplace);
        }

        protected removeAllChildren(element: HTMLElement) {
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }
        }
    }
}
