/**
 * Created by Mateusz Lipowski on 19.09.2016.
 */

namespace Logic {
    export class FormSender {
        protected requestSender: RequestSender;
        protected formData: FormData = null;

        protected onSubmit: (e: Event) => void;
        protected userOnSubmit: () => void = null;

        public constructor(protected form: HTMLFormElement, protected callback: (action: string, fields: Object, response: any) => void) {
            if (this.form == null || this.form.tagName.toLowerCase() != 'form')
                throw new Error("FormSender Exception: Element form nie jest tagiem <form>");

            this.requestSender = new RequestSender;

            this.onSubmit = e => {
                e.preventDefault();

                if(this.userOnSubmit)
                    this.userOnSubmit();

                let formDataObject = this.formFieldsToObject(this.form);
                let action = this.form.dataset['action'];
                this.requestSender.sendRequest(action, formDataObject, this.callback, this.formData);

                return false;
            };

            this.form.addEventListener('submit', this.onSubmit);
        }

        public setFormData(formData: FormData): void {
            this.formData = formData;
        }

        public setOnSubmit(onSubmit: () => void): void {
            this.userOnSubmit = onSubmit;
        }

        public submit(): void {
            let event = new Event('submit');
            this.form.dispatchEvent(event);
        }

        public getRequestSender(): RequestSender {
            return this.requestSender;
        }

        public getMessage(): string {
            return this.requestSender.getMessage();
        }

        public isError(): boolean {
            return this.requestSender.isError();
        }

        public dispose(): void {
            this.form.removeEventListener('submit', this.onSubmit);
        }

        protected formFieldsToObject(form): Object {
            let object = {};

            let elements = form.elements;
            let elementsLength = elements.length;

            for (let i = 0; i < elementsLength; i++) {
                let element = elements[i];

                if (element.type == 'checkbox')
                    object[element.name] = element.checked;
                else if (element.value != '')
                    object[element.name] = element.value;
            }

            return object;
        }
    }
}