
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class ClientLinkColor extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let menuItem = this.event.target;
            let app: App = App.getInstance();
            app.service.settings.set(Entity.Map.Shape.Link.ClientLink.settingClientLinkColor, menuItem.value);
            app.service.settings.save((): void => {
                app.entityContainer.clients.map((client: Entity.Map.Marker.Node.ClientNode) => {
                    if(client.link) {
                        client.link.update();
                    }
                });

                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, 'Zmieniono kolor połączeń klienckich');
                alert.show();
            });
        }
    }
}
