/**
 * Created by Mateusz Partyka on 05.10.2016.
 */
namespace Entity.View.Menu {
    export class MainMenu extends Entity.View.ViewBase {
        protected _element;
        protected event: Action.UserActionEvent = null;

        constructor(element) {
            super();
            this._element = element;

            App.getInstance().service.callbackContainer.pushCallback('category', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.onCategoryUpdate();
                return true;
            });

            App.getInstance().service.callbackContainer.pushCallback('region', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.onRegionUpdate();
                return true;
            });

            App.getInstance().service.callbackContainer.pushCallback('tickets', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.onTicketsUpdate();
                return true;
            });
        }

        public populateMenu() {
            this.populateCategories();
            this.populateRegionsAndRange();
            this.refreshHideTicketsButton();
            //this.populateDevicesTypes();

            this.initializeMenuItems();
        }

        protected populateCategories(): void {
            let categories: Entity.Map.Type.Category[] = App.getInstance().entityContainer.categories;
            let ulCategories: HTMLUListElement = this._element.getElementsByClassName('main-menu-categories')[0] as HTMLUListElement;

            this.removeAllChildren(ulCategories);

            for (let category of categories) {
                if (!category)
                    continue;

                //wyświetlanie kategorii:
                let li: HTMLLIElement = document.createElement('li') as HTMLLIElement;
                let input: HTMLInputElement = document.createElement('input') as HTMLInputElement;
                let label: HTMLLabelElement = document.createElement('label') as HTMLLabelElement;

                input.id = 'menu-category-' + category.id;
                input.type = 'checkbox';
                input.checked = true;
                input.className += 'menu-category';
                input.setAttribute('data-action', 'menu-category');
                input.setAttribute('data-category', category.id.toString());

                label.htmlFor = 'menu-category-' + category.id;
                label.textContent = category.name;

                li.appendChild(input);
                li.appendChild(label);
                ulCategories.appendChild(li);
                //wyświetlanie kategorii - end
            }
        }

        protected populateRegionsAndRange(): void {
            let regions: Entity.Map.Type.Region[] = App.getInstance().entityContainer.regions;

            let ulRegions: HTMLUListElement = this._element.getElementsByClassName('main-menu-regions')[0] as HTMLUListElement;
            let ulRange: HTMLUListElement = this._element.getElementsByClassName('main-menu-range')[0] as HTMLUListElement;

            this.removeAllChildren(ulRegions);
            this.removeAllChildren(ulRange);

            for (let region of regions) {
                if (!region)
                    continue;

                //regiony:
                let liRegion: HTMLLIElement = document.createElement('li') as HTMLLIElement;
                let inputRegion: HTMLInputElement = document.createElement('input') as HTMLInputElement;
                let labelRegion: HTMLLabelElement = document.createElement('label') as HTMLLabelElement;

                inputRegion.id = 'menu-region-' + region.id;
                inputRegion.type = 'checkbox';
                inputRegion.checked = true;
                inputRegion.className += 'menu-region';
                inputRegion.setAttribute('data-action', 'menu-region');
                inputRegion.setAttribute('data-region', region.id.toString());

                labelRegion.htmlFor = 'menu-region-' + region.id;
                labelRegion.textContent = region.name;

                liRegion.appendChild(inputRegion);
                liRegion.appendChild(labelRegion);
                ulRegions.appendChild(liRegion);
                //regiony - end


                //zasięg:
                let liRange: HTMLLIElement = document.createElement('li') as HTMLLIElement;
                let inputRange: HTMLInputElement = document.createElement('input') as HTMLInputElement;
                let labelRange: HTMLLabelElement = document.createElement('label') as HTMLLabelElement;

                inputRange.id = 'menu-show-range-' + region.id;
                inputRange.type = 'checkbox';
                inputRange.checked = false;
                inputRange.className += 'menu-show-range';
                inputRange.setAttribute('data-action', 'menu-show-range');
                inputRange.setAttribute('data-region', region.id.toString());

                labelRange.htmlFor = 'menu-show-range-' + region.id;
                labelRange.textContent = region.name;

                liRange.appendChild(inputRange);
                liRange.appendChild(labelRange);
                ulRange.appendChild(liRange);
                //zasięg - end
            }
        }

        protected populateDevicesTypes(): void {
            let nodeContainer: HTMLUListElement = document.querySelector('.main-menu-types') as HTMLUListElement;
            let types: string[] = [];

            App.getInstance().entityContainer.devices.forEach(device => {
                if (device && device.type && !types.includes(device.type.name))
                    types.push(device.type.name);
            });

            types.forEach(type => {
                let li: HTMLLIElement = document.createElement('li');
                let input: HTMLInputElement = document.createElement('input');
                let label: HTMLLabelElement = document.createElement('label');

                input.type = 'checkbox';
                input.id = 'menu-types-' + type;
                input.className = 'menu-type';
                input.checked = true;
                input.dataset.action = 'menu-type';
                input.dataset.type = type;

                label.innerHTML = type;
                label.htmlFor = input.id;

                li.appendChild(input);
                li.appendChild(label);

                nodeContainer.appendChild(li);
            });
        }

        protected refreshHideTicketsButton(): void {
            let hideTicketsButton: HTMLElement = document.getElementById('li-menu-tickets-hide');
            if (!hideTicketsButton)
                return;

            if (App.getInstance().entityContainer.tickets.length > 0)
                hideTicketsButton.style.display = 'block';
            else
                hideTicketsButton.style.display = 'none';
        }

        protected onCategoryUpdate(): void {
            this.populateCategories();
        }

        protected onRegionUpdate(): void {
            this.populateRegionsAndRange();
        }

        protected onTicketsUpdate(): void {
            this.refreshHideTicketsButton();
        }

        protected initializeMenuItems() {
            let menuItems = this._element.getElementsByTagName('input');
            let menuItemButtonIcons = this._element.getElementsByClassName('button-icon');

            for (let menuItem of menuItems) {
                menuItem.addEventListener('change', () => {
                    this.onClick(menuItem);
                });

                if (menuItem.id == 'client-link-color') {
                    let clientLinkColor: string | number = App.getInstance().service.settings.get(Entity.Map.Shape.Link.ClientLink.settingClientLinkColor);
                    menuItem.value = clientLinkColor ? clientLinkColor.toString() : '#000000';
                }
            }

            for (let menuItem of menuItemButtonIcons) {
                menuItem.addEventListener('click', () => {
                    this.onClick(menuItem);
                });
            }
        }

        protected onClick(menuItem): void {
            try {
                let collection: Control.EntityContainer = App.getInstance().entityContainer;
                let button = menuItem.dataset.button;
                let action = menuItem.dataset.action;

                if (button == 'true') {
                    menuItem.checked = false;
                }

                if (!action)
                    return;

                this.event = new Action.UserActionEvent(menuItem, {});

                Factory.UserActionFactory.invokeAction(action, 'MainMenu', this.event);
            }
            catch (e) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, e.message);
                alert.show();
            }
        }
    }
}