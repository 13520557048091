/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuType extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let menuItem = this.event.target;

            if (menuItem.dataset.type === 'clients')
                this.app.entityContainer.toggleClients(menuItem.checked);
            else
                this.app.entityContainer.toggleDeviceType(menuItem.dataset.type, menuItem.checked);
        }
    }
}
