/**
 * Created by Mateusz Partyka on 16.09.2016.
 */
namespace Entity.Map.Marker.Node {
    export class ClientNode extends Node {
        protected _client_service_id: number;
        protected _client_id: number;
        protected _price2: string;
        protected _name: string;
        protected _ip: string;
        protected _address: string;
        protected _comment: string;
        protected _gps: string;
        protected _node_id: number;
        protected _company: string;
        protected _resignation_date: string;
        protected _signal: number;

        protected _clientLink: Entity.Map.Shape.Link.ClientLink = null;
        protected _deviceOffsetPolyline: google.maps.Polyline = null;
        protected _clientOffsetPolyline: google.maps.Polyline = null;

        public static devicesRadius: number = 3; //w metrach
        public static clientsRadius: number = 1; //w metrach

        constructor(id: number, lat: number, lng: number, data: Object, map: google.maps.Map) {
            super(id, lat, lng, data, map);

            this._client_service_id = parseInt(data['id']);
            this._client_id = parseInt(data['klient_id']);
            this._price2 = data['cena2'];
            this._name = data['nazwa'];
            this._ip = data['ip'];
            this._address = data['adres'];
            this._comment = data['komentarz'];
            this._gps = data['gps'];
            this._node_id = parseInt(data['wezel_id']);
            this._company = data['spolka'];
            this._signal = parseInt(data['sygnal']);
            this._resignation_date = data['data_rezygnacji'];

            if(this._signal == null || this._signal == undefined || isNaN(this._signal))
                this._signal = null;

            if(this._node_id > 0) {
                let node: Entity.Map.Marker.Node.DeviceNode = App.getInstance().entityContainer.findNode(this._node_id.toString()) as Entity.Map.Marker.Node.DeviceNode;
                if(node) {
                    node.registerClientNode(this);
                }
            }

            this.update();
        }

        public update(): void {
            let noSignalColor: string = config.signal.noSignalColor;
            let noAntennaColor: string = config.signal.noAntennaColor;

            if(this._signal == -1)
                this.chld = `0.6|0|${noAntennaColor}|12|_|KL`;
            else if(this._signal != null && this._signal != undefined)
                this.chld = `0.6|0|${this.color}|12|_|KL`;
            else
                this.chld = `0.6|0|${noSignalColor}|12|_|KL`;

            if(this._deviceOffsetPolyline) {
                this._deviceOffsetPolyline.setMap(null);
                this._deviceOffsetPolyline = null;
            }

            if(this._clientOffsetPolyline) {
                this._clientOffsetPolyline.setMap(null);
                this._clientOffsetPolyline = null;
            }

            if(!this._ip)
                this._ip = '0.0.0.0';
            else if (this._ip != '0.0.0.0') {
                this._ip = this._ip.split('.').map(function (elem) {
                    return parseInt(elem, 10);
                }).join('.');
            }

            if(this._marker) {
                this._marker.setIcon(this.getIconPath());
            }

            this.determineVisibility();
        }

        public showNode(): void {
            super.showNode();

            if(this._deviceOffsetPolyline)
                this._deviceOffsetPolyline.setVisible(true);
            if(this._clientOffsetPolyline)
                this._clientOffsetPolyline.setVisible(true);
        }

        public hideNode(): void {
            super.hideNode();

            if(this._deviceOffsetPolyline)
                this._deviceOffsetPolyline.setVisible(false);
            if(this._clientOffsetPolyline)
                this._clientOffsetPolyline.setVisible(false);
        }

        public getDetails(additionalData: Object = null): string {
            let lines: string[] = [];
            let details: Object = {};
            let detailsTable: string = '';
            let devicesTable: string = '';
            let elevation: string = '';

            let zoom: number = this._map.getZoom();

            lines.push('Klient <a href="' + config.panelUrl + '?co=klienci&klient=1&id=' + this._client_id + '" target="_blank">' + this._client_id + '</a> - ' + this._name);
            lines.push(this._address ? this._address : ' - brak adresu - ');
            lines.push('<a href="?center=' + this.gps + '&zoom=' + zoom + '">' + this.gps + '</a>');

            details = {
            };

            for(let key in details) {
                detailsTable += '<tr><td style="text-align: right;">' + key + ': </td><td>' + details[key] + '</td></tr>';
            }
            detailsTable = '<table>' + detailsTable + '</table>';

            //dodatkowe dane, ładowane po wyświetleniu info window:
            if(additionalData && additionalData['devices']) {
                for(let key in additionalData['devices']) {
                    if(additionalData['devices'].hasOwnProperty(key) && additionalData['devices'][key]) {
                        let device: Entity.Device.Device = additionalData['devices'][key];
                        devicesTable += '<tr><td style="text-align: right;">'
                            + device.name + '</td><td>'
                            + Logic.Util.generateIpAddressLink(device.ip) + '</td><td>'
                            + device.ssid + '</td><td>'
                            + device.freq + '</td><td>'
                            + (device.mikrotik ? 'mikrotik ' : '')
                            + (device.ubiquiti ? 'ubiquiti ' : '')
                            + '</td></tr>';
                    }
                }
                if(devicesTable)
                    devicesTable = '<div class="infoRow infoSubnodesHeader">Urządzenia:</div><table cellspacing="2" style="text-align: center;">' + devicesTable + '</table>';
            }
            if(additionalData && additionalData['elevation']) {
                elevation = '<div class="infoRow infoSubnodesHeader" style="display: inline-block">Wysokość NPM:&nbsp;&nbsp;</div>' + Logic.Util.formatDecimal(additionalData['elevation'], ' m');
            }
            //dodatkowe dane, ładowane po wyświetleniu info window - end

            lines.push('<a href="#" onclick="this.parentNode.getElementsByClassName(\'infoDetails\')[0].style.display = \'block\'; this.style.display = \'none\'">Szczegóły</a><div class="infoDetails">' + detailsTable + devicesTable + elevation + '</div>');
            return lines.join('<br>');
        }

        public moveBasedOnNearbyMarkers(): void {
            let clientsInRadius: Entity.Map.Marker.Node.ClientNode[] = App.getInstance().entityContainer.findClientsInRadius(this.getPositionObject(), Entity.Map.Marker.Node.ClientNode.clientsRadius);
            if(clientsInRadius.length > 1) {
                let offsetLatLng: google.maps.LatLng = new google.maps.LatLng(this._lat, this._lng - (0.0001 * (clientsInRadius.length - 1)));
                this._marker.setPosition(offsetLatLng);
                this._clientOffsetPolyline = new google.maps.Polyline({
                    path: [this.getPositionObject(), offsetLatLng],
                    strokeWeight: 1
                });
                this._clientOffsetPolyline.setMap(this._map);
                this._lat = offsetLatLng.lat();
                this._lng = offsetLatLng.lng();

                for(let clientNode of clientsInRadius) {
                    if(!clientNode || clientNode == this)
                        continue;
                    clientNode.moveBasedOnNearbyMarkers();
                }
                this.moveBasedOnNearbyMarkers();
            }

            let devicesInRadius: Entity.Map.Marker.Node.DeviceNode[] = App.getInstance().entityContainer.findDevicesInRadius(this.getPositionObject(), Entity.Map.Marker.Node.ClientNode.devicesRadius);
            if(devicesInRadius.length > 0) {
                let offsetLatLng: google.maps.LatLng = new google.maps.LatLng(this._lat, this._lng - 0.0001);
                this._marker.setPosition(offsetLatLng);
                this._deviceOffsetPolyline = new google.maps.Polyline({
                    path: [this.getPositionObject(), offsetLatLng],
                    strokeWeight: 1
                });
                this._deviceOffsetPolyline.setMap(this._map);
                this._lat = offsetLatLng.lat();
                this._lng = offsetLatLng.lng();
                this.moveBasedOnNearbyMarkers();
            }
        }

        protected onClick(params): void {
            super.onClick(params);

            this.moveBasedOnNearbyMarkers();
        }

        get color(): string {
            if (this._resignation_date !== '0000-00-00')
                return config.colors.resigned;

            //TODO partial solution, not always detecting right

            if (this._signal === 0)
                return config.colors.wired;

            let min: number = config.signal.min;
            let max: number = config.signal.max;

            let parsedSignal = min;
            if(this._signal != undefined && this._signal != null && !isNaN(this._signal)) {
                parsedSignal = Math.max(min, Math.min(this._signal, max));
            }

            let huePosition: number = ((parsedSignal - min) / (max - min));

            let h: number = (config.signal.minHue + huePosition * (config.signal.maxHue - config.signal.minHue));
            let s: number = config.signal.saturation;
            let v: number = config.signal.value;

            let rgb: number[] = Logic.Util.HSVtoRGB(h, s, v);
            return Logic.Util.rgbToHex(rgb[0], rgb[1], rgb[2]);
        }

        get client_service_id(): number {
            return this._client_service_id;
        }

        get client_id(): number {
            return this._client_id;
        }

        get name(): string {
            return this._name ? this._name : '';
        }

        set name(name: string) {
            this._name = name;
        }

        get ip(): string {
            return this._ip ? this._ip : '';
        }

        set ip(ip:string) {
            this._ip = ip;
        }

        get address(): string {
            return this._address ? this._address : '';
        }

        set address(address: string) {
            this._address = address;
        }

        get comment(): string {
            return this._comment ? this._comment : '';
        }

        set comment(comment: string) {
            this._comment = comment;
        }

        get gps(): string {
            return this._gps ? this._gps : '';
        }

        set gps(gps:string) {
            this._gps = gps;
        }

        get node_id(): number {
            return this._node_id;
        }

        set node_id(node_id: number) {
            this._node_id = node_id;
        }

        get link(): Entity.Map.Shape.Link.ClientLink {
            return this._clientLink;
        }

        set link(link: Entity.Map.Shape.Link.ClientLink) {
            this._clientLink = link;
        }
    }
}
