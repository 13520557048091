/**
 * Created by Mateusz Lipowski on 24.11.2016.
 */


namespace Entity.Map {
    import poly = google.maps.geometry.poly;

    export class Map extends Entity.Map.MapEntity {
        protected _map: google.maps.Map = null;
        protected _isRouteShown: boolean = false;
        protected _isDrag: boolean = false;

        public constructor(protected _mapCanvas: HTMLCanvasElement, protected urlParams: any) {
            super();
            let map = new google.maps.Map(this._mapCanvas, this.getOptions());

            map.addListener('click', (params) => {
                // if POI
                if (params.placeId) return;

                this.onClick(params.latLng as google.maps.LatLng, params.text, params.writeHistory);
            });

            map.addListener('dragstart', (params) => {
                this._isDrag = true;
            });

            map.addListener('dragend', (params) => {
                this._isDrag = false;
            });

            map.addListener('idle', (params) => {
                this.onIdle();
            });

            this._map = map;
            this.body = this._map;
            this.setupInputEvent(InputEvent.RightClick, (params => {
                App.getInstance().service.contextMenu.show(this, new Action.UserActionEvent(this, params, null));
            }));
        }

        public showRoute(directionsResult: google.maps.DirectionsResult): void {
            let directionsRenderer: google.maps.DirectionsRenderer = App.getInstance().service.directionsRenderer;
            directionsRenderer.setMap(this.map);
            directionsRenderer.setDirections(directionsResult);
            this._isRouteShown = true;
        }

        public hideRoute(): void {
            if (!this._isRouteShown)
                return;

            let directionsRenderer: google.maps.DirectionsRenderer = App.getInstance().service.directionsRenderer;
            directionsRenderer.setMap(null);
            this._isRouteShown = false;
        }

        public isRouteShown(): boolean {
            return this._isRouteShown;
        }

        public isDrag(): boolean {
            return this._isDrag;
        }

        private getBoundariesForPolyline(poly: google.maps.Polyline) {
            let bounds = new google.maps.LatLngBounds;

            poly.getPath().forEach((latLng) => {
                bounds.extend(latLng);
            });

            return bounds;
        }

        protected onIdle(): void {
            if (this._map) {
                let map: google.maps.Map = this._map;
                let bounds = this._map.getBounds();
                let polylines: google.maps.Polyline[] = App.getInstance().entityContainer.polylines;

                // Ukrywanie linii poza obszarem mapy
                for (let i = 0; i < polylines.length; i++) {
                    if (bounds.intersects(this.getBoundariesForPolyline(polylines[i]))) {
                        polylines[i].setMap(map);
                    } else {
                        polylines[i].setMap(null);
                    }
                }
            }
        }

        protected getOptions(): google.maps.MapOptions {
            return {
                center: {lat: parseFloat(this.urlParams.lat), lng: parseFloat(this.urlParams.lng)},
                zoom: parseInt(this.urlParams.zoom),
                mapTypeId: google.maps.MapTypeId.HYBRID
            };
        }

        public onClick(latLng: google.maps.LatLng, text: string = '', writeHistory: boolean = true): void {
            let infoWindow: Entity.View.InfoWindows.InfoWindow = App.getInstance().service.infoWindow;
            let content: string[] = [];
            let gps: string = Logic.Util.shortenGPS(latLng.lat() + ',' + latLng.lng());
            let zoom: number = this._map.getZoom();
            let geocode: Logic.Geocode = new Logic.Geocode();
            let elevation: Logic.Elevation = new Logic.Elevation(latLng);
            let textarea = document.createElement('textarea');
            let container = document.createElement('div');
            let a: HTMLAnchorElement;
            let link: string = '?center=' + gps + '&zoom=' + zoom;
            container.className = 'iw-container';

            geocode.getAddress(latLng.lat(), latLng.lng(), (address: string): void => {
                content[0] = address;
                content[1] = '<a href="' + link + (text ? '&text=' + encodeURIComponent(text) : '') + '" class="point-link">' + gps + '</a>';
                content[2] = 'Wysokość NPM: ...';

                if (writeHistory)
                    history.replaceState({
                        lat: latLng.lat(),
                        lng: latLng.lng(),
                        address
                    }, 'Mapy Wave - ' + address, '/?center=' + gps);

                elevation.requestElevation((height: number): void => {
                    content[2] = 'Wysokość NPM: ' + (height === null ? 'Nie można pozyskać wysokości' : (Logic.Util.formatDecimal(height, ' m')));
                    container.innerHTML = content.join('<br>');
                    container.appendChild(textarea);
                    infoWindow.setContent(container);
                });

                textarea.value = text;
                textarea.className = 'link-description';
                textarea.addEventListener('keyup', (event: Event) => {
                    if (!a) a = document.querySelector('.point-link');
                    if (textarea.value)
                        a.href = link + '&text=' + encodeURIComponent(textarea.value);
                    textarea.classList.add('is-visible');
                });

                if (text)
                    textarea.classList.add('is-visible');

                container.appendChild(textarea);

                infoWindow.setPosition(latLng);
                infoWindow.setContent(container);
                infoWindow.open(this.map);
            });
        }

        get map(): google.maps.Map {
            return this._map;
        }
    }
}