/**
 * Created by Mateusz Lipowski on 27.01.2017.
 */

namespace Entity.Map.Marker {
    export abstract class Marker extends Entity.Map.MapEntity {

        //protected static ICON_PATH = 'https://chart.apis.google.com/chart?';
        protected static ICON_DRAG = '/img/marker-drag.png';

        protected chst: string = 'd_map_spin';
        protected chld: string = '';
        protected defaultColor: string = 'FFFFFF';

        protected _marker: google.maps.Marker;
        protected _map: google.maps.Map;
        protected _lat: number;
        protected _lng: number;
        protected _isVisible: boolean = true;

        public constructor(lat: number, lng: number, map: google.maps.Map) {
            super();
            this._lat = lat;
            this._lng = lng;
            this._map = map;
        }

        public setMap(map:google.maps.Map):void {
            this._map = map;
            if(this._marker) {
                this._marker.setMap(map);
            }
        }

        public getPosition():string {
            return `${this._lat},${this._lng}`;
        }

        public getPositionObject():google.maps.LatLng {
            return new google.maps.LatLng(this._lat, this._lng);
        }

        public toggleVisibility() {
            if(!this._marker)
                return;

            if (this._marker.getMap() != null)
                this._marker.setMap(null);
            else
                this._marker.setMap(this._map);
        }

        public determineVisibility() {
            if(!this._marker || !this._map)
                return;

            this.show();
        }

        protected onClick(params): void {

        }

        protected show(): void {
            this._marker.setVisible(true);
            this._isVisible = true;
        }

        protected hide(): void {
            this._marker.setVisible(false);
            this._isVisible = false;
        }

        protected initializeMarker():void {
            this._marker = new google.maps.Marker({
                position: this.getPositionObject(),
                icon: this.getIconObject(),
            });

            this._marker.addListener('click', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.MARKER, new Control.CallbackContainer.Event('click', this, params));
                this.onClick(params);
            });

            this._marker.addListener('rightclick', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.MARKER, new Control.CallbackContainer.Event('rightclick', this, params));
            });

            this._marker.addListener('drag', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.MARKER, new Control.CallbackContainer.Event('drag', this, params));
            });

            this._marker.addListener('dragend', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.MARKER, new Control.CallbackContainer.Event('dragend', this, params));
            });

            this.body = this._marker;
            this.setupInputEvent(InputEvent.RightClick, (params => {
                App.getInstance().service.contextMenu.show(this, new Action.UserActionEvent(this, params, null));
            }));
        }

        protected getIconObject(): Object {
            return this.getIconPath();
        }

        protected getIconPath(): string {
            return '/img/pin_icon.png';
        }

        get color(): string {
            return this.defaultColor;
        }

        get marker(): google.maps.Marker {
            return this._marker;
        }
    }
}