/**
 * Created by Mateusz Lipowski on 23.11.2016.
 */

namespace Entity.Map.NetworkRange {
    type rangePolygonsArray = Entity.Map.Shape.RangePolygon[];

    interface UpdateRangePaths {
        id: number,
        pathJson: string,
        markedForDeletion: boolean
    }

    export class RangeUpdater extends Entity.Map.MapEntity {
        protected changedRangePolygons: rangePolygonsArray[] = [];
        protected updateRangePathsArray: UpdateRangePaths[] = null;

        public constructor() {
            super();
        }

        public registerEditedRangePolygon(editedRangePolygon: Entity.Map.Shape.RangePolygon): void {
            let regionId: number = editedRangePolygon.region.id;
            if(!this.changedRangePolygons[regionId])
                this.changedRangePolygons[regionId] = [];

            for(let rangePolygon of this.changedRangePolygons[regionId]) {
                if(rangePolygon == editedRangePolygon)
                    return;
            }

            this.changedRangePolygons[regionId].push(editedRangePolygon);
        }

        /**
         * Metoda generująca tablicę updateRangePathsArray, która zawiera informacje o nowych ścieżkach obszarów, używane jako zmienna POST dla akcji backendowej
         * @return void
         */
        protected generateUpdateRangePathsArray(regionId: number): void {
            if(!this.changedRangePolygons[regionId])
                return;

            this.updateRangePathsArray = [];

            for(let rangePolygon of this.changedRangePolygons[regionId]) {
                if(!rangePolygon || !rangePolygon.polygon)
                    continue;

                rangePolygon.pathJson = Logic.Util.generatePathJson(rangePolygon.polygon.getPath());
                this.updateRangePathsArray.push({
                    id: rangePolygon.id,
                    pathJson: rangePolygon.pathJson,
                    markedForDeletion: rangePolygon.markedForDeletion
                });
            }
        }

        /**
         * Wysłanie żądania do serwera, które zaktualizuje bazę danych o nowe ścieżki dla istniejących obszarów i doda nowe obszary
         * Zmienna POST opisująca zmiany pochodzi ze zmiennej updateRangePathsArray, gdzie dla danego id wyznaczona jest nowa ścieżka, dla id = 0 ścieżka opisuje nowy obszar zasięgu
         */
        public requestRangeUpdate(): void {
            if(!this.changedRangePolygons)
                return;

            if(this.changedRangePolygons.length <= 0) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.INFO, 'Nie zmodyfikowano żadnych obszarów zasięgu');
                alert.show();
                return;
            }

            for(let regionId in this.changedRangePolygons) {
                this.generateUpdateRangePathsArray(parseInt(regionId));

                let requestSender: Logic.RequestSender = new Logic.RequestSender();

                let polygonCount: number = this.updateRangePathsArray.length;
                let updateRangePathsJson: string = JSON.stringify(this.updateRangePathsArray);
                let formData: FormData = new FormData();

                formData.append('pathsJson', updateRangePathsJson);

                requestSender.sendRequest('EditRange', {region_id: regionId}, (action: string, fields: Object, response: any): void => {
                    if (requestSender.isError()) {
                        let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                        alert.show();
                    }
                    else {
                        let insertIds: string[] = response.insertIds;
                        let lastInsertId: number = 0;
                        let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, 'Zmodyfikowana ilość obszarów dla regionu ' + regionId + ': ' + polygonCount);
                        alert.show();

                        if (this.changedRangePolygons[regionId]) {
                            for (let key in this.changedRangePolygons[regionId]) {
                                if (this.changedRangePolygons[regionId].hasOwnProperty(key) && this.changedRangePolygons[regionId][key]) {
                                    let rangePolygon: Entity.Map.Shape.RangePolygon = this.changedRangePolygons[regionId][key];

                                    if(rangePolygon.markedForDeletion) {
                                        App.getInstance().service.rangeManager.deleteRangePolygon(rangePolygon);
                                        this.changedRangePolygons[regionId][key] = null;
                                    }
                                    else if(!rangePolygon.id) {
                                        rangePolygon.id = parseInt(insertIds[lastInsertId]);
                                        lastInsertId++;
                                    }
                                }
                            }
                        }

                        this.changedRangePolygons = [];
                        this.updateRangePathsArray = null;
                    }
                }, formData);
            }
        }
    }
}
