/**
 * Created by Mateusz Lipowski on 03.11.2016.
 */

namespace Entity.Device {
    export class DeviceRequestResponse extends Entity.BaseEntity {
        protected requestSender: Logic.RequestSender = null;
        protected getDevicesActionName: string = 'GetDevices';
        protected getDeviceActionName: string = 'GetDevice';
        protected _response: any = null;
        protected _message: string = null;
        protected _error: boolean = false;

        protected _devices: Entity.Device.Device[] = [];
        protected _device: Entity.Device.Device = null;

        public constructor() {
            super();
            this.requestSender = new RequestSender();
        }

        public requestDeviceById(deviceId: number, callback: () => void): void {
            this.requestSender.sendRequest(this.getDeviceActionName, {id: deviceId}, (action: string, fields: Object, response: any): void => {
                if(this.requestSender.isError()) {
                    this._response = null;
                    this._error = true;
                }
                else {
                    this._response = response;
                    this._device = new Entity.Device.Device(this.response);
                    this._error = false;
                }

                this._message = this.requestSender.getMessage();
                callback();
            });
        }

        public requestDevicesForNode(nodeId: number, callback: () => void): void {
            this.requestSender.sendRequest(this.getDevicesActionName, {wezel_id: nodeId}, (action: string, fields: Object, response: any): void => {
                if(this.requestSender.isError()) {
                    this._response = null;
                    this._error = true;
                }
                else {
                    this._response = response;
                    this.parseResponse();
                    this._error = false;
                }

                this._message = this.requestSender.getMessage();
                callback();
            });
        }

        public requestDevicesForClientService(clientServiceId: number, callback: () => void): void {
            this.requestSender.sendRequest(this.getDevicesActionName, {klient_usluga_id: clientServiceId}, (action: string, fields: Object, response: any): void => {
                if(this.requestSender.isError()) {
                    this._response = null;
                    this._error = true;
                }
                else {
                    this._response = response;
                    this.parseResponse();
                    this._error = false;
                }

                this._message = this.requestSender.getMessage();
                callback();
            });
        }

        protected parseResponse(): void {
            let devicesJson = this.response.devices;
            let devicesObject: Object = null;

            if(devicesJson == undefined)
                throw new Error('PhotoRequestResponse.parseResponse: Can\'t parse devices JSON, property "devices" in response is undefined');

            this.clearParsedResponseArrays();

            devicesObject = JSON.parse(devicesJson);

            for(let key in devicesObject) {
                if(devicesObject.hasOwnProperty(key) && devicesObject[key])
                    this._devices.push(new Entity.Device.Device(devicesObject[key]));
            }
        }

        protected clearParsedResponseArrays(): void {
            this._devices = [];
        }

        public get response(): any {
            return this._response;
        }

        public get message(): string {
            return this._message;
        }

        public get error(): boolean {
            return this._error;
        }

        public get devices(): Entity.Device.Device[] {
            return this._devices;
        }

        public get device(): Entity.Device.Device {
            return this._device;
        }
    }
}