/**
 * Created by Mateusz Lipowski on 12.10.2016.
 */

namespace Logic {
    export class GeocodeResult {

        protected _foundAddress: string;
        protected _latLng: google.maps.LatLng;

        public constructor(protected _queryAddress: string, protected _rawResult: google.maps.GeocoderResult) {
            let location: any = this._rawResult.geometry.location;
            this._latLng = new google.maps.LatLng(location['lat'], location['lng']);
            this._foundAddress = this._rawResult.formatted_address;
        }

        public get queryAddress(): string {
            return this._queryAddress;
        }

        public get foundAddress(): string {
            return this._foundAddress;
        }

        public get latLng(): google.maps.LatLng {
            return this._latLng;
        }

        public get rawResult(): google.maps.GeocoderResult {
            return this._rawResult;
        }
    }
}
