/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class OfferEdit extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected offer: Entity.Map.NetworkRange.Offer = null;
        protected form: HTMLFormElement = null;
        protected deleteButton: HTMLInputElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.offer = this.event.target as Entity.Map.NetworkRange.Offer;
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('offer_edit');
            this.form = this.window.getForm();
            this.deleteButton = this.form['delete'] as HTMLInputElement;

            this.deleteButton.addEventListener('click', (e: MouseEvent): void => {
                if(confirm('Na pewno?')) {
                    this.sendDeleteRequest();
                }
            });

            this.initView();
            this.initFormSender();
        }

        protected initView(): void {
            this.form['id']['value'] = this.offer.id;
            this.form['name']['value'] = this.offer.name;
            this.form['color'].value = this.offer.color;
            this.form['description'].value = this.offer.description;
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    this.offer.name = this.form['name']['value'];
                    this.offer.color = this.form['color'].value;
                    this.offer.description = this.form['description'].value;
                    this.app.service.callbackContainer.invokeCallbacks('offer', new Control.CallbackContainer.Event('update', this.offer, {}));

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }

        protected sendDeleteRequest(): void {
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('DeleteOffer', {id: this.offer.id}, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    this.app.entityContainer.deleteOffer(this.offer.id);
                    this.app.service.callbackContainer.invokeCallbacks('offer', new Control.CallbackContainer.Event('delete', this.offer, {}));
                    this.app.service.callbackContainer.invokeCallbacks('offer', new Control.CallbackContainer.Event('update', this.offer, {}));
                    this.offer = null;

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
