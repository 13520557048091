/**
 * Created by Mateusz Lipowski on 03.10.2016.
 */

namespace Action.ContextMenu {
    export class LinkEdit extends UserActionBase {
        protected window: Entity.View.Window.Window = null;
        protected form: HTMLFormElement = null;
        protected formSender: Logic.FormSender = null;
        protected link: Entity.Map.Shape.Link.Link = null;
        protected node1: Entity.Map.Marker.Node.Node = null;
        protected node2: Entity.Map.Marker.Node.Node = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
            this.link = event.target as Entity.Map.Shape.Link.Link;
            this.node1 = this.link.node1;
            this.node2 = this.link.node2;
            this.window = App.getInstance().service.windowContainer.createWindow();
        }

        public execute(): void {
            this.window.show('link_edit');

            this.window.setOnDismiss(() => {
                if(this.formSender) {
                    this.formSender.dispose();
                    this.formSender = null;
                }
            });

            this.initForm();
            this.initFormSender();
        }


        /**
         * Pobiera button formularza, uzupełnia formularz o domyślne dane
         */
        protected initForm() {
            this.form = this.window.getForm() as HTMLFormElement;

            let categories: Entity.Map.Type.Category[] = App.getInstance().entityContainer.categories;
            let linkTypes: Entity.Map.Type.LinkType[] = App.getInstance().entityContainer.linkTypes;
            let guessedCategoryName: string = this.link.guessedCategory ? this.link.guessedCategory.name : 'Brak';
            let node1Info = this.form.getElementsByClassName('node1-info')[0];
            let node2Info = this.form.getElementsByClassName('node2-info')[0];

            node1Info.textContent = this.getNodeInfo(this.node1);
            node2Info.textContent = this.getNodeInfo(this.node2);

            this.form['wezel1_id'].value = this.node1.id;
            this.form['wezel2_id'].value = this.node2.id;

            this.populateSelect(this.form['kategoria_id'], categories, 'id', 'name', this.link.kategoria_id.toString(), 'Auto (' + guessedCategoryName + ')');
            this.populateSelect(this.form['polaczenie_typ'], linkTypes, 'id', 'name', this.link.polaczenie_typ.toString(), ' - brak - ');

            this.form['id']['value'] = this.link.id;
            this.form['parametry'].value = this.link.parametry;
            this.form['backup'].checked = (this.link.backup == '1');
        }

        /**
         * Tworzy i inicjuje Logic.FormSender, definuje akcję po wykonaniu formularza
         */
        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    this.link.kategoria_id = this.form['kategoria_id'].value;
                    this.link.polaczenie_typ = this.form['polaczenie_typ'].value;
                    this.link.parametry = this.form['parametry'].value;
                    this.link.backup = this.form['backup'].checked;
                    this.link.update();

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}