/**
 * Created by Mateusz Lipowski on 14.11.2016.
 */

namespace Entity.Map.Shape {
    export class RangePolygon extends Entity.Map.Shape.Polygon {
        protected static zIndexCounter: number = 1;
        protected static defaultColor: string = '#84b3ff';

        protected _id: number;
        protected _pathJson: string;
        protected _markedForDeletion: boolean = false;
        protected _exclusiveVisibility: boolean = false;
        protected _color: string = Entity.Map.Shape.RangePolygon.defaultColor;
        protected _strokeColor: string = '#000000';
        protected _rangeOffers: Entity.Map.NetworkRange.RangeOffer[] = [];

        protected _region: Entity.Map.Type.Region = null;
        protected _zIndex: number = 0;
        protected _paths: any[] = null;

        protected _oldZIndex: number = 0;
        protected _oldColor: string = '';
        protected _oldStrokeColor: string = '';

        constructor(path: google.maps.LatLng[], rowData: Object, polygon: google.maps.Polygon = null) {
            super(null);
            this._id = parseInt(rowData['id']);
            this._pathJson = rowData['wierzcholki'];
            this._region = App.getInstance().entityContainer.findRegion(rowData['region_id']);

            for(let rangeOfferObject of rowData['range_offers']) {
                if(!rangeOfferObject)
                    continue;

                this._rangeOffers.push(new Entity.Map.NetworkRange.RangeOffer(rangeOfferObject));
            }

            if(!this._region)
                throw new Error('Shapes.RangePolygon.constructor: Nie można znaleźć regionu o id ' + rowData['region_id']);

            if(polygon)
                this._polygon = polygon;
            else
                this._polygon = new google.maps.Polygon();

            this._zIndex = Entity.Map.Shape.RangePolygon.zIndexCounter;
            this._polygon.setPaths((path == null && polygon != null) ? polygon.getPath().getArray() : path);
            //this._paths = (path == null && polygon != null) ? polygon.getPath().getArray() : path;

            this._oldZIndex = this.zIndex;
            this._oldColor = this.color;
            this._oldStrokeColor = this.strokeColor;

            this.updateOptions();

            this.setVisible(false);
            this._polygon.setMap(App.getInstance().service.map);

            this._polygon.addListener('rightclick', (params) => {
                if(this._polygon.getEditable())
                    App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, new Control.CallbackContainer.Event('rightclick', this, params));
            });
            this._polygon.addListener('click', (params) => {
                if(this._polygon.getEditable())
                    App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, new Control.CallbackContainer.Event('click', this, params));
                this.onClick(params['latLng']);
            });
            this._polygon.addListener('mousemove', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, new Control.CallbackContainer.Event('mousemove', this, params));
            });
            this._polygon.addListener('mouseout', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, new Control.CallbackContainer.Event('mouseout', this, params));
            });
            this._polygon.addListener('mousedown', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, new Control.CallbackContainer.Event('mousedown', this, params));
            });
            this._polygon.addListener('mouseup', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, new Control.CallbackContainer.Event('mouseup', this, params));
            });
            this._polygon.addListener('dragend', (params) => {
                App.getInstance().service.rangeManager.registerEditedRangePolygon(this);
            });

            Entity.Map.Shape.RangePolygon.zIndexCounter++;
        };

        public addOffer(offer: Entity.Map.NetworkRange.Offer): void {
            let rowData: string[] = [];
            rowData['zasieg_id'] = this.id.toString();
            rowData['oferta_id'] = offer.id.toString();
            rowData['glowna'] = '0';
            let rangeOffer: Entity.Map.NetworkRange.RangeOffer = new Entity.Map.NetworkRange.RangeOffer(rowData);
            this.rangeOffers.push(rangeOffer);
        }

        public removeOffer(offerId: number): void {
            for(let i = 0; i < this.rangeOffers.length; i++) {
                if(!this.rangeOffers[i] || this.rangeOffers[i].offer_id != offerId)
                    continue;

                this.rangeOffers.splice(i, 1);
                break;
            }
        }

        public isOverlappingWith(targetPolygon: Entity.Map.Shape.RangePolygon): boolean {
            let gpcThisPoly: PolyDefault = Logic.Util.pathToGpcPoly(this.getPath());
            let gpcTargetPoly: PolyDefault = Logic.Util.pathToGpcPoly(targetPolygon.getPath());

            try {
                let resultPoly: PolyDefault = gpcThisPoly.intersection(gpcTargetPoly);
                resultPoly.getNumPoints();
                return (resultPoly != undefined);
            }
            catch (e) {
                return false;
            }
        }

        public isIntersectingWith(targetPolygon: Entity.Map.Shape.RangePolygon): boolean {
            let gpcThisPoly: PolyDefault = Logic.Util.pathToGpcPoly(this.getPath());
            let gpcTargetPoly: PolyDefault = Logic.Util.pathToGpcPoly(targetPolygon.getPath());

            let resultPoly: PolyDefault = gpcTargetPoly.union(gpcThisPoly);

            let numNotHoles: number = 0;
            for(let i = 0; i < resultPoly.getNumInnerPoly(); i++) {
                if(!resultPoly.getInnerPoly(i).isHole())
                    numNotHoles++;
            }

            return (numNotHoles == 1);
        }

        public split(vertex1Index: number, vertex2Index: number): void {
            let path1: google.maps.MVCArray = new google.maps.MVCArray();
            let path2: google.maps.MVCArray = new google.maps.MVCArray();
            let appendToPath1: boolean = true;

            this._polygon.getPath().forEach((element: any, i: number): void => {
                if(i == vertex1Index || i == vertex2Index) {
                    path1.push(element);
                    path2.push(element);
                    appendToPath1 = !appendToPath1;
                }
                else {
                    if(appendToPath1)
                        path1.push(element);
                    else
                        path2.push(element);
                }
            });


            this._polygon.setPath(path1);
            let newPolygon: google.maps.Polygon = new google.maps.Polygon({
                paths: path2.getArray(),
                map: App.getInstance().service.map
            });

            let newRangePolygon: Entity.Map.Shape.RangePolygon = Factory.RangePolygonFactory.createNewRangePolygon(newPolygon);
            newRangePolygon.setDraggable(this.getDraggable());
            App.getInstance().service.rangeManager.registerEditedRangePolygon(this);
            App.getInstance().service.rangeManager.registerEditedRangePolygon(newRangePolygon);
        }

        public join(targetRangePolygon: Entity.Map.Shape.RangePolygon): boolean {
            if(!this.isIntersectingWith(targetRangePolygon))
                return false;

            targetRangePolygon.restore();
            targetRangePolygon.updateOptions();

            let newPath: google.maps.MVCArray = Logic.Util.joinPaths(this, targetRangePolygon);

            if(targetRangePolygon.id) {
                targetRangePolygon.delete();
                this.polygon.setPath(newPath);
            }
            else {
                App.getInstance().service.rangeManager.deleteRangePolygon(targetRangePolygon);
                this.polygon.setPath(newPath);
            }

            App.getInstance().service.rangeManager.registerEditedRangePolygon(this);
            App.getInstance().service.rangeManager.registerEditedRangePolygon(targetRangePolygon);

            return true;
        }

        public clip(targetRangePolygon: Entity.Map.Shape.RangePolygon): boolean {
            if(!this.isOverlappingWith(targetRangePolygon))
                return false;

            let gpcThisPoly: PolyDefault = Logic.Util.pathToGpcPoly(this.getPath());
            let gpcTargetPoly: PolyDefault = Logic.Util.pathToGpcPoly(targetRangePolygon.getPath());

            let gpcResultPoly: PolyDefault = gpcTargetPoly.difference(gpcThisPoly);

            targetRangePolygon.polygon.setPath(Logic.Util.gpcPolyToPath(gpcResultPoly));

            App.getInstance().service.rangeManager.registerEditedRangePolygon(targetRangePolygon);

            return true;
        }

        public delete(): void {
            this._markedForDeletion = true;
            this._polygon.setMap(null);

            App.getInstance().service.rangeManager.registerEditedRangePolygon(this);
        }

        public restore(): void {
            this._zIndex = this._oldZIndex;
            this._color = this._oldColor;
            this._strokeColor = this._oldStrokeColor;
        }

        public updateOptions(deletedOfferIds: number = null): void {
            this._color = Entity.Map.Shape.RangePolygon.defaultColor;

            for(let key in this._rangeOffers) {
                if(!this._rangeOffers[key])
                    continue;

                let rangeOffer = this._rangeOffers[key];

                if(deletedOfferIds !== null && rangeOffer.offer_id == deletedOfferIds) {
                    this._rangeOffers[key] = null;
                    continue;
                }

                if(rangeOffer.primary) {
                    this._color = rangeOffer.offer.color;
                    break;
                }
            }

            this._paths = this._polygon.getPath().getArray();
            this._polygon.setOptions(this.options());
        }

        public createOfferInfoWindow(): Entity.View.InfoWindows.OfferInfoWindow {
            let offerInfoWindow: Entity.View.InfoWindows.OfferInfoWindow = new Entity.View.InfoWindows.OfferInfoWindow(this);
            return offerInfoWindow;
        }

        public onClick(latLng: google.maps.LatLng): void {
            if(this.markedForDeletion || !this.polygon)
                return;

            let offerInfoWindow: Entity.View.InfoWindows.OfferInfoWindow = this.createOfferInfoWindow();
            offerInfoWindow.show();
        }

        protected options(): google.maps.PolygonOptions {
            return {
                paths: this._paths,
                fillColor: this.color,
                strokeColor: this.strokeColor,
                zIndex: this._zIndex,
                draggable: false
            };
        }

        public get id(): number {
            return this._id;
        }

        public set id(id: number) {
            this._id = id;
        }

        public get pathJson(): string {
            return this._pathJson;
        }

        public set pathJson(pathJson: string) {
            this._pathJson = pathJson;
        }

        public get region(): Entity.Map.Type.Region {
            return this._region;
        }

        public get exclusiveVisibility(): boolean {
            return this._exclusiveVisibility;
        }

        public set exclusiveVisibility(visibility: boolean) {
            this._exclusiveVisibility = visibility;
        }

        public get zIndex(): number {
            return this._zIndex;
        }

        public set zIndex(zIndex: number) {
            this._zIndex = zIndex;
        }

        public get color(): string {
            return this._color;
        }

        public set color(color: string) {
            this._color = color;
        }

        public get strokeColor(): string {
            return this._strokeColor;
        }

        public set strokeColor(strokeColor: string) {
            this._strokeColor = strokeColor;
        }

        public get markedForDeletion(): boolean {
            return this._markedForDeletion;
        }

        public get rangeOffers(): Entity.Map.NetworkRange.RangeOffer[] {
            return this._rangeOffers;
        }
    }
}
