/**
 * Created by Mateusz Lipowski on 13.12.2016.
 */

namespace Entity.Map.Marker.VehicleGPS {
    export class Vehicle extends Entity.Map.Marker.Marker {
        protected path: VehicleGPS.GPS[];
        protected fragmentedPath: google.maps.LatLng[];
        protected trace: google.maps.Polyline[];

        constructor(id: number, data: Object, map, path: VehicleGPS.GPS[]) {
            if(path[0])
                super(path[0].x, path[0].y, map);
            else
                super(0, 0, map);

            this.defaultColor = 'DD1C1C';
            this.path = path;
            this.chst = 'd_map_pin_icon';
            this.chld = 'car-dealer|' + this.color;
            this.initializeMarker();
            this.update();
            this._marker.setMap(this._map);
        }

        public update(): void {
            if(this._marker) {
                this._marker.setIcon(this.getIconPath());
            }

            if(!this.trace) {
                this.trace = [];
                this.fragmentTracePath(100);

                let prevFragmentLatLng: google.maps.LatLng = this.fragmentedPath[0];
                let opacity: number = 0;
                for(let i = 1; i < this.fragmentedPath.length; i++) {
                    let latLng: google.maps.LatLng = this.fragmentedPath[i];
                    if(!latLng)
                        continue;

                    opacity += (i * 0.05) / this.fragmentedPath.length;
                    if(opacity > 1)
                        opacity = 1;

                    this.trace.push(new google.maps.Polyline({
                        map: this._map,
                        path: [prevFragmentLatLng, latLng],
                        strokeOpacity: opacity,
                        strokeColor: '#' + this.color,
                    }));

                    prevFragmentLatLng = latLng;
                }
            }

            this.determineVisibility();
        }

        public getDetails(additionalData: Object): string {
            return '';
        }

        protected fragmentTracePath(count: number = 50): void {
            this.fragmentedPath = [];
            let length: number = google.maps.geometry.spherical.computeLength(this.tracePath);

            let prevLatLng: google.maps.LatLng = this.tracePath[0];
            for(let i = 1; i < this.tracePath.length; i++) {
                let latLng: google.maps.LatLng = this.tracePath[i];
                if(!latLng)
                    continue;

                let segmentLength: number = google.maps.geometry.spherical.computeLength([prevLatLng, latLng]);
                let segmentFragmentCount: number = Math.ceil((segmentLength / length) * count);

                for(let j = 1; j <= segmentFragmentCount; j++) {
                    let segmentFragmentOffsetFraction: number = j / segmentFragmentCount;
                    let segmentFragmentLatLng: google.maps.LatLng = google.maps.geometry.spherical.interpolate(prevLatLng, latLng, segmentFragmentOffsetFraction);

                    this.fragmentedPath.push(segmentFragmentLatLng);
                }

                prevLatLng = latLng;
            }
        }

        protected get tracePath(): google.maps.LatLng[] {
            let result: google.maps.LatLng[] = [];

            for(let gps of this.path) {
                result.unshift(gps.latLng);
            }

            return result;
        }
    }
}
