import URIRegionParser = Logic.URIRegionParser;
/**
 * Created by Mateusz Lipowski on 13.12.2016.
 */

class GuestApp extends App {
    protected static instance: GuestApp = null;

    protected regionId: string;

    public static getInstance(): GuestApp {
        if(GuestApp.instance == null)
            throw new Error('Cannot get the instance of GuestApp: instance hasn\'t been created yet');

        return GuestApp.instance;
    }

    constructor () {
        super();
        GuestApp.instance = this;
    }

    public run(mapCanvas) {
        try {
            this.initBasicModules(mapCanvas);
            this.service.initMap(mapCanvas);
            this.service.initMenus();
            this.service.initInfoWindow();
            this.service.initWindowContainer();
            this.service.initOverlay();
            this.service.initDrawingManager();
            this.service.initDirectionsRenderer();
            this.service.initRangeManager();

            this.parseURI();
            this.fillMap();
        }
        catch(e) {
            console.dir(e);
            console.error('Caught exception in App constructor: ' + e.message)
        }
    }

    protected parseURI(): void {
        let centerParser: URICenterParser = new URICenterParser();
        let regionParser: URIRegionParser = new URIRegionParser();
        let lineParser: URILineParser = new URILineParser();
        
        this._entityContainer.lines.push(lineParser.createLine());

        centerParser.centerMap(this.service.map);
        this.regionId = regionParser.getRegionId();
    }

    protected fillMap() {
        let requestSender: Logic.RequestSender = new Logic.RequestSender();
        requestSender.sendRequest('GetAllGuest', null, (action: string, fields: Object, response: any) => {
            if (requestSender.isError()) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                alert.show();
                this.postFill();
                return;
            }

            try {
                let regions = JSON.parse(response.regions);
                let offers = JSON.parse(response.offers);
                let range = JSON.parse(response.range);

                this.populateRegions(regions);
                this.populateOffers(offers);
                this.populateRange(range);

                this.initRange();
            }
            catch (e) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, e.message);
                alert.show();
            }
        });
    }

    protected initRange(): void {
        if(!this.regionId) {
            this._entityContainer.regions.map((region) => {
                this.service.rangeManager.setRegionVisible(region.id, true);
                return;
            });

            return;
        }

        let region: Entity.Map.Type.Region = this._entityContainer.findRegion(parseInt(this.regionId));
        if(!region) {
            let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Nieznany region');
            alert.show();
            return;
        }

        this.service.rangeManager.setRegionVisible(region.id, true);
    }
}
