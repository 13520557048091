/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class MapDrawPolygon extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.app.service.drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
        }
    }
}
