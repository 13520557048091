
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LinkEditPath extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let link: Entity.Map.Shape.Link.Link = this.event.target as Entity.Map.Shape.Link.Link;
            link.startPathEdit();
        }
    }
}
