/**
 * Created by Mateusz Lipowski on 24.10.2016.
 */

namespace Entity.View.Window {
    export class PhotosWindow extends Entity.View.Window.Window {
        protected node: Entity.Map.Marker.Node.Node = null;
        protected photoRequestResponse: Entity.Photo.PhotoRequestResponse = null;
        protected categories: Entity.Photo.Category[] = [];
        protected photos: Entity.Photo.Photo[] = [];
        protected currentImages: HTMLImageElement[] = [];
        protected clientMode: boolean = false;
        protected initialized: boolean = false;
        protected updateCallbackId: number = null;

        protected selectRow: HTMLDivElement = null;
        protected photosDiv: HTMLDivElement = null;
        protected categoriesSelect: HTMLSelectElement = null;
        protected addPhotoButton: HTMLInputElement = null;

        protected proxyListenerOnCategoryChanged: (e: UIEvent) => any;
        protected proxyListenerOnAddPhotoButtonClick: (e: UIEvent) => any;

        public constructor(windowContent: HTMLDivElement, windowContainerIndex: number = null) {
            super(windowContent, windowContainerIndex);
            this.photoRequestResponse = new Entity.Photo.PhotoRequestResponse();

            this.updateCallbackId = App.getInstance().service.callbackContainer.pushCallback('photos', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.showForNode(this.node);
                return true;
            });


            this.proxyListenerOnCategoryChanged = (e: UIEvent): any => {
                this.onCategoryChanged();
            };

            this.proxyListenerOnAddPhotoButtonClick = (e: UIEvent): any => {
                this.onAddPhotoButtonClick();
            };
        }

        public showForNode(node: Entity.Map.Marker.Node.Node, clientMode: boolean = false): void {
            this.node = node;
            this.clientMode = clientMode;

            let loader = App.getInstance().service.loader;
            loader.show('Pobieranie zdjęć');

            let photoRequestResponseRequestFunctionName = 'requestPhotosForNode';
            let id: number = node.id;
            if(this.clientMode) {
                photoRequestResponseRequestFunctionName = 'requestPhotosForClient';
                id = (node as Entity.Map.Marker.Node.ClientNode).client_id;
            }

            this.photoRequestResponse[photoRequestResponseRequestFunctionName](id, (): void => {
                if(this.photoRequestResponse.error) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Nie można pobrać zdjęć: ' + this.photoRequestResponse.message);
                    alert.show();
                    loader.dismiss();
                    return;
                }

                this.categories = this.photoRequestResponse.categories;
                this.photos = this.photoRequestResponse.photos;

                this.show('photos');
                this.initView();
                this.populateCategories();
                this.populatePhotos();
                loader.dismiss();
            });
        }

        public dismiss(): void {
            let callbackContainer: Control.CallbackContainer.CallbackContainer = App.getInstance().service.callbackContainer;
            callbackContainer.deleteCallbackById('photos', 'update', this.updateCallbackId);

            if(this.categoriesSelect)
                this.categoriesSelect.removeEventListener('change', this.proxyListenerOnCategoryChanged);

            if(this.addPhotoButton)
                this.addPhotoButton.removeEventListener('click', this.proxyListenerOnAddPhotoButtonClick);

            super.dismiss();
        }

        protected onImageClick(image: HTMLImageElement): void {
            let photoswipe: HTMLImageElement = document.getElementById('pswp') as HTMLImageElement;
            let index: number = 0;
            let items = [];
            let options = {
                index: 0
            };

            for(let currentImage of this.currentImages) {
                if(currentImage.src == image.src)
                    options.index = index;

                items.push({
                    src: currentImage.src,
                    w: currentImage.naturalWidth,
                    h: currentImage.naturalHeight,
                    title: currentImage.alt + "<br>" + currentImage.title
                });
                index++;
            }

            let gallery = new PhotoSwipe(photoswipe, PhotoSwipeUI_Default, items, options);
            gallery.init();
        }

        protected onAddPhotoButtonClick(): void {
            let addPhotoWindow: Entity.View.Window.PhotoAddWindow = App.getInstance().service.windowContainer.createWindow(Control.WindowClass.PHOTO_ADD_WINDOW) as Entity.View.Window.PhotoAddWindow;
            addPhotoWindow.showForNode(this.node);
            addPhotoWindow.setSelectedCategory(this.selectedCategory);
        }

        protected onCategoryChanged(): void {
            this.populatePhotos();
        }

        protected initView(): void {

            if(this.initialized)
                return;

            this.setWide();

            this.selectRow = this.form.getElementsByClassName('select-row')[0] as HTMLDivElement;
            this.photosDiv = this.form.getElementsByClassName('photos')[0] as HTMLDivElement;
            this.categoriesSelect = this.form['kategoria_id'] as HTMLSelectElement;
            this.addPhotoButton = this.form['addPhoto'] as HTMLInputElement;

            this.categoriesSelect.addEventListener('change', this.proxyListenerOnCategoryChanged);
            this.addPhotoButton.addEventListener('click', this.proxyListenerOnAddPhotoButtonClick);

            if(this.clientMode)
                this.addPhotoButton.style.display = 'none';
            else
                this.addPhotoButton.style.display = 'block';

            this.initialized = true;
        }
        protected populateCategories(): void {
            if(!this.categoriesSelect)
                throw new Error('PhotosWindow.populateCategories: categoriesSelect is undefined');


            if(this.clientMode) {
                this.selectRow.style.display = 'none';
                return;
            }
            else {
                this.selectRow.style.display = 'block';
            }

            let selectedValue = this.categoriesSelect.value;

            this.removeAllChildren(this.categoriesSelect);

            for(let category of this.categories) {
                let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                option.value = category.id.toString();
                option.textContent = category.name;
                if(option.value == selectedValue)
                    option.selected = true;
                if(category.node_id)
                    option.className += 'exclusive';

                this.categoriesSelect.appendChild(option);
            }
        }

        protected populatePhotos(): void {
            if(!this.photosDiv)
                throw new Error('PhotosWindow.populatePhotos: photosDiv is undefined');

            this.removeAllChildren(this.photosDiv);
            this.currentImages = [];

            if(this.photos.length <= 0) {
                let boldElement: HTMLSpanElement = document.createElement('b') as HTMLSpanElement;
                boldElement.textContent = 'Brak zdjęć';
                boldElement.className += 'no-photos';

                this.photosDiv.appendChild(boldElement);
                this.selectRow.style.display = 'none';
            }
            else {
                if(!this.clientMode) {
                    this.selectRow.style.display = 'block';
                }

                for (let photo of this.photos) {
                    if (photo.category_id != this.selectedCategory && !this.clientMode)
                        continue;

                    let imageContainer: HTMLDivElement = document.createElement('div') as HTMLDivElement;
                    let image: HTMLImageElement = document.createElement('img') as HTMLImageElement;

                    imageContainer.className += 'image-container';

                    image.src = photo.src;
                    image.alt = photo.name;
                    image.title = photo.description;

                    image.addEventListener('click', (e: UIEvent): any => {
                        this.onImageClick(image);
                    });

                    this.currentImages.push(image);
                    imageContainer.appendChild(image);
                    this.photosDiv.appendChild(imageContainer);

                    image.addEventListener('contextmenu', (e: MouseEvent): any => {
                        e.preventDefault();
                        e.stopPropagation();
                        App.getInstance().service.contextMenu.show(image, new Action.UserActionEvent(photo, {}, new google.maps.Point(e.pageX, e.pageY)));
                        return false;
                    });
                }
            }
        }

        protected get selectedCategory(): number {
            return parseInt(this.categoriesSelect.value);
        }
    }
}
