/**
 * Created by Mateusz Lipowski on 18.10.2016.
 */

namespace Entity.View.InfoWindows {
    export class InfoWindow extends Entity.View.ViewBase {
        protected infoWindow: google.maps.InfoWindow;
        protected _isOpen: boolean = false;
        protected _context: any = null;

        public constructor() {
            super();
            this.infoWindow = new google.maps.InfoWindow({});
            this.addListeneres();
        }

        public setPosition(position: google.maps.LatLng) {
            this.infoWindow.setPosition(position);

            this.hideSubnodes();
        }
        public setContent(content: string|Node) {
            this.infoWindow.setContent(content);
        }
        public open(map: google.maps.Map, context: any = null) {
            if(App.getInstance().service.contextMenu.shown)
                return;

            this.infoWindow.open(map);
            this._context = context;

            if(this._context && (this._context instanceof Entity.Map.Marker.Marker || this._context instanceof google.maps.Marker)) {
                this.infoWindow.setOptions({
                    pixelOffset: new google.maps.Size(0, -40)
                });
            }
            else {
                this.infoWindow.setOptions({
                    pixelOffset: new google.maps.Size(0, 0)
                });
            }

            this._isOpen = true;
        }
        get isOpen(): boolean {
            return this._isOpen;
        }
        get context(): any {
            return this._context;
        }

        protected hideSubnodes(): void {
            if(this._context && this._context instanceof Entity.Map.Marker.Node.Node) {
                let node: Entity.Map.Marker.Node.Node = this._context as Entity.Map.Marker.Node.Node;
                if(node.parent)
                    node.parent.hideSubnodes();
                node.hideSubnodes();
            }
        }

        protected addListeneres() {
            let onInfoWindowDissmissed = (params: any): void => {
                this.hideSubnodes();

                this._context = null;
                this._isOpen = false;
            };
            this.infoWindow.addListener('closeclick', onInfoWindowDissmissed);
        }
    }
}
