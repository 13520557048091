
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuOffer extends Action.UserActionBase {
        protected window: Entity.View.Window.Window = null;
        protected table: HTMLTableElement = null;

        protected onOfferUpdateCallbackId: number;
        protected proxyListenerEditOfferButtonClick: (e: MouseEvent) => void;
        protected proxyListenerAddOfferButtonClick: (e: MouseEvent) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);

            this.proxyListenerEditOfferButtonClick = (e: MouseEvent): void => {
                this.onEditOfferButtonClick(e.target as HTMLInputElement);
            };
            this.proxyListenerAddOfferButtonClick = (e: MouseEvent): void => {
                this.onAddOfferButtonClick(e.target as HTMLInputElement);
            };
        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            this.initView();
            this.populateOffers();

            this.onOfferUpdateCallbackId = this.app.service.callbackContainer.pushCallback('offer', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.populateOffers();
                return true;
            });
        }

        protected onEditOfferButtonClick(button: HTMLInputElement): void {
            let offerId: number = parseInt(button.dataset['offer_id']);
            let offer: Entity.Map.NetworkRange.Offer = this.app.entityContainer.findOffer(offerId);

            if(!offer) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Wybrana oferta nie istnieje');
                alert.show();
                return;
            }

            Factory.UserActionFactory.invokeAction('OfferEdit', 'Other', new Action.UserActionEvent(offer, {}));
        }

        protected onAddOfferButtonClick(button: HTMLInputElement): void {
            Factory.UserActionFactory.invokeAction('OfferAdd', 'Other', new Action.UserActionEvent(this, {}));
        }

        protected initView(): void {
            this.window = this.app.service.windowContainer.createWindow(Control.WindowClass.WINDOW);
            this.window.show('offers');
            this.window.setOnDismiss((): void => {
                this.dispose();
            });

            this.table = this.window.getForm().getElementsByClassName('offers-table')[0] as HTMLTableElement;
            if(!this.table)
                throw new Error('Brak tabeli ofert w oknie ofert');

            let newOfferButton: HTMLInputElement = this.window.getForm().getElementsByClassName('new-offer')[0] as HTMLInputElement;
            newOfferButton.addEventListener('click', this.proxyListenerAddOfferButtonClick);
        }

        protected dispose(): void {
            this.app.service.callbackContainer.deleteCallbackById('offer', 'update', this.onOfferUpdateCallbackId);
        }

        protected populateOffers(): void {
            if(!this.table) {
                throw new Error('UserAction.MainMenu.MenuOffer.populateOffers: Table element is undefined');
            }

            this.removeAllChildren(this.table);
            this.createTableHeader();

            for(let offer of this.app.entityContainer.offers) {
                if(!offer)
                    continue;

                this.appendOfferRow(offer);
            }
        }

        protected appendOfferRow(offer: Entity.Map.NetworkRange.Offer): void {
            let id = this.createSpan(offer.id.toString(), null, 'offer-element id');
            let name = this.createSpan(offer.name, null, 'offer-element name');
            let color = this.createColor(offer.color, null, 'offer-element color');
            let button = document.createElement('input') as HTMLInputElement;

            button.type = 'button';
            button.value = 'Edytuj';
            button.setAttribute('data-offer_id', offer.id.toString());
            button.addEventListener('click', this.proxyListenerEditOfferButtonClick);

            let row: HTMLTableRowElement = null;
            row = this.createTableRow('td', id, name, color, button);

            this.table.appendChild(row);
        }

        protected createTableHeader(): void {
            let row: HTMLTableRowElement = this.createTableRow('th', 'ID', 'Nazwa', 'Kolor', '');
            this.table.appendChild(row);
        }
    }
}
