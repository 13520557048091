
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class RangePolygonDeleteVertex extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let rangePolygon: Entity.Map.Shape.RangePolygon = this.event.target as Entity.Map.Shape.RangePolygon;
            if(rangePolygon.getEditable() && this.event.params['vertex'] != undefined) {
                rangePolygon.deleteVertex(this.event.params['vertex']);
                App.getInstance().service.rangeManager.registerEditedRangePolygon(rangePolygon);
            }
            else {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Brak wierzchołka do usunięcia');
                alert.show();
            }
        }
    }
}
