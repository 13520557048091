/**
 * Created by Mateusz Lipowski on 24.01.2017.
 */

namespace Logic {
    export class Cookie {
        public static createCookie(name: string, value: string | number | boolean, days: number = null) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + value + expires + "; path=/";
        }

        public static  readCookie(name: string) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        }

        public static  eraseCookie(name: string) {
            Logic.Cookie.createCookie(name, "", -1);
        }
    }
}
