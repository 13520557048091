/**
 * Created by Mateusz Lipowski on 03.10.2016.
 */

namespace Action {
    export abstract class UserActionBase extends Entity.View.ViewBase  {
        public abstract execute(): void;

        public constructor(protected _event: Action.UserActionEvent) {
            super();
        }

        protected getNodeInfo(node: Entity.Map.Marker.Node.Node): string {
            if(node instanceof DeviceNode)
                return (node as DeviceNode).getShortenedDeviceName() + ': ' + (node as DeviceNode).getShortAddress();
            else
                return node.id.toString();
        }

        public get event(): Action.UserActionEvent {
            return this._event;
        }

        public get app(): App {
            return App.getInstance();
        }
    }

    export abstract class SingularUserActionBase extends Action.UserActionBase {
        protected static inExecution: Action.SingularUserActionBase = null;

        public constructor(protected _event: Action.UserActionEvent) {
            super(_event);
        }
    }
}