/**
 * Created by Mateusz Partyka on 22.09.2016.
 */
namespace Entity.Map.Shape.Link {
    export class Link extends Entity.Map.MapEntity {
        protected _polyline: google.maps.Polyline;
        protected _category: Entity.Map.Type.Category = null;
        protected _linkType: Entity.Map.Type.LinkType = null;
        protected _node1: Entity.Map.Marker.Node.Node = null;
        protected _node2: Entity.Map.Marker.Node.Node = null;
        protected _path: google.maps.MVCArray = new google.maps.MVCArray;
        protected _midPath: google.maps.MVCArray = new google.maps.MVCArray;

        protected _id: number;
        protected _kategoria_id: number;
        protected _polaczenie_typ: number;
        protected _parametry: string;
        protected _backup: string;
        protected _sciezka: string;
        protected _map: google.maps.Map = null;

        constructor(linkData: Object, node1: Entity.Map.Marker.Node.Node, node2: Entity.Map.Marker.Node.Node, map: google.maps.Map = null) {
            super();

            this._map = map;

            for (let key in linkData) {
                if (linkData.hasOwnProperty(key))
                    this[`_${key}`] = linkData[key];
            }

            this.parseMidPath();
            this._node1 = node1;
            this._node2 = node2;
            this._path.push(this.node1Position);
            this._midPath.forEach((elem: any, i: number): void => {
                this._path.push(elem);
            });
            this._path.push(this.node2Position);

            this._polyline = new google.maps.Polyline();

            node1.addPolyline(this);
            node2.addPolyline(this);

            this._polyline.addListener('click', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.LINK, new Control.CallbackContainer.Event('click', this, params));
            });

            this._polyline.addListener('rightclick', (params) => {
                //App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.LINK, new Control.CallbackContainer.Event('rightclick', this, params));
            });

            this.update();

            this.body = this._polyline;
            this.setupInputEvent(InputEvent.RightClick, (params => {
                App.getInstance().service.contextMenu.show(this, new Action.UserActionEvent(this, params, null));
            }));
        }

        public update() {
            if (this._kategoria_id > 0) {
                if (!this._category || this._category.id != this._kategoria_id)
                    this._category = App.getInstance().entityContainer.findCategory(this._kategoria_id);
            }
            else
                this._category = null;

            if (this._polaczenie_typ > 0) {
                if (!this._linkType || this._linkType.id != this._polaczenie_typ)
                    this._linkType = App.getInstance().entityContainer.findLinkType(this._polaczenie_typ);
            }
            else {
                this._linkType = null;
            }


            this._path.setAt(0, this.node1Position);
            this._path.setAt(this._path.getLength() - 1, this.node2Position);

            this._polyline.setOptions(this.getPolylineOptions());
            this.determineVisibility();
        }

        public remove() {
            this._polyline.setMap(null);
        }

        public swapNodes() {
            let temp = this.node1;
            this._node1 = this._node2;
            this._node2 = temp;
        }

        public isBackup(): boolean {
            return this.backup == '1';
        }

        public startPathEdit(): void {
            this._polyline.setEditable(true);
        }

        public endPathEdit(): void {
            this._polyline.setEditable(false);

            this._midPath = new google.maps.MVCArray();

            for (let i = 1; i < this._path.getLength() - 1; i++)
                this._midPath.push(this._path.getAt(i));
        }

        public deleteVertex(vertexIndex: number): void {
            if (vertexIndex == 0 || vertexIndex == this._path.getLength() - 1)
                return;

            this._path.removeAt(vertexIndex);
        }

        public isEditEnabled(): boolean {
            return (this._polyline.getEditable() == true); //google zwraca undefined zamiast false, co za gówno
        }

        public determineVisibility(): void {
            if (this.node1Visible && this.node2Visible)
                this._polyline.setVisible(true);
            else
                this._polyline.setVisible(false);
        }

        public getDetails(): string {
            let node1: Entity.Map.Marker.Node.Node = this.node1 as Entity.Map.Marker.Node.Node;
            let node2: Entity.Map.Marker.Node.Node = this.node2 as Entity.Map.Marker.Node.Node;
            let marker1: google.maps.Marker = node1.marker;
            let marker2: google.maps.Marker = node2.marker;

            let distance = this.length.toFixed(0);
            let content: string = '';
            let node1Desc: string = this.node1.id.toString();
            let node2Desc: string = this.node2.id.toString();

            if (node1 instanceof DeviceNode)
                node1Desc = (<DeviceNode>node1).getShortenedDeviceName();
            else if (node1 instanceof Entity.Map.Marker.Node.ClientNode) {
                node1Desc = 'Klient ' + (<Entity.Map.Marker.Node.ClientNode>node1).id;
                node1Desc += '<br/>' + (<Entity.Map.Marker.Node.ClientNode>node1).address;
            }

            if (node2 instanceof DeviceNode)
                node2Desc = (<DeviceNode>node2).getShortenedDeviceName();
            else if (node2 instanceof Entity.Map.Marker.Node.ClientNode) {
                node2Desc = 'Klient ' + (<Entity.Map.Marker.Node.ClientNode>node2).id;
                node2Desc += '<br/>' + (<Entity.Map.Marker.Node.ClientNode>node2).address;
            }

            if (this.node1.isSubnode())
                node1Desc += ' (podrzędny)';
            if (this.node2.isSubnode())
                node2Desc += ' (podrzędny)';

            if (this.node1.device)
                node1Desc += '<br/>Urządzenie: ' + this.node1.device.detailedName;
            if (this.node2.device)
                node2Desc += '<br/>Urządzenie: ' + this.node2.device.detailedName;

            node1Desc = 'Węzeł 1: <div class="linkNodeInfoRow">' + node1Desc + '</div>';
            node2Desc = 'Węzeł 2: <div class="linkNodeInfoRow">' + node2Desc + '</div>';

            content += node1Desc;
            content += '<br />' + node2Desc;

            if (this.id) {
                content += '<br />ID: ' + this.id;
                content += '<br />Kategoria: ' + (this.category ? (this.kategoria_id > 0 ? this.category.name : 'Auto (' + this.category.name + ')') : 'Brak');
                content += '<br />Typ: ' + (this.linkType ? this.linkType.name : 'Brak');
                content += '<br />Parametry: ' + (this.parametry ? this.parametry : 'Brak');
            }
            content += '<br />Odległość: ' + distance + ' m';
            return content;
        }

        protected getPolylineOptions(): google.maps.PolylineOptions {
            return <google.maps.PolylineOptions>{
                path: this._path,
                strokeOpacity: (this.isBackup() ? 0 : 1),
                strokeWeight: (this.linkType ? this.linkType.weight : 4),
                strokeColor: '#' + this.determineColor(),
                icons: this.getIconSequence(),
                map: this._map
            };
        }

        protected getIconSequence(): google.maps.IconSequence[] {
            let iconSequence: google.maps.IconSequence[] = [{
                icon: {path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW, strokeOpacity: 1},
                offset: '100%'
            }];

            if (this.isBackup()) {
                iconSequence.push({
                    icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        scale: 3
                    },
                    offset: '0',
                    repeat: '20px'
                });
            }

            return iconSequence;
        }

        protected determineColor(): string {
            if (this.linkType && this.linkType.color)
                return this.linkType.color;

            if (this.category)
                return this.category.color;

            return 'FF0000';
        }

        protected parseMidPath(): void {
            if (!this._sciezka)
                return;

            let midPath: Object = JSON.parse(this._sciezka);
            for (let key in midPath) {
                if (!midPath.hasOwnProperty(key) || midPath[key].lat == undefined || midPath[key].lng == undefined)
                    continue;

                this._midPath.push(new google.maps.LatLng(midPath[key].lat, midPath[key].lng));
            }
        }

        get id(): number {
            return this._id;
        }

        set id(id: number) {
            this._id = id;
        }

        get kategoria_id(): number {
            return this._kategoria_id;
        }

        set kategoria_id(kategoria_id: number) {
            this._kategoria_id = kategoria_id;
        }

        get polaczenie_typ(): number {
            return this._polaczenie_typ;
        }

        set polaczenie_typ(polaczenie_typ: number) {
            this._polaczenie_typ = polaczenie_typ;
        }

        get parametry(): string {
            return this._parametry;
        }

        set parametry(parametry: string) {
            this._parametry = parametry;
        }

        get backup(): string {
            return this._backup;
        }

        set backup(backup: string) {
            this._backup = backup;
        }

        get node1(): Entity.Map.Marker.Node.Node {
            return this._node1;
        }

        get node2(): Entity.Map.Marker.Node.Node {
            return this._node2;
        }

        get category(): Entity.Map.Type.Category {
            if (this._category)
                return this._category;

            return this.guessedCategory;
        }

        get linkType(): Entity.Map.Type.LinkType {
            return this._linkType;
        }

        get guessedCategory(): Entity.Map.Type.Category {
            let type1: Entity.Map.Type.Type = this._node1.type;
            let type2: Entity.Map.Type.Type = this._node2.type;
            let nodeCategory: Entity.Map.Type.Category = null;

            if (type1 && type1.category)
                nodeCategory = type1.category;

            if (type2 && type2.category && (!nodeCategory || type2.category.priority > nodeCategory.priority))
                nodeCategory = type2.category;

            if (nodeCategory)
                return nodeCategory;

            return null;
        }

        get path(): google.maps.MVCArray {
            return this._path;
        }

        get midPath(): google.maps.MVCArray {
            return this._midPath;
        }

        get node1Position(): google.maps.LatLng {
            if (this.node1.isSubnode() && !this.node1.parent.subnodesShown)
                return this.node1.parent.getPositionObject();

            return this.node1.getPositionObject();
        }

        get node2Position(): google.maps.LatLng {
            if (this.node2.isSubnode() && !this.node2.parent.subnodesShown)
                return this.node2.parent.getPositionObject();

            return this.node2.getPositionObject();
        }

        get node1Visible(): boolean {
            if (this.node1.isSubnode())
                return this.node1.parent.visible;

            return this.node1.visible;
        }

        get node2Visible(): boolean {
            if (this.node2.isSubnode())
                return this.node2.parent.visible;

            return this.node2.visible;
        }

        get length(): number {
            let result: number = 0;

            let path: google.maps.MVCArray = this._path;
            for (let i = 1; i < path.getLength(); i++) {
                let prevLatLng: google.maps.LatLng = path.getAt(i - 1);
                let currLatLng: google.maps.LatLng = path.getAt(i);

                result += google.maps.geometry.spherical.computeDistanceBetween(prevLatLng, currLatLng);
            }

            return result;
        }

        get polyline(): google.maps.Polyline {
            return this._polyline;
        }
    }
}