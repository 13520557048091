/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class TypeEdit extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected type: Entity.Map.Type.Type = null;
        protected form: HTMLFormElement = null;
        protected deleteButton: HTMLInputElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.type = this.event.target as Entity.Map.Type.Type;
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('type_edit');
            this.form = this.window.getForm();
            this.deleteButton = this.form['delete'] as HTMLInputElement;

            this.deleteButton.addEventListener('click', (e: MouseEvent): void => {
                if(confirm('Na pewno?')) {
                    this.sendDeleteRequest();
                }
            });

            this.initView();
            this.initFormSender();
        }

        protected initView(): void {
            this.form['id']['value'] = this.type.id;
            this.form['name']['value'] = this.type.name;
            this.form['symbol'].value = this.type.symbol;
            this.form['color'].value = '#' + this.type.color;

            for(let category of this.app.entityContainer.categories) {
                if(!category)
                    continue;

                let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                option.value = category.id.toString();
                option.textContent = category.name;
                if(category.id == this.type.category_id)
                    option.selected = true;

                this.form['category_id'].appendChild(option);
            }
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    this.type.name = this.form['name']['value'];
                    this.type.symbol = this.form['symbol'].value;
                    this.type.color = this.form['color'].value.substring(1);
                    this.type.category_id = parseInt(this.form['category_id'].value);
                    this.app.service.callbackContainer.invokeCallbacks('type', new Control.CallbackContainer.Event('update', this.type, {}));

                    for(let deviceNode of this.app.entityContainer.findNodesByTypeId(this.type.id)) {
                        if(!deviceNode)
                            continue;

                        deviceNode.update();
                    }

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }

        protected sendDeleteRequest(): void {
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('DeleteType', {id: this.type.id}, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(requestSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.getMessage());
                else {
                    this.app.entityContainer.deleteType(this.type.id);
                    this.app.service.callbackContainer.invokeCallbacks('type', new Control.CallbackContainer.Event('delete', this.type, {}));
                    this.app.service.callbackContainer.invokeCallbacks('type', new Control.CallbackContainer.Event('update', this.type, {}));
                    this.type = null;

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
