/**
 * Created by Mateusz Lipowski on 31.10.2016.
 */

namespace Entity.Map.Shape.Link {
    export class ClientLink extends Entity.Map.Shape.Link.Link {
        public static settingClientLinkColor = 'clientLinkColor';

        protected clientNode: Entity.Map.Marker.Node.ClientNode;
        protected deviceNode: Entity.Map.Marker.Node.DeviceNode;

        protected helperPolyline: google.maps.Polyline = null;

        public constructor(node1: Entity.Map.Marker.Node.Node, node2: Entity.Map.Marker.Node.Node, map: google.maps.Map = null) {
            super({}, node1, node2, map);

            if(node1 instanceof Entity.Map.Marker.Node.ClientNode) {
                this.clientNode = node1 as Entity.Map.Marker.Node.ClientNode;
                this.deviceNode = node2 as Entity.Map.Marker.Node.DeviceNode;
            }
            else {
                this.clientNode = node2 as Entity.Map.Marker.Node.ClientNode;
                this.deviceNode = node1 as Entity.Map.Marker.Node.DeviceNode;
            }

            // this.createHelperPolyline();

            App.getInstance().entityContainer.polylines.push(this.polyline);
        }

        protected createHelperPolyline(): void {
            let options: google.maps.PolylineOptions = this.getPolylineOptions();
            // options.strokeColor = '#' + this.deviceNode.color;
            options.strokeOpacity = 1;
            options.strokeWeight = 1;
            this.helperPolyline = new google.maps.Polyline(options);
            App.getInstance().entityContainer.polylines.push(this.helperPolyline);
        }

        protected getPolylineOptions(): google.maps.PolylineOptions {
            let linkColor: string = '#000000';
            let settingLinkColor: string | number = App.getInstance().service.settings.get(ClientLink.settingClientLinkColor);
            if(settingLinkColor)
                linkColor = settingLinkColor.toString();

            if (this.node1 instanceof Entity.Map.Marker.Node.ClientNode)
                linkColor = '#' + this.node2.color;
            else
                linkColor = '#' + this.node1.color;

            return <google.maps.PolylineOptions>{
                path: this._path,
                strokeOpacity: 1,
                strokeWeight: 1,
                strokeColor: linkColor,
                //icons: this.getIconSequence(),
                map: this._map
            };
        }

        protected getIconSequence(): google.maps.IconSequence[] {
            return [{
                icon: {
                    path: 'M 0,-1 0,1',
                    strokeOpacity: 1,
                    scale: 1
                },
                offset: '0',
                repeat: '1px'
            }];
        }

        public determineVisibility(): void {
            if(this.clientNode && this.clientNode.visible) {
                this._polyline.setVisible(true);
                if(this.helperPolyline)
                    this.helperPolyline.setVisible(true);
            }
            else {
                this._polyline.setVisible(false);
                if(this.helperPolyline)
                    this.helperPolyline.setVisible(false);
            }
        }
    }
}