
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuTicketsSearch extends Action.MenuTicketsBase {
        protected window: Entity.View.Window.Window = null;
        protected form: HTMLFormElement = null;
        protected categorySelect: HTMLSelectElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);

        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('tickets_search');

            this.initView();
            this.populateCategories();
            this.initFormSender();
        }

        protected initView(): void {
            this.form = this.window.getForm();
            this.categorySelect = this.form['category'];
        }

        protected populateCategories(): void {
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('GetTickets', { categories: true }, (action: string, fields: Object, response: any) => {
                if (requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                    return;
                }

                try {
                    let categories: string[] = JSON.parse(response);
                    this.removeAllChildren(this.categorySelect);

                    let defaultOption: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                    defaultOption.value = '0';
                    defaultOption.textContent = ' - dowolna - ';
                    this.categorySelect.appendChild(defaultOption);

                    for(let i = 0; i < categories.length; i++) {
                        if(!categories[i])
                            continue;

                        let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                        option.value = categories[i];
                        option.textContent = categories[i];
                        this.categorySelect.appendChild(option);
                    }
                }
                catch (e) {
                    let exceptionAlert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, e.message);
                    exceptionAlert.show();
                }
            });
        }

        protected initFormSender(): void {
            let formSender: Logic.FormSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                if (formSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, formSender.getMessage());
                    alert.show();
                    this.app.service.loader.dismiss();
                    return;
                }

                let count: number = this.populateTickets(response);
                let alert: Entity.View.Alert = null;
                this.app.service.loader.dismiss();

                if(count)
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, 'Ilość znalezionych zgłoszeń: ' + count);
                else
                    alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Nie znaleziono zgłoszeń');

                alert.show();
            });

            formSender.setOnSubmit(() => {
                this.app.service.loader.show('Wyszukiwanie ticketów');
            });
        }
    }
}
