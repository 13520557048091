/**
 * Created by Mateusz Lipowski on 24.10.2016.
 */

namespace Entity.View.Window {
    export class PhotoAddWindow extends Entity.View.Window.Window {
        protected node: Entity.Map.Marker.Node.Node = null;
        protected photoRequestResponse: Entity.Photo.PhotoRequestResponse = null;
        protected categories: Entity.Photo.Category[] = [];
        protected formData: FormData = null;
        protected formSender: Logic.FormSender = null;
        protected categoryToSelect: number = null;

        protected newCategoryNameDiv: HTMLDivElement = null;
        protected categoriesSelect: HTMLSelectElement = null;
        protected photosInput: HTMLInputElement = null;

        public constructor(windowContent: HTMLDivElement, windowContainerIndex: number = null) {
            super(windowContent, windowContainerIndex);
            this.photoRequestResponse = new Entity.Photo.PhotoRequestResponse();
        }

        public showForNode(node: Entity.Map.Marker.Node.Node): void {
            this.node = node;
            let loader = App.getInstance().service.loader;
            loader.show('Pobieranie zdjęć');

            this.photoRequestResponse.requestCategoriesForNode(node.id, (): void => {
                if(this.photoRequestResponse.error) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Nie można pobrać zdjęć: ' + this.photoRequestResponse.message);
                    alert.show();
                    loader.dismiss();
                    return;
                }

                this.categories = this.photoRequestResponse.categories;

                this.show('photo_add');
                this.initView();
                this.initFormSender();
                loader.dismiss();
            });
        }

        public setSelectedCategory(categoryId: number): void {
            this.categoryToSelect = categoryId;

            if(!this.categoriesSelect)
                return;

            let options: HTMLCollection = this.categoriesSelect.options;
            for(let i = 0; i < options.length; i++) {
                let option: HTMLOptionElement = options.item(i) as HTMLOptionElement;
                if(option.value == categoryId.toString())
                    option.selected = true;
            }
        }

        protected onCategoryChanged(): void {
            if(this.selectedCategory === 0) {
                this.newCategoryNameDiv.style.display = 'block';
            }
            else {
                this.newCategoryNameDiv.style.display = 'none';
            }
        }

        protected onPhotosInputChanged(): void {
            this.formData = new FormData(this.form);
            this.formSender.setFormData(this.formData);
        }

        protected initView(): void {
            this.categoriesSelect = this.form['kategoria_id'] as HTMLSelectElement;
            this.newCategoryNameDiv = this.form.getElementsByClassName('new-category-name')[0] as HTMLDivElement;
            this.photosInput = this.form['zdjecia[]'] as HTMLInputElement;

            this.form['wezel_id'].value = this.node.id;

            this.categoriesSelect.addEventListener('change', (e: UIEvent): any => {
               this.onCategoryChanged();
            });
            this.photosInput.addEventListener('change', (e: UIEvent): any => {
               this.onPhotosInputChanged();
            });
            this.populateCategories();
            this.onCategoryChanged();
        }

        protected initFormSender(): void {
            let loader = App.getInstance().service.loader;

            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                if(this.formSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                    alert.show();
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    alert.show();

                    if(response.uploadErrors) {
                        for(let i = 0; i < response.uploadErrors.length; i++) {
                            let uploadErrorAlert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, response.uploadErrors[i]);
                            uploadErrorAlert.show();
                        }
                    }

                    this.dismiss();
                    App.getInstance().service.callbackContainer.invokeCallbacks('photos', new Control.CallbackContainer.Event('update', this, null));
                }

                loader.dismiss();
            });

            this.formSender.setOnSubmit((): void => {
                loader.show('Wysyłanie zdjęć');
            });
        }

        protected populateCategories(): void {
            if(!this.categoriesSelect)
                throw new Error('PhotosWindow.populateCategories: categoriesSelect is undefined');

            this.removeAllChildren(this.categoriesSelect);

            for(let category of this.categories) {
                let option: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
                option.value = category.id.toString();
                option.textContent = category.name;
                if(category.node_id)
                    option.className += 'exclusive';

                if(this.categoryToSelect != null && this.categoryToSelect.toString() == option.value)
                    option.selected = true;

                this.categoriesSelect.appendChild(option);
            }

            let optionNewCategory: HTMLOptionElement = document.createElement('option') as HTMLOptionElement;
            optionNewCategory.value = '0';
            optionNewCategory.textContent = ' - nowa - ';
            this.categoriesSelect.appendChild(optionNewCategory);
        }

        protected get selectedCategory(): number {
            return parseInt(this.categoriesSelect.value);
        }
    }
}
