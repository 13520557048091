
/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class RegionAdd extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected form: HTMLFormElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('region_add');
            this.form = this.window.getForm();
            this.initFormSender();
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    let rowData: Object = {
                        id: response.id,
                        nazwa: this.form['name']['value'],
                        prefix: this.form['prefix']['value']
                    };
                    let newRegion: Entity.Map.Type.Region = new Entity.Map.Type.Region(rowData);
                    this.app.entityContainer.regions[newRegion.id] = newRegion;
                    this.app.service.callbackContainer.invokeCallbacks('region', new Control.CallbackContainer.Event('update', newRegion, {}));
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
