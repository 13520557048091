namespace Control.CallbackContainer {
    export class Event {
        public constructor(protected _type: string, protected _target:any, protected _params: any) {

        }

        get type(): string {
            return this._type;
        }

        get target(): any {
            return this._target;
        }

        get params(): any {
            return this._params;
        }
    }
}