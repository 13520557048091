/**
 * Created by Mateusz Lipowski on 21.10.2016.
 */

namespace Logic {
    export class Util {
        public static shortenGPSComponent(gpsComponent: number): number {
            return Math.round(gpsComponent * 1000000) / 1000000;
        }

        public static shortenGPS(gps: string): string {
            return gps.split(',').map((value, index) => {
                return Util.shortenGPSComponent(parseFloat(value.trim()));
            }).join(',');
        }

        public static gpsToLatLng(gps: string): google.maps.LatLng {
            let xy = gps.split(',');
            if(!xy || xy.length != 2)
                return null;

            try {
                return new google.maps.LatLng(parseFloat(xy[0]), parseFloat(xy[1]));
            }
            catch (e) {
                return null;
            }
        }

        public static generatePathArray(vertexesJson: string): google.maps.LatLng[] {
            try {
                let result: google.maps.LatLng[] = [];
                let vertexesObject: Object = JSON.parse(vertexesJson);
                for(let key in vertexesObject) {
                    if(!vertexesObject.hasOwnProperty(key))
                        continue;

                    let latLng: google.maps.LatLng = new google.maps.LatLng(vertexesObject[key]['x'], vertexesObject[key]['y']);
                    result.push(latLng);
                }

                return result;
            }
            catch (e) {
                return null;
            }
        }

        public static generatePathJson(path: google.maps.MVCArray): string {
            let simpleArray: google.maps.LatLng[] = path.getArray();
            let resultArray: Object[] = [];

            for(let latLng of simpleArray) {
                resultArray.push({
                    x: Logic.Util.shortenGPSComponent(latLng.lat()),
                    y: Logic.Util.shortenGPSComponent(latLng.lng())
                });
            }

            return JSON.stringify(resultArray);
        }

        public static joinPaths(polygon1: Entity.Map.Shape.Polygon, polygon2: Entity.Map.Shape.Polygon): google.maps.MVCArray {
            let gpcPolygon1: PolyDefault = Logic.Util.pathToGpcPoly(polygon1.getPath());
            let gpcPolygon2: PolyDefault = Logic.Util.pathToGpcPoly(polygon2.getPath());

            let gpcUnionPolygon = gpcPolygon1.union(gpcPolygon2);

            return Logic.Util.gpcPolyToPath(gpcUnionPolygon);
        }

        public static pathToGpcPoly(path: google.maps.MVCArray): PolyDefault {
            let gpcPoly: PolyDefault = new PolyDefault();
            path.forEach((latLng: google.maps.LatLng, index: number): void => {
                let point: google.maps.Point = App.getInstance().service.overlay.getProjection().fromLatLngToContainerPixel(latLng);
                gpcPoly.addPoint(new Point(point.x, point.y));
            });

            return gpcPoly;
        }

        public static gpcPolyToPath(gpcPoly: PolyDefault): google.maps.MVCArray {
            let path: google.maps.MVCArray = new google.maps.MVCArray();

            let vertexCount: number = gpcPoly.getNumPoints();
            for(let i = 0; i < vertexCount; i++) {
                let latLng: google.maps.LatLng = App.getInstance().service.overlay.getProjection().fromContainerPixelToLatLng(new google.maps.Point(gpcPoly.getX(i), gpcPoly.getY(i)));
                path.push(latLng);
            }

            return path;
        }

        public static compareVertices(vertex1: google.maps.LatLng, vertex2: google.maps.LatLng): boolean {
            return (vertex1.lat() == vertex2.lat() && vertex1.lng() == vertex2.lng());
        }

        public static rgbToHex(r, g, b): string {
            let rgb: string = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
            let dotIndex: number = rgb.indexOf('.');
            if(dotIndex >= 0) {
                return rgb.substr(0, dotIndex);
            }
            return rgb;
        }

        public static HSVtoRGB(h, s, v): number[] {
            var r, g, b;

            var i = Math.floor(h * 6);
            var f = h * 6 - i;
            var p = v * (1 - s);
            var q = v * (1 - f * s);
            var t = v * (1 - (1 - f) * s);

            switch (i % 6) {
                case 0: r = v, g = t, b = p; break;
                case 1: r = q, g = v, b = p; break;
                case 2: r = p, g = v, b = t; break;
                case 3: r = p, g = q, b = v; break;
                case 4: r = t, g = p, b = v; break;
                case 5: r = v, g = p, b = q; break;
            }

            return [ r * 255, g * 255, b * 255 ];
        }

        public static generateIpAddressLink(ipAddress: string): string {
            if(!ipAddress)
                return '';

            let fixedIpAddress: string = '';
            let ipOctets: string[] = ipAddress.split('.');

            ipOctets.map((ipOctet: string, index: number, array): void => {
                let matches: string[] = ipOctet.match(/0*([0-9]+)/);
                if(matches.length == 2) {
                    ipOctets[index] = matches[1];
                }
            });

            fixedIpAddress = ipOctets.join('.');


            return '<a href="' + config.panelUrl + '/?co=testy&ip=' + fixedIpAddress + '" target="_blank">' + fixedIpAddress + '</a>';
        }

        public static formatDecimal(decimal: number, unit: string, precision: number = 2): string {
            return (Math.round(decimal * precision) / precision).toString().replace('.', ',') + unit;
        }
    }
}