/**
 * Created by Mateusz Lipowski on 27.10.2016.
 */

namespace Entity.Photo {
    export class PhotoRequestResponse extends Entity.BaseEntity {
        protected requestSender: Logic.RequestSender = null;
        protected getPhotosActionName: string = 'GetPhotos';
        protected getCategoriesActionName: string = 'GetPhotosCategories';
        protected _response: any = null;
        protected _message: string = null;
        protected _error: boolean = false;

        protected _photos: Entity.Photo.Photo[] = [];
        protected _categories: Entity.Photo.Category[] = [];

        public constructor() {
            super();
            this.requestSender = new RequestSender();
        }

        public requestPhotosForNode(nodeId: number, callback: () => void): void {
            this.requestSender.sendRequest(this.getPhotosActionName, {wezel_id: nodeId}, (action: string, fields: Object, response: any): void => {
                if(this.requestSender.isError()) {
                    this._response = null;
                    this._error = true;
                }
                else {
                    this._response = response;
                    this.parseResponse();
                    this._error = false;
                }

                this._message = this.requestSender.getMessage();
                callback();
            });
        }

        public requestPhotosForClient(clientId: number, callback: () => void): void {
            this.requestSender.sendRequest(this.getPhotosActionName, {klient_id: clientId}, (action: string, fields: Object, response: any): void => {
                if(this.requestSender.isError()) {
                    this._response = null;
                    this._error = true;
                }
                else {
                    this._response = response;
                    this.parseResponse();
                    this._error = false;
                }

                this._message = this.requestSender.getMessage();
                callback();
            });
        }

        public requestCategoriesForNode(nodeId: number, callback: () => void): void {
            this.requestSender.sendRequest(this.getCategoriesActionName, {wezel_id: nodeId}, (action: string, fields: Object, response: any): void => {
                if(this.requestSender.isError()) {
                    this._response = null;
                    this._error = true;
                }
                else {
                    this._response = response;
                    this.parseResponse();
                    this._error = false;
                }

                this._message = this.requestSender.getMessage();
                callback();
            });
        }

        protected parseResponse(): void {
            let categoriesJson = this.response.categories;
            let photosJson = this.response.photos;
            let categoriesObject: Object = null;
            let photosObject: Object = null;

            if(categoriesJson == undefined)
                throw new Error('PhotoRequestResponse.parseResponse: Can\'t parse categories JSON, property "categories" in response is undefined');
            if(photosJson == undefined)
                throw new Error('PhotoRequestResponse.parseResponse: Can\'t parse photos JSON, property "photos" in response is undefined');

            this.clearParsedResponseArrays();

            categoriesObject = JSON.parse(categoriesJson);
            photosObject = JSON.parse(photosJson);

            for(let key in categoriesObject) {
                if(categoriesObject.hasOwnProperty(key) && categoriesObject[key])
                    this._categories.push(new Entity.Photo.Category(categoriesObject[key]));
            }

            for(let key in photosObject) {
                if(photosObject.hasOwnProperty(key) && photosObject[key])
                    this._photos.push(new Entity.Photo.Photo(photosObject[key]));
            }
        }

        protected clearParsedResponseArrays(): void {
            this._photos = [];
            this._categories = [];
        }

        public get response(): any {
            return this._response;
        }

        public get message(): string {
            return this._message;
        }

        public get error(): boolean {
            return this._error;
        }

        public get categories(): Entity.Photo.Category[] {
            return this._categories;
        }

        public get photos(): Entity.Photo.Photo[] {
            return this._photos;
        }
    }
}
