
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class MapNewTicket extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let newTicketLatLng: google.maps.LatLng = this.event.params['latLng'] as google.maps.LatLng;

            let newTicketGeocode: Logic.Geocode = new Logic.Geocode();
            newTicketGeocode.getAddress(newTicketLatLng.lat(), newTicketLatLng.lng(), (address: string): void => {
                let lat: number = Logic.Util.shortenGPSComponent(newTicketLatLng.lat());
                let lng: number = Logic.Util.shortenGPSComponent(newTicketLatLng.lng());
                location.href = 'https://panel.wave.com.pl/?co=dziennik&nowy_temat=1&adres=' + encodeURIComponent(address) + '&gps=' + encodeURIComponent(lat + ',' + lng);
            });
        }
    }
}
