
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class LinkEditPathDeleteVertex extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let link: Entity.Map.Shape.Link.Link = this.event.target as Entity.Map.Shape.Link.Link;
            if(link.isEditEnabled() && this.event.params['vertex'] != undefined) {
                link.deleteVertex(this.event.params['vertex']);
            }
            else {
                let alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Brak wierzchołka do usunięcia');
                alert.show();
            }
        }
    }
}
