/**
 * Created by Mateusz Lipowski on 19.09.2016.
 */
namespace Entity.View {
    export class StateBox extends Entity.View.ViewBase {
        protected stateBoxText: HTMLScriptElement;

        public constructor(protected stateBox: HTMLScriptElement) {
            super();
            if(this.stateBox.tagName.toLowerCase() != 'div')
                throw new Error('Niewłaściwy element state-box');

            this.stateBoxText = this.stateBox.getElementsByClassName('state-box-text')[0] as HTMLScriptElement;
        };

        public show(text, appendElement: HTMLElement = null):void {
            let textSpan: HTMLSpanElement = document.createElement('span') as HTMLSpanElement;
            textSpan.textContent = text;

            this.removeAllChildren(this.stateBoxText);
            this.stateBoxText.appendChild(textSpan);

            if(appendElement)
                this.stateBoxText.appendChild(appendElement);

            this.stateBox.style.display = 'block';
        };

        public dismiss():void {
            this.stateBox.style.display = 'none';
        };

        public getCloseButton():HTMLScriptElement {
            return this.stateBox.getElementsByClassName('close')[0] as HTMLScriptElement;
        };
    }
}