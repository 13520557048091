/**
 * Created by Mateusz Lipowski on 24.10.2016.
 */

namespace Entity.Map.Type {
    export class LinkType extends Entity.Map.MapEntity {
        protected _id: number = 0;
        protected _name: string = '';
        protected _color: string = null;
        protected _weight: number = 0;

        public constructor(rowData: Object) {
            super();
            this._id = parseInt(rowData['id']);
            this._name = rowData['nazwa'];
            this._color = rowData['kolor'];
            this._weight = rowData['grubosc'];

            this.update(rowData);
        }

        public update(rowData = null) {
            if(!this._id)
                throw new Error('Błędne ID dla typu polaczenia (podane id: ' + rowData['id'] + ')');

            if(!this._name || this._name.length <= 0)
                throw new Error('Błędna nazwa dla typu polaczenia ' + this._id + ' (podana nazwa: ' + rowData['nazwa'] + ')');

            if(!this._color || this._color.length <= 0)
                this._color = null;
        }

        get id(): number {
            return this._id;
        }

        get name(): string {
            return this._name;
        }

        set name(name: string) {
            this._name = name;
        }

        get color(): string {
            return this._color;
        }

        set color(color) {
            this._color = color;
        }

        get weight(): number {
            return this._weight;
        }

        set weight(weight: number) {
            this._weight = weight;
        }
    }
}