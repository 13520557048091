/**
 * Created by Mateusz Lipowski on 24.11.2016.
 */

namespace Entity.Map.NetworkRange {
    interface PathAndVerticesPair {
        path: google.maps.MVCArray,
        vertices: number[],
        rangePolygon: Entity.Map.Shape.RangePolygon
    }

    export class RangeVertexPositioner extends Entity.Map.MapEntity {
        protected vertexDrag: boolean = false;
        protected draggedVertexRangePolygon: Entity.Map.Shape.RangePolygon = null;
        protected mutualVertices: PathAndVerticesPair[] = [];

        protected lastMousePoint: google.maps.Point = null;

        public constructor(protected polygons: Entity.Map.Shape.RangePolygon[], protected polygonsDraggable: boolean = false) {
            super();
        }

        /**
         * Metoda wywoływana gdy rozpoczynamy przenoszenie wierzchołka
         * @param target
         * @param vertexIndex
         * @return void
         */
        public onVertexDragStart(target: Entity.Map.Shape.RangePolygon, vertexIndex: number): void {
            let targetVertex: google.maps.LatLng = target.getPath().getAt(vertexIndex);

            this.mutualVertices = [];
            for(let rangePolygon of this.polygons) {
                if(!rangePolygon || !rangePolygon.polygon.getVisible() || rangePolygon.markedForDeletion)
                    continue;

                let path: google.maps.MVCArray = rangePolygon.getPath();
                let vertices: number[] = [];
                path.forEach((element: google.maps.LatLng, index: number): void => {
                    if(element.lat() == targetVertex.lat() && element.lng() == targetVertex.lng()) {
                        vertices.push(index);
                    }
                });

                if(vertices.length >= 1) {
                    this.mutualVertices.push({
                        path: path,
                        vertices: vertices,
                        rangePolygon: rangePolygon
                    });
                }
            }

            if(this.mutualVertices.length > 1) {
                target.setDraggable(false);
                target.setEditable(false);
            }

            this.draggedVertexRangePolygon = target;
            this.vertexDrag = true;
        }

        /**
         * Metoda wywoływana gdy kończymy przenoszenie wierzchołka
         * @param target
         * @param vertexIndex
         * @return void
         */
        public onVertexDragEnd(target: Entity.Map.Shape.RangePolygon, vertexIndex: number): void {
            if(this.mutualVertices.length > 1) {
                this.draggedVertexRangePolygon.setDraggable(this.polygonsDraggable);
                this.draggedVertexRangePolygon.setEditable(true);
            }

            for(let pathVerticesPair of this.mutualVertices) {
                if (!pathVerticesPair)
                    continue;

                App.getInstance().service.rangeManager.registerEditedRangePolygon(pathVerticesPair.rangePolygon);
            }
            App.getInstance().service.rangeManager.registerEditedRangePolygon(this.draggedVertexRangePolygon);

            this.mutualVertices = [];
            this.vertexDrag = false;

            this.draggedVertexRangePolygon = null;
        }

        /**
         * Metoda wywoływana po każdym ruchu kursora, w trakcie przenoszenia wierzchołka. Aktualizuje położenie wszystkich wierzchołków które były w tej samej pozycji
         * @param x number
         * @param y number
         * @return void
         */
        public onUpdateVertexPosition(x: number, y: number): void {
            if(!this.vertexDrag || this.mutualVertices.length <= 1)
                return;

            this.lastMousePoint = new google.maps.Point(x, y);
            let mouseLatLng: google.maps.LatLng = App.getInstance().service.overlay.getProjection().fromContainerPixelToLatLng(this.lastMousePoint);

            for(let pathVerticesPair of this.mutualVertices) {
                if(!pathVerticesPair)
                    continue;

                for(let vertexIndex of pathVerticesPair.vertices) {
                    if(vertexIndex === null || vertexIndex === undefined)
                        continue;

                    pathVerticesPair.path.setAt(vertexIndex, new google.maps.LatLng(mouseLatLng.lat(), mouseLatLng.lng()));
                }
            }
        }
    }
}
