/**
 * Created by Mateusz Lipowski on 28.11.2016.
 */


namespace Action.Other {
    export class LinkTypeAdd extends Action.UserActionBase {
        protected formSender: Logic.FormSender = null;
        protected window: Entity.View.Window.Window = null;
        protected form: HTMLFormElement = null;

        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            this.window = App.getInstance().service.windowContainer.createWindow();
            this.window.show('link_type_add');
            this.form = this.window.getForm();

            this.form['weight'].value = '1';

            this.initFormSender();
        }

        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    let rowData: Object = {
                        id: response.id,
                        nazwa: this.form['name']['value'],
                        kolor: this.form['color'].value.substring(1),
                        grubosc: this.form['weight'].value
                    };
                    let newLinkType: Entity.Map.Type.LinkType = new Entity.Map.Type.LinkType(rowData);
                    this.app.entityContainer.linkTypes[newLinkType.id] = newLinkType;
                    this.app.service.callbackContainer.invokeCallbacks('linkType', new Control.CallbackContainer.Event('update', newLinkType, {}));
                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
