/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class PhotoDelete extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let photo: Entity.Photo.Photo = this.event.target as Entity.Photo.Photo;
            if (confirm(`Na pewno chcesz usunąć zdjęcie ${photo.name}?`)) {
                let requestSender: Logic.RequestSender = new Logic.RequestSender();
                requestSender.sendRequest('DeletePhoto', {id: photo.id}, (action: string, fields: Object, response: any): void => {
                    if (requestSender.isError()) {
                        let alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.getMessage());
                        alert.show();
                    } else {
                        let alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, requestSender.getMessage());
                        alert.show();
                        this.app.service.callbackContainer.invokeCallbacks('photos', new Control.CallbackContainer.Event('update', photo, null));
                    }
                });
            }
        }
    }
}
