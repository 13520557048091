/**
 * Created by Mateusz Lipowski on 03.11.2016.
 */

namespace Entity.Device {
    export class Device extends Entity.BaseEntity {
        protected _id: number;
        protected _client_id: number;
        protected _client_service_id: number;
        protected _node_id: number;
        protected _name: string;
        protected _ip: string;
        protected _mac: string;
        protected _filter: boolean;
        protected _mikrotik: boolean;
        protected _ubiquiti: boolean;
        protected _mikrotik_password_date: string;
        protected _locked: boolean;
        protected _ssid: string;
        protected _freq: string;
        protected _comment: string;


        public constructor(rowData: string[]) {
            super();
            this._id = parseInt(rowData['id']);
            this._client_id = parseInt(rowData['klient_id']);
            this._client_service_id = parseInt(rowData['klient_usluga_id']);
            this._node_id = parseInt(rowData['wezel_id']);
            this._name = rowData['nazwa'];
            this._ip = rowData['ip'];
            this._mac = rowData['mac'];
            this._filter = rowData['filtracja'] == '1';
            this._mikrotik = rowData['mikrotik'] == '1';
            this._ubiquiti = rowData['ubiquiti'] == '1';
            this._mikrotik_password_date = rowData['mikrotik_data_hasla'];
            this._locked = rowData['zablokowany'] == '1';
            this._comment = rowData['komentarz'];
            this._ssid = rowData['ssid'];
            this._freq = rowData['freq'];
        }

        get id(): number {
            return this._id;
        }
        get client_id(): number {
            return this._client_id;
        }
        get client_service_id(): number {
            return this._client_service_id;
        }
        get node_id(): number {
            return this._node_id;
        }
        get name(): string {
            return this._name;
        }
        get ip(): string {
            return this._ip;
        }
        get mac(): string {
            return this._mac;
        }
        get filter(): boolean {
            return this._filter;
        }
        get mikrotik(): boolean {
            return this._mikrotik;
        }
        get ubiquiti(): boolean {
            return this._ubiquiti;
        }
        get mikrotik_password_date(): string {
            return this._mikrotik_password_date;
        }
        get locked(): boolean {
            return this._locked;
        }
        get ssid(): string {
            return this._ssid;
        }
        get freq(): string {
            return this._freq;
        }
        get comment(): string {
            return this._comment;
        }
        get detailedName(): string {
            return (this.name ? this.name : this.id.toString()) + (this.ip ? (': ' + this.ip) : '');
        }
    }
}