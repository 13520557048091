/**
 * Created by Mateusz Lipowski on 12.10.2016.
 */

namespace Entity.View {
    import LatLng = google.maps.LatLng;

    export class LocationSearchInput extends Entity.View.ViewBase {
        protected onSubmit: (e: Event) => void;
        protected onFocus: (e: Event) => void;
        protected onFocusOut: (e: Event) => void;
        protected onClickShowLocation: (e: Event) => void;
        private mainMenu: HTMLElement;

        public constructor(protected form: HTMLFormElement, protected resultList: HTMLUListElement) {
            super();
            if (form == null || form.tagName.toLowerCase() != 'form')
                throw new Error('LocationSearchInput: podany button "form" to nie jest elementem <form>');

            if (resultList == null || resultList.tagName.toLowerCase() != 'ul')
                throw new Error('LocationSearchInput: podany button "resultsList" to nie jest elementem <ul>');

            this.mainMenu = document.getElementById('main-menu-container');

            this.initLambdas();
            this.initEvents();
        }

        public searchAddress(address: string) {
            this.form.location.focus();
            this.form.location.value = decodeURIComponent(address).replace(/\+/g, ' ');
            this.onSubmit(new Event('Search'));
        }

        protected initLambdas() {
            this.onSubmit = (e: Event): void => {
                e.preventDefault();

                let locationInput = this.form['location'];
                if (locationInput == undefined || locationInput == null)
                    throw new Error('LocationSearchInput: nie można znaleźć inputa "location" w formularzu');

                if (locationInput.value.length == 0)
                    return;

                let geocode: Logic.Geocode = new Logic.Geocode();

                this.clearResultList();

                // 54°29'31.15"N 18°24'49.77"E
                if (locationInput.value.includes('°')) {
                    let parts = locationInput.value.split(/[^\d\w\.]+/);
                    let lat: any, lng: any;

                    if (parts[0] > parts[4]) {
                        lat = this.convertCoordinates(parts[0], parts[1], parts[2], parts[3]);
                        lng = this.convertCoordinates(parts[4], parts[5], parts[6], parts[7]);
                    } else {
                        lat = this.convertCoordinates(parts[4], parts[5], parts[6], parts[7]);
                        lng = this.convertCoordinates(parts[0], parts[1], parts[2], parts[3]);
                    }

                    geocode.getAddress(lat, lng, (foundAddress: string): void => {
                        this.setPosition(lat, lng, foundAddress);
                    });

                    // STANDARD COORDINATES / TARGEO
                } else if (locationInput.value.match(geocode.coordinatesRegExpSimple)) {
                    let coordinates: number[] = locationInput.value.match(geocode.coordinatesRegExpSimple);

                    let lat = coordinates[1];
                    let lng = coordinates[2];

                    // reversed latitude and longitude range matches for Poland
                    if ((lat > 10 && lat < 30) && (lng > 50 && lng < 60))
                        geocode.getAddress(lng, lat, (foundAddress: string): void => {
                            this.setPosition(lng, lat, foundAddress);
                        });
                    else
                        geocode.getAddress(lat, lng, (foundAddress: string): void => {
                            this.setPosition(lat, lng, foundAddress);
                        });

                } else {
                    geocode.getLatLng(locationInput.value, (results: Logic.GeocodeResult[]): void => {

                        history.replaceState({}, 'Mapy Wave - ' + locationInput.value, '/?s=' + encodeURIComponent(locationInput.value));

                        let result: Logic.GeocodeResult = null;
                        let li: HTMLLIElement = null;
                        let label: HTMLLabelElement = null;

                        if (results.length === 0) {
                            li = document.createElement('li') as HTMLLIElement;
                            label = document.createElement('label') as HTMLLabelElement;
                            label.textContent = 'Brak wyników wyszukiwania';
                            li.appendChild(label);
                            this.resultList.appendChild(li);

                            return;
                        }

                        for (let i = 0; i < results.length; i++) {
                            result = results[i];

                            let latLng: google.maps.LatLng = result.latLng;
                            let foundAddress = result.foundAddress;
                            li = document.createElement('li') as HTMLLIElement;
                            label = document.createElement('label') as HTMLLabelElement;

                            label.textContent = foundAddress;

                            label.addEventListener('click', (e: MouseEvent): void => {
                                this.setPosition(latLng.lat(), latLng.lng(), foundAddress);
                            });

                            li.appendChild(label);
                            this.resultList.appendChild(li);

                            if (i === 0)
                                this.setPosition(latLng.lat(), latLng.lng(), foundAddress, false);
                        }
                    });
                }
            };

            this.onClickShowLocation = (e: MouseEvent): void => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition((position): void => {
                        this.setPosition(position.coords.latitude, position.coords.longitude, 'Aktualna pozycja');
                    }, (error): void => {
                        let message: string = error.message;
                        if (error.code == 1) {
                            message = 'Odmowa zgody na ustalenie lokalizacji!';
                        }

                        let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, message);
                        alert.show();
                    });
                }
                else {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.WARNING, 'Twoje urządzenie nie wspiera geolokacji');
                    alert.show();
                }
            };

            this.onFocus = (e: Event): void => {
                this.mainMenu.classList.add('is-active');
            };

            this.onFocusOut = (e: Event): void => {
                this.mainMenu.classList.remove('is-active');
            }
        }

        private convertCoordinates(degrees: string, minutes: string, seconds: string, direction: string): String {
            let value = Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

            if (direction == "S" || direction == "W")
                value *= -1;

            return value.toFixed(6);
        }

        protected setPosition(lat: number, lng: number, foundAddress: string, writeHistory: boolean = true): void {
            let latLng: google.maps.LatLng = new google.maps.LatLng(lat, lng);
            let app = App.getInstance();
            let map: google.maps.Map = app.service.map;

            app.service.mapContainer.onClick(latLng, '', false);
            map.setZoom(15);
            map.setCenter(latLng);
        }

        protected initEvents() {
            this.form.addEventListener('submit', this.onSubmit);

            if (this.form['location'] != undefined) {
                this.form['location'].addEventListener('focus', this.onFocus);
                this.form['location'].addEventListener('focusout', this.onFocusOut);
            }

            if (this.form['show-location'] != undefined) {
                this.form['show-location'].addEventListener('click', this.onClickShowLocation);
            }
        }

        protected clearResultList() {
            while (this.resultList.firstChild) {
                this.resultList.removeChild(this.resultList.firstChild);
            }
        }
    }
}
