
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuEditType extends Action.UserActionBase {
        protected window: Entity.View.Window.Window = null;
        protected table: HTMLTableElement = null;

        protected onTypeUpdateCallbackId: number;
        protected proxyListenerEditTypeButtonClick: (e: MouseEvent) => void;
        protected proxyListenerAddTypeButtonClick: (e: MouseEvent) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);

            this.proxyListenerEditTypeButtonClick = (e: MouseEvent): void => {
                this.onEditTypeButtonClick(e.target as HTMLInputElement);
            };
            this.proxyListenerAddTypeButtonClick = (e: MouseEvent): void => {
                this.onAddTypeButtonClick(e.target as HTMLInputElement);
            };
        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            this.initView();
            this.populateTypes();

            this.onTypeUpdateCallbackId = this.app.service.callbackContainer.pushCallback('type', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.populateTypes();
                return true;
            });
        }

        protected onEditTypeButtonClick(button: HTMLInputElement): void {
            let typeId: number = parseInt(button.dataset['type_id']);
            let type: Entity.Map.Type.Type = this.app.entityContainer.findType(typeId);

            if(!type) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Wybrany typ nie istnieje');
                alert.show();
                return;
            }

            Factory.UserActionFactory.invokeAction('TypeEdit', 'Other', new Action.UserActionEvent(type, {}));
        }

        protected onAddTypeButtonClick(button: HTMLInputElement): void {
            Factory.UserActionFactory.invokeAction('TypeAdd', 'Other', new Action.UserActionEvent(this, {}));
        }

        protected initView(): void {
            this.window = this.app.service.windowContainer.createWindow(Control.WindowClass.WINDOW);
            this.window.show('types');
            this.window.setWide();
            this.window.setOnDismiss((): void => {
                this.dispose();
            });

            this.table = this.window.getForm().getElementsByClassName('types-table')[0] as HTMLTableElement;
            if(!this.table)
                throw new Error('Brak tabeli typów w oknie typów');

            let newOfferButton: HTMLInputElement = this.window.getForm().getElementsByClassName('new-type')[0] as HTMLInputElement;
            newOfferButton.addEventListener('click', this.proxyListenerAddTypeButtonClick);
        }

        protected dispose(): void {
            this.app.service.callbackContainer.deleteCallbackById('type', 'update', this.onTypeUpdateCallbackId);
        }

        protected populateTypes(): void {
            if(!this.table) {
                throw new Error('UserAction.MainMenu.MenuType.populateTypes: Table element is undefined');
            }

            this.removeAllChildren(this.table);
            this.createTableHeader();

            for(let type of this.app.entityContainer.types) {
                if(!type)
                    continue;

                this.appendTypeRow(type);
            }
        }

        protected appendTypeRow(type: Entity.Map.Type.Type): void {
            let id = this.createSpan(type.id.toString(), null, 'offer-element id');
            let categoryName = this.createSpan(type.category ? type.category.name : ' - brak - ', null, 'offer-element categoryName');
            let name = this.createSpan(type.name, null, 'offer-element name');
            let symbol = this.createSpan(type.symbol, null, 'offer-element symbol');
            let color = this.createColor('#' + type.color, null, 'offer-element color');
            let button = document.createElement('input') as HTMLInputElement;

            button.type = 'button';
            button.value = 'Edytuj';
            button.setAttribute('data-type_id', type.id.toString());
            button.addEventListener('click', this.proxyListenerEditTypeButtonClick);

            let row: HTMLTableRowElement = null;
            row = this.createTableRow('td', id, name, categoryName, symbol, color, button);

            this.table.appendChild(row);
        }

        protected createTableHeader(): void {
            let row: HTMLTableRowElement = this.createTableRow('th', 'ID', 'Nazwa', 'Kategoria', 'Symbol', 'Kolor', '');
            this.table.appendChild(row);
        }
    }
}
