/**
 * Created by Mateusz Partyka on 16.09.2016.
 */

namespace Entity.Map.Marker.Node {
    export abstract class Node extends Entity.Map.Marker.Marker {
        protected _id: number;
        protected _node_id: number = 0;
        protected _device_id: number = 0;
        protected _region_id: number = 0;
        protected _type_id: number = 0;
        protected _latBeforeDrag: number;
        protected _lngBeforeDrag: number;
        protected _polylines:Entity.Map.Shape.Link.Link[] = [];
        protected _parent: Entity.Map.Marker.Node.Node = null;
        protected _subnodes: Entity.Map.Marker.Node.Node[] = [];
        protected _region: Entity.Map.Type.Region = null;
        protected _type: Entity.Map.Type.Type = null;
        protected _subnodesShown: boolean = false;

        protected _device: Entity.Device.Device = null;

        constructor(id: number, lat: number, lng: number, data: Object, map) {
            super(lat, lng, map);

            this._id = parseInt(data['id']);
            this._node_id = parseInt(data['wezel_id']);
            this._device_id = parseInt(data['komputer_id']);
            this._region_id = parseInt(data['region_id']);
            this._type_id = parseInt(data['typ_id']);

            this.initializeMarker();
            this.updateMarker();
            this.setMap(this._map);

            this._marker.addListener('click', (params) => {
                App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.NODE, new Control.CallbackContainer.Event('click', this, params));
            });
        }

        public abstract getDetails(additionalData: Object): string;
        public abstract update(): void;

        public updateMarker(): void {
            if(this._region_id > 0) {
                this._region = App.getInstance().entityContainer.findRegion(this._region_id);
            }

            if(this._type_id > 0)
                this._type = App.getInstance().entityContainer.findType(this._type_id);
            else
                this._type = null;

            if(this._node_id > 0)
                this.chld = `0.6|-45|${this.color}|12|b|${this.symbol}`;
            else
                this.chld = `0.6|0|${this.color}|12|b|${this.symbol}`;

            if(this._device_id > 0) {
                let deviceRequestResponse: Entity.Device.DeviceRequestResponse = new Entity.Device.DeviceRequestResponse();
                deviceRequestResponse.requestDeviceById(this._device_id, (): void => {
                    if(deviceRequestResponse.error)
                        this._device = null;
                    else
                        this._device = deviceRequestResponse.device;
                });
            }
            else {
                this._device = null;
            }

            //aktualizacja markera:
            if(this._marker) {
                this._marker.setIcon(this.getIconPath());
            }

            this.determineVisibility();
            //aktualizacja markera - end


            //aktualizacja połączeń:
            for (let polyline of this._polylines)
                polyline.update();
            //aktualizacja połączeń - end
        }

        public updatePolylinesMarkers(event): void {
            this._lat = event.params.latLng.lat();
            this._lng = event.params.latLng.lng();

            if(this._marker) {
                this._marker.setPosition(event.params.latLng);
                this.updatePolylines();
            }
        }

        public updatePolylines(): void {
            for (let polyline of this._polylines)
                polyline.update();
        }

        public removePolylineByID(polylineId: number): void {
            if(this.parent)
                this.parent.removePolylineByID(polylineId);

            for(let i = 0; i < this.polylines.length; i++) {
                if(this.polylines[i].id == polylineId) {
                    this.polylines.splice(i, 1);
                    return;
                }
            }
        }

        public addPolyline(polyline: Entity.Map.Shape.Link.Link): void {
            if(this.isSubnode())
                this.parent.addPolyline(polyline);

            this._polylines.push(polyline);
        }

        public remove() {
            this._polylines.map(function(polyline) {
                polyline.remove();
                polyline = null;
            });

            if(this.parent) {
                this.parent.removeSubnode(this.id);

                let subnodes: Object = this.parent.getSubnodes();
                for(let key in subnodes) {
                    if(subnodes.hasOwnProperty(key)) {
                        subnodes[key].update();
                    }
                }
            }

            this.marker.setMap(null);
        }

        public showSubnodes(): void {
            for(let key in this._subnodes) {
                if (this._subnodes.hasOwnProperty(key)) {
                    //this._subnodes[key].marker.setMap(this._map);
                    this._subnodes[key].marker.setVisible(true);
                }
            }

            this._subnodesShown = true;

            this.updatePolylines();
        }

        public hideSubnodes(): void {
            for (let key in this._subnodes) {
                if (this._subnodes.hasOwnProperty(key)) {
                    //this._subnodes[key].marker.setMap(null);
                    this._subnodes[key].marker.setVisible(false);
                }
            }

            this._subnodesShown = false;

            this.updatePolylines();
        }

        public showNode(): void {
            this.show();
            for(let polyline of this.polylines) {
                polyline.determineVisibility();
            }
        }

        public hideNode(): void {
            this.hide();
            for(let polyline of this.polylines) {
                polyline.determineVisibility();
            }
        }

        public determineVisibility() {
            if(!this._marker)
                return;

            if(this.isSubnode() && this._parent && this._parent.subnodesShown)
                return;

            if (!this.isSubnode() && (!this._region || this._region.isVisible) && (!this._type || (this._type.isVisible && (!this._type.category || this._type.category.isVisible)))) {
                this.showNode();
            } else {
                this.hideNode();
            }
        }

        public isSubnode(): boolean {
            return false;
        }

        public addSubnode(node: Entity.Map.Marker.Node.Node): void {
            this._subnodes[node.id] = node;
            this.update();
        }

        public removeSubnode(nodeId: number): void {
            delete this._subnodes[nodeId];
            this.update();
        }

        public getSubnode(nodeId: number): Entity.Map.Marker.Node.Node {
            if(this._subnodes[nodeId] == undefined)
                return null;

            return this._subnodes[nodeId];
        }

        public getSubnodePosition(nodeId: number): number {
            let position: number = 0;
            for(let key in this._subnodes) {
                if(this._subnodes.hasOwnProperty(key)) {
                    position++;

                    if(this._subnodes[key].id == nodeId)
                        return position;
                }
            }

            return null;
        }

        public getSubnodes(): Entity.Map.Marker.Node.Node[] {
            return this._subnodes;
        }

        public getSubnodesCount(): number {
            return Object.keys(this._subnodes).length;
        }

        protected onClick(params): void {
            let node: Entity.Map.Marker.Node.Node = this;
            let parent: Entity.Map.Marker.Node.Node = this;
            let deviceRequestResponse: Entity.Device.DeviceRequestResponse = new Entity.Device.DeviceRequestResponse();
            let deviceRequestResponseMethodName: string = 'requestDevicesForNode';
            let deviceRequestResponseMethodParam: number = node.id;
            let infoWindow: Entity.View.InfoWindows.InfoWindow = App.getInstance().service.infoWindow;

            if(node.isSubnode())
                parent = node.parent;

            if(node instanceof Entity.Map.Marker.Node.ClientNode) {
                deviceRequestResponseMethodName = 'requestDevicesForClientService';
                deviceRequestResponseMethodParam = (node as Entity.Map.Marker.Node.ClientNode).client_service_id;
            }

            deviceRequestResponse[deviceRequestResponseMethodName](deviceRequestResponseMethodParam, (): void => {
                let elevation: Logic.Elevation = new Logic.Elevation(node.getPositionObject());
                elevation.requestElevation((elevation: number): void => {
                    if(elevation !== null)
                        infoWindow.setContent(node.getDetails({devices: deviceRequestResponse.devices, elevation: elevation}));
                });

                infoWindow.setContent(node.getDetails({devices: deviceRequestResponse.devices}));
            });

            infoWindow.setContent('Pobieranie danych...');
            infoWindow.setPosition(params.latLng);
            infoWindow.open(this._map, node);
            parent.showSubnodes();
        }

        //<editor-fold desc="Getters&Setters">
        get id(): number {
            return this._id;
        }

        set id(id:number) {
            this._id = id;
        }

        get node_id(): number {
            return this._node_id;
        }

        set node_id(node_id: number) {
            this._node_id = node_id;
        }

        get device_id(): number {
            return this._device_id;
        }

        set device_id(device_id: number) {
            this._device_id = device_id;
        }

        get region_id(): number {
            return this._region_id;
        }

        set region_id(region_id: number) {
            this._region_id = region_id;
        }

        get type_id(): number {
            return this._type_id;
        }

        set type_id(type_id: number) {
            this._type_id = type_id;
        }

        get color(): string {
            if(!this.type)
                return this.defaultColor;

            if(this.type.color)
                return this.type.color;

            if(this.type.category)
                return this.type.category.color;

            return this.defaultColor;
        }

        get symbol(): string {
            if(!this.type)
                return '';

            if(this.type.symbol)
                return this.type.symbol;

            return '';
        }

        get polylines(): Entity.Map.Shape.Link.Link[] {
            return this._polylines;
        }

        set polylines(polylines: Entity.Map.Shape.Link.Link[]) {
            this._polylines = polylines;
        }

        get parent(): Entity.Map.Marker.Node.Node {
            return this._parent;
        }

        get region(): Entity.Map.Type.Region {
            return this._region;
        };

        get type(): Entity.Map.Type.Type {
            return this._type;
        }

        get regionName(): string {
            return this._region ? this._region.name : ' -brak- ';
        }

        get subnodesShown(): boolean {
            return this._subnodesShown;
        }

        get visible(): boolean {
            return this._isVisible;
        }

        get device(): Entity.Device.Device {
            return this._device;
        }
        //</editor-fold>

    }
}
