
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuShowRange extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            collection.regions.map((region) => {
                if (region.id == menuItem.dataset.region) {

                    this.app.service.rangeManager.setRegionVisible(region.id, menuItem.checked);

                    return;
                }
            });
        }
    }
}
