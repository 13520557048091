/**
 * Created by Mateusz Lipowski on 14.12.2016.
 */

namespace Entity.Map.Marker.VehicleGPS {
    export class GPS extends Entity.Map.MapEntity {
        protected _id: number;
        protected _lat: number;
        protected _lng: number;
        protected _date: string;
        protected _device_id: number;

        public constructor(rowData: string[]) {
            super();
            this._id = parseInt(rowData['id']);
            this._lat = parseFloat(rowData['lat']);
            this._lng = parseFloat(rowData['lng']);
            this._date = rowData['date'];
            this._device_id = parseInt(rowData['device_id']);
        }

        public get id(): number {
            return this._id;
        }

        public get date(): string {
            return this._date;
        }

        public get x(): number {
            return Logic.Util.shortenGPSComponent(this._lat);
        }

        public get y(): number {
            return Logic.Util.shortenGPSComponent(this._lng);
        }

        public get gps(): string {
            return this.x + ',' + this.y;
        }

        public get latLng(): google.maps.LatLng {
            return new google.maps.LatLng(this.x, this.y);
        }
    }
}