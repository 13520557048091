
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuTicketsHide extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            collection.deleteTickets();
            this.app.service.callbackContainer.invokeCallbacks('tickets', new Control.CallbackContainer.Event('update', collection.tickets, {}));
        }
    }
}
