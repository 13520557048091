/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class MarkerMove extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let node: Entity.Map.Marker.Node.DeviceNode = this.event.target as Entity.Map.Marker.Node.DeviceNode;
            node.toggleDraggable();
        }
    }
}
