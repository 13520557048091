/**
 * Created by Mateusz Lipowski on 03.10.2016.
 */

namespace Action.ContextMenu {
    export class MarkerEdit extends UserActionBase {
        protected window: Entity.View.Window.Window;
        protected form: HTMLFormElement = null;
        protected formSender: Logic.FormSender = null;
        protected node: Entity.Map.Marker.Node.DeviceNode = null;
        protected stateBox: Entity.View.StateBox = null;
        protected onChooseSupernodeEventId: number = null;
        protected windowType: string = 'node_edit';

        protected onChooseWezelId: (e: Event) => void;
        protected onStateBoxClosed: (e: Event) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);
            this.node = event.target as Entity.Map.Marker.Node.DeviceNode;
            this.window = App.getInstance().service.windowContainer.createWindow();

            /**
             * Akcja po kliknięciu na przycisk wyboru węzła nadrzędnego
             *
             * @param e
             */
            this.onChooseWezelId = (e: Event) => {
                this.stateBox = new Entity.View.StateBox(document.getElementById('state-box') as HTMLScriptElement);
                this.stateBox.show('Wybierz węzeł');

                this.app.entityContainer.toggleClients(false);

                this.window.dismiss(false);
                this.stateBox.getCloseButton().addEventListener('click', this.onStateBoxClosed);

                this.onChooseSupernodeEventId = App.getInstance().service.callbackContainer.pushCallback('node', 'click', (e: Control.CallbackContainer.Event): boolean => {
                    let node: Entity.Map.Marker.Node.Node = e.target as Entity.Map.Marker.Node.Node;
                    let options: HTMLOptionElement[] = this.form['region_id'].getElementsByTagName('option');

                    this.form['wezel_id'].value = node.id; //ustawianie pola "wezel_id" na "id" wybranego wezla

                    for(let key in options) { //ustawianie opcji "region_id" na "region_id" wybranego wezla
                        let option: HTMLOptionElement = options[key];
                        if(option instanceof  HTMLElement && option.tagName.toLowerCase() == 'option') {
                            option.selected = (option.value == node.region_id.toString());
                        }
                    }

                    this.app.entityContainer.toggleClients(true);

                    this.onStateBoxClosed(null);
                    return false;
                })
            };

            /**
             * Akcja po zamknięciu stateboxa
             *
             * @param e
             */
            this.onStateBoxClosed = (e: Event) => {
                if(this.onChooseSupernodeEventId != null) {
                    App.getInstance().service.callbackContainer.deleteCallbackById('node', 'click', this.onChooseSupernodeEventId);
                    this.onChooseSupernodeEventId = null;
                }

                this.app.entityContainer.toggleClients(true);

                this.stateBox.dismiss();
                this.window.show(this.windowType);
                this.stateBox.getCloseButton().removeEventListener('click', this.onStateBoxClosed);
                this.form['choose_wezel_id'].addEventListener('click', this.onChooseWezelId);
                this.initFormSender();
            };
        }

        public execute(): void {
            this.window.show(this.windowType);
            this.initForm();

            this.form['choose_wezel_id'].addEventListener('click', this.onChooseWezelId);

            this.window.setOnDismiss(() => {
                if(this.formSender) {
                    this.formSender.dispose();
                    this.formSender = null;
                }
                this.form['choose_wezel_id'].removeEventListener('click', this.onChooseWezelId);
            });

            //this.populateLinkList();
            this.initFormSender();
        }

        /**
         * Pobiera button formularza, uzupełnia formularz o domyślne dane
         */
        protected initForm() {
            this.form = this.window.getForm() as HTMLFormElement;

            let deviceRequesResponse: Entity.Device.DeviceRequestResponse = new Entity.Device.DeviceRequestResponse();
            let deviceRequesResponseNodeId: number = this.node.id;

            let selectDevice: HTMLSelectElement = this.form['komputer_id'] as HTMLSelectElement;
            let selectType: HTMLSelectElement = this.form['typ_id'] as HTMLSelectElement;
            let selectRegion: HTMLSelectElement = this.form['region_id'] as HTMLSelectElement;

            let types: Entity.Map.Type.Type[] = App.getInstance().entityContainer.types;
            let regions: Entity.Map.Type.Region[] = App.getInstance().entityContainer.regions;


            this.populateSelect(selectType, types, 'id', 'name', this.node.type_id.toString(), '- brak -');
            this.populateSelect(selectRegion, regions, 'id', 'name', this.node.region_id.toString());

            if(this.node.isSubnode())
                deviceRequesResponseNodeId = this.node.parent.id;

            deviceRequesResponse.requestDevicesForNode(deviceRequesResponseNodeId, (): void => {
                this.populateSelect(selectDevice, deviceRequesResponse.devices, 'id', 'detailedName', this.node.device_id.toString(), ' - brak - ');
            });

            this.form['id']['value'] = this.node.id;
            this.form['wezel_id']['value'] = this.node.parent ? this.node.parent.id : '0';
            this.form['nazwa'].value = this.node.name;
            this.form['ip'].value = this.node.ip;
            this.form['adres'].value = this.node.address;
            this.form['vlan'].value = this.node.vlan;
            this.form['komentarz'].value = this.node.comment;
            this.form['gps'].value = this.node.gps;
        }

        /**
         * Wypełnia listę połączeń
         */
        protected populateLinkList() {
            let linksList = this.form.getElementsByClassName('links')[0];
            let links = this.node.polylines;
            let child = null;
            while (child = linksList.firstChild) {
                linksList.removeChild(child);
            }

            for(let i = 0; i < links.length; i++) {
                let link: Entity.Map.Shape.Link.Link = links[i] as Entity.Map.Shape.Link.Link;
                let linkId = link.id;

                let li = document.createElement('li');
                let otherNodeId = link.node1.id == this.node.id ? link.node2.id : link.node1.id;
                let params = link.parametry;
                let isBackup = (link.backup == '1');
                li.textContent = otherNodeId + (params ? (': ' + params) : '') + (isBackup ? ' (backup)' : '');
                linksList.appendChild(li);
            }
        }

        /**
         * Tworzy i inicjuje Logic.FormSender, definuje akcję po wykonaniu formularza
         */
        protected initFormSender() {
            this.formSender = new Logic.FormSender(this.form, (action: string, fields: Object, response: any): void => {
                let alert: Entity.View.Alert = null;
                if(this.formSender.isError())
                    alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, this.formSender.getMessage());
                else {
                    this.node.node_id = this.form['wezel_id'].value;
                    this.node.device_id = this.form['komputer_id'].value;
                    this.node.type_id = this.form['typ_id'].value;
                    this.node.region_id = this.form['region_id'].value;
                    this.node.name = this.form['nazwa'].value;
                    this.node.ip = this.form['ip'].value;
                    this.node.address = this.form['adres'].value;
                    this.node.vlan = this.form['vlan'].value;
                    this.node.comment = this.form['komentarz'].value;
                    this.node.gps = this.form['gps'].value;

                    if(this.node.parent)
                        this.node.parent.removeSubnode(this.node.id);
                    this.node.update();

                    alert = new Entity.View.Alert(Entity.View.AlertType.SUCCESS, this.formSender.getMessage());
                    this.formSender.dispose();
                    this.window.dismiss();
                }
                alert.show();
            });
        }
    }
}
