/**
 * Created by Mateusz Lipowski on 24.03.2017.
 */


namespace Control {
    import MVCObject = google.maps.MVCObject;
    export class InputManager {
        protected _mobile: boolean;
        protected clickIndex: number;
        protected clickEventBlocked: boolean;

        public constructor() {
            this.mobile = false;
            this.clickEventBlocked = false;
        }

        public setupGoogleMapsEvent(mvcObject: MVCObject, eventName: string, callback: (params) => void): void {
            if(this.mobile && eventName == 'rightclick') {
                let clickIndex: number = this.clickIndex;
                let lastTimoutId: number = 0;

                let ondown = (params): void => {


                    if(lastTimoutId) {
                        clearTimeout(lastTimoutId);
                        lastTimoutId = 0;
                    }

                    lastTimoutId = setTimeout(() => {
                        this.clickEventBlocked = true;
                        lastTimoutId = 0;
                        callback(params);

                    }, 600);
                };

                let onchange = (params): void => {
                    if(this.clickEventBlocked)
                    {
                        setTimeout(() => {
                            this.clickEventBlocked = false;
                        }, 500);

                    }

                    if(lastTimoutId) {
                        clearTimeout(lastTimoutId);
                        lastTimoutId = 0;
                    }
                };

                mvcObject.addListener('mousedown', ondown);
                mvcObject.addListener('mouseup', onchange);
                mvcObject.addListener('drag', onchange);
            }
            else {
                mvcObject.addListener(eventName, callback);
            }
        }

        public setupDOMEvent(element: Element | Document, eventName: string, callback: (e: Event) => void) {
            element.addEventListener(eventName, (e: Event) => {
                if(!this.clickEventBlocked)
                    callback(e);
            });
        }

        public set mobile(value: boolean) {
            this._mobile = value;
        }

        public get mobile(): boolean {
            return this._mobile;
        }
    }
}