/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class PolygonEdit extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let polygon: Entity.Map.Shape.Polygon = this.event.target as Entity.Map.Shape.Polygon;
            polygon.toggleEditable();
            this.app.service.drawingManager.setDrawingMode(null);
        }
    }
}
