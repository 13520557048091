/**
 * Created by Mateusz Lipowski on 25.11.2016.
 */

namespace Entity.Map.NetworkRange {
    export class RangeOffer extends Entity.Map.MapEntity {
        protected _range_id: number;
        protected _offer_id: number;
        protected _primary: boolean;

        protected _offer: Entity.Map.NetworkRange.Offer = null;

        public constructor(rowData: string[]) {
            super();
            this._range_id = parseInt(rowData['zasieg_id']);
            this._offer_id = parseInt(rowData['oferta_id']);
            this._primary = rowData['glowna'] == '1';

            this._offer = App.getInstance().entityContainer.findOffer(this._offer_id);

            if(!this._offer) {
                throw new Error('Shapes.RangeOffer: Oferta o id ' + this._offer_id + ' nie istnieje, nie można utworzyć relacji');
            }
        }

        public get offer(): Entity.Map.NetworkRange.Offer {
            return this._offer;
        }

        public get range_id(): number {
            return this._range_id;
        }

        public get offer_id(): number {
            return this._offer_id;
        }

        public get primary(): boolean {
            return this._primary;
        }

        public set primary(primary: boolean) {
            this._primary = primary;
        }
    }
}
