namespace Factory {
    import ClientNode = Entity.Map.Marker.Node.ClientNode;
    /**
     * Created by Mateusz Partyka on 21.09.2016.
     */
    export class NodeFactory {
        static createNode(supertype: Factory.NodeSupertype, id, lat, lng, data, map): Entity.Map.Marker.Node.Node {
            if (supertype == Factory.NodeSupertype.DEVICE) {
                return new Entity.Map.Marker.Node.DeviceNode(id, lat, lng, data, map);
            }
            else {
                return new Entity.Map.Marker.Node.ClientNode(id, lat, lng, data, map);
            }
        }
    }

    export class NodeSupertype {
        public static DEVICE = 'device';
        public static CLIENT = 'client';
    }

}