
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action {
    export abstract class MenuTicketsBase extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        protected populateTickets(response): number {
            let count: number = 0;
            let collection: Control.EntityContainer = this.app.entityContainer;

            try {
                collection.deleteTickets();

                let ticketsRowData = JSON.parse(response);

                for (let prop in ticketsRowData) {
                    if (ticketsRowData.hasOwnProperty(prop)) {
                        let ticketRowData = ticketsRowData[prop];
                        let ticketNode: Entity.Map.Marker.Node.Ticket = new Entity.Map.Marker.Node.Ticket(ticketRowData['gps'].split(',')[0], ticketRowData['gps'].split(',')[1], ticketsRowData[prop], this.app.service.map);

                        collection.tickets.push(ticketNode);
                        count++;
                    }
                }

                this.app.service.callbackContainer.invokeCallbacks('tickets', new Control.CallbackContainer.Event('update', collection.tickets, {}));
            }
            catch (e) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, e.message);
                alert.show();
            }

            return count;
        }
    }
}
