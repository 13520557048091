
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.ContextMenu {
    export class ClientPhotos extends Action.UserActionBase {
        public constructor(event: Action.UserActionEvent) {
            super(event);
        }

        public execute(): void {
            let clientNode: Entity.Map.Marker.Node.ClientNode = this.event.target as Entity.Map.Marker.Node.ClientNode;
            let clientPhotosWindow: Entity.View.Window.PhotosWindow = this.app.service.windowContainer.createWindow(Control.WindowClass.PHOTOS_WINDOW) as Entity.View.Window.PhotosWindow;
            clientPhotosWindow.showForNode(clientNode, true);
        }
    }
}
