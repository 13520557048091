
/**
 * Created by Mateusz Lipowski on 08.11.2016.
 */

namespace Action.MainMenu {
    export class MenuEditLinkType extends Action.UserActionBase {
        protected window: Entity.View.Window.Window = null;
        protected table: HTMLTableElement = null;

        protected onTypeUpdateCallbackId: number;
        protected proxyListenerEditLinkTypeButtonClick: (e: MouseEvent) => void;
        protected proxyListenerAddLinkTypeButtonClick: (e: MouseEvent) => void;

        public constructor(event: Action.UserActionEvent) {
            super(event);

            this.proxyListenerEditLinkTypeButtonClick = (e: MouseEvent): void => {
                this.onEditLinkTypeButtonClick(e.target as HTMLInputElement);
            };
            this.proxyListenerAddLinkTypeButtonClick = (e: MouseEvent): void => {
                this.onAddLinkTypeButtonClick(e.target as HTMLInputElement);
            };
        }

        public execute(): void {
            let menuItem = this.event.target;
            let collection: Control.EntityContainer = this.app.entityContainer;
            this.initView();
            this.populateLinkTypes();

            this.onTypeUpdateCallbackId = this.app.service.callbackContainer.pushCallback('linkType', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.populateLinkTypes();
                return true;
            });
        }

        protected onEditLinkTypeButtonClick(button: HTMLInputElement): void {
            let linkTypeId: number = parseInt(button.dataset['link_type_id']);
            let linkType: Entity.Map.Type.LinkType = this.app.entityContainer.findLinkType(linkTypeId);

            if(!linkType) {
                let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Wybrany typ połączenia nie istnieje');
                alert.show();
                return;
            }

            Factory.UserActionFactory.invokeAction('LinkTypeEdit', 'Other', new Action.UserActionEvent(linkType, {}));
        }

        protected onAddLinkTypeButtonClick(button: HTMLInputElement): void {
            Factory.UserActionFactory.invokeAction('LinkTypeAdd', 'Other', new Action.UserActionEvent(this, {}));
        }

        protected initView(): void {
            this.window = this.app.service.windowContainer.createWindow(Control.WindowClass.WINDOW);
            this.window.show('link_types');
            this.window.setWide();
            this.window.setOnDismiss((): void => {
                this.dispose();
            });

            this.table = this.window.getForm().getElementsByClassName('types-table')[0] as HTMLTableElement;
            if(!this.table)
                throw new Error('Brak tabeli typów w oknie typów');

            let newOfferButton: HTMLInputElement = this.window.getForm().getElementsByClassName('new-type')[0] as HTMLInputElement;
            newOfferButton.addEventListener('click', this.proxyListenerAddLinkTypeButtonClick);
        }

        protected dispose(): void {
            this.app.service.callbackContainer.deleteCallbackById('type', 'update', this.onTypeUpdateCallbackId);
        }

        protected populateLinkTypes(): void {
            if(!this.table) {
                throw new Error('UserAction.MainMenu.MenuType.populateLinkTypes: Table element is undefined');
            }

            this.removeAllChildren(this.table);
            this.createTableHeader();

            for(let linkType of this.app.entityContainer.linkTypes) {
                if(!linkType)
                    continue;

                this.appendTypeRow(linkType);
            }
        }

        protected appendTypeRow(linkType: Entity.Map.Type.LinkType): void {
            let id = this.createSpan(linkType.id.toString(), null, 'link_type-element id');
            let name = this.createSpan(linkType.name, null, 'link_type-element name');
            let color = this.createColor('#' + linkType.color, null, 'link_type-element color');
            let weight = this.createSpan(linkType.weight.toString(), null, 'link_type-element weight');
            let button = document.createElement('input') as HTMLInputElement;

            button.type = 'button';
            button.value = 'Edytuj';
            button.setAttribute('data-link_type_id', linkType.id.toString());
            button.addEventListener('click', this.proxyListenerEditLinkTypeButtonClick);

            let row: HTMLTableRowElement = null;
            row = this.createTableRow('td', id, name, color, weight, button);

            this.table.appendChild(row);
        }

        protected createTableHeader(): void {
            let row: HTMLTableRowElement = this.createTableRow('th', 'ID', 'Nazwa', 'Kolor', 'Grubość', '');
            this.table.appendChild(row);
        }
    }
}
