/**
 * Created by Mateusz Lipowski on 14.11.2016.
 */

namespace Entity.Map.NetworkRange {
    type rangePolygonsArray = Entity.Map.Shape.RangePolygon[];

    export class RangeManager extends Entity.Map.MapEntity {
        protected static frontZIndex: number = 900;
        protected static frontStrokeColor: string = '#a83200';

        protected _editedRegion: Entity.Map.Type.Region = null;

        protected regionsVisibility: boolean[] = [];
        protected rangePolygons: rangePolygonsArray[] = [];

        protected frontRangePolygon: Entity.Map.Shape.RangePolygon = null;
        protected polygonsDraggable: boolean = false;

        protected rangeUpdater: Entity.Map.NetworkRange.RangeUpdater = null;
        protected rangeVertexPositioner: Entity.Map.NetworkRange.RangeVertexPositioner = null;

        public constructor() {
            super();
            this.rangeUpdater = new Entity.Map.NetworkRange.RangeUpdater();

            App.getInstance().service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'click', (event: Control.CallbackContainer.Event): boolean => {
                let rangePolygon: Entity.Map.Shape.RangePolygon = event.target as Entity.Map.Shape.RangePolygon;

                if(!rangePolygon.getEditable())
                    return;

                if(rangePolygon != this.frontRangePolygon) {
                    if(this.frontRangePolygon) {
                        this.frontRangePolygon.restore();
                        this.frontRangePolygon.updateOptions();
                    }

                    this.frontRangePolygon = rangePolygon;
                    this.frontRangePolygon.zIndex = Entity.Map.NetworkRange.RangeManager.frontZIndex;
                    this.frontRangePolygon.strokeColor = Entity.Map.NetworkRange.RangeManager.frontStrokeColor;
                    this.frontRangePolygon.updateOptions();
                }
                else {
                    this.frontRangePolygon.restore();
                    this.frontRangePolygon.updateOptions();
                    this.frontRangePolygon = null;
                }

                return true;
            });

            App.getInstance().service.callbackContainer.pushCallback('offer', 'update', (e: Control.CallbackContainer.Event): boolean => {
                this.onOfferUpdate(e.target as Entity.Map.NetworkRange.Offer);
                return true;
            });
            App.getInstance().service.callbackContainer.pushCallback('offer', 'delete', (e: Control.CallbackContainer.Event): boolean => {
                this.onOfferDelete(e.target as Entity.Map.NetworkRange.Offer);
                return true;
            });

            App.getInstance().service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'rightclick', (event: Control.CallbackContainer.Event): boolean => {
                App.getInstance().service.contextMenu.show(event.target, Action.UserActionEvent.CreateForCallbackEvent(event));
                return true;
            });

            App.getInstance().service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'mousedown', (event: Control.CallbackContainer.Event): boolean => {
                if(event.params['vertex'] !== undefined) {
                    this.rangeVertexPositioner = new Entity.Map.NetworkRange.RangeVertexPositioner(this.regionRange, this.polygonsDraggable);
                    this.rangeVertexPositioner.onVertexDragStart(event.target, event.params['vertex']);
                }
                return true;
            });

            App.getInstance().service.callbackContainer.pushCallback(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, 'mouseup', (event: Control.CallbackContainer.Event): boolean => {
                if(event.params['vertex'] !== undefined && this.rangeVertexPositioner) {
                    this.rangeVertexPositioner.onVertexDragEnd(event.target, event.params['vertex']);
                    this.rangeVertexPositioner = null;
                }

                if(event.params['edge'] !== undefined) { //gdy (prawdopodobnie) dodano wierzchołek
                    App.getInstance().service.rangeManager.registerEditedRangePolygon(event.target as Entity.Map.Shape.RangePolygon);
                }
                return true;
            });

            document.addEventListener('mousemove', (e: MouseEvent): void => {
                if(this.rangeVertexPositioner) {
                    this.rangeVertexPositioner.onUpdateVertexPosition(e.clientX, e.clientY);
                }
            });
        }

        /**
         * Metoda rejestrująca RangePolygon na liście obszarów (dodanie do tablicy rangePolygons pod odpowiednim regionem)
         * @param rangePolygon
         * @return void
         */
        public registerRangePolygon(rangePolygon: Entity.Map.Shape.RangePolygon): void {
            let regionId: number = 0;
            if(rangePolygon.region)
                regionId = rangePolygon.region.id;

            if(!this.rangePolygons[regionId])
                this.rangePolygons[regionId] = [];


            rangePolygon.setDraggable(this.polygonsDraggable);

            this.rangePolygons[regionId].push(rangePolygon);
        }

        /**
         * Rejestruje obszar w RangeUpdater, dzięki czemu zostatnie zmieniony przy zapisie
         * @param rangePolygon
         */
        public registerEditedRangePolygon(rangePolygon: Entity.Map.Shape.RangePolygon): void {
            this.rangeUpdater.registerEditedRangePolygon(rangePolygon);

            App.getInstance().service.callbackContainer.invokeCallbacks(Control.CallbackContainer.CallbackSource.RANGE_POLYGON, new Control.CallbackContainer.Event('change', rangePolygon, {}));
        }

        /**
         * Edycja całego regionu - wszystkich obszarów należących do tego regionu
         * @param regionId
         * @return void
         */
        public editRegion(regionId: number): void {
            if(this._editedRegion) {
                this.setRegionEditable(false);
                this.setEditedRegionVisible(false);
                this._editedRegion = null;
            }

            this._editedRegion = App.getInstance().entityContainer.findRegion(regionId);
            if(!this.editRegion)
                throw new Error('Shapes.RangeManager.editRegion: Nie można znaleźć regionu ' + regionId);

            this.setEditedRegionVisible(true);
            this.setRegionEditable(true);
        }

        /**
         * Koniec edycji całego aktualnie edytowanego regionu
         * @param
         * @return void
         */
        public endEdit(): void {
            if(!this._editedRegion)
                return;

            this.setRegionEditable(false);
            this.setEditedRegionVisible(false);
            this._editedRegion = null;
            this.revertChanges();
        }

        /**
         * Koniec edycji całego aktualnie edytowanego regionu
         * @param
         * @return void
         */
        public cancelEdit(): void {
            if(!this._editedRegion)
                return;

            this.setRegionEditable(false);
            this.setEditedRegionVisible(false);
            this.revertChanges((): void  => {
                this.setEditedRegionVisible(true);
                this.setRegionEditable(true);
            });
        }

        /**
         * Zapis aktualnie edytowanego regionu - wszystkich obszarów należących do tego regionu
         * @param
         * @return void
         */
        public saveEdit(): void {
            this.rangeUpdater.requestRangeUpdate();
        }

        /**
         * Metoda sprawiająca że tylko wyznaczony obszar jest widoczny
         * @param rangePolygon
         * @param visible
         */
        public setSinglePolygonVisible(rangePolygon: Entity.Map.Shape.RangePolygon, visible: boolean): void {
            this.setEditedRegionVisible(!visible, true);

            if(visible) {
                for (let polygon of this.regionRange) {
                    if(!polygon)
                        continue;

                    if (polygon == rangePolygon) {
                        rangePolygon.setVisible(true);
                        rangePolygon.exclusiveVisibility = true;
                        return;
                    }
                }
            }
        }

        /**
         * Zmiana widoczności wybranego regionu - wszystkich obszarów należących do tego regionu
         * @param regionId
         * @param visible
         * @return void
         */
        public setRegionVisible(regionId: number, visible: boolean): void {
            if(!this.rangePolygons[regionId])
                return;

            this.regionsVisibility[regionId] = visible;

            if(!this._editedRegion || this._editedRegion.id != regionId) {
                for(let rangePolygon of this.rangePolygons[regionId]) {
                    if(!rangePolygon)
                        continue;

                    rangePolygon.setVisible(visible);
                    rangePolygon.exclusiveVisibility = false;
                }
            }
        }

        /**
         * Zmiana widoczności aktualnie edytowanego regionu - wszystkich obszarów należących do tego regionu
         * @param visible
         * @param ignorePreviousVisiblility
         * @return void
         */
        protected setEditedRegionVisible(visible: boolean, ignorePreviousVisiblility: boolean = false) {
            if(!this.regionRange)
                return;

            for(let rangePolygon of this.regionRange) {
                if(!rangePolygon)
                    continue;

                if(ignorePreviousVisiblility)
                    rangePolygon.setVisible(visible);
                else {
                    if (visible) {
                        rangePolygon.setVisible(true);
                    }
                    else {
                        rangePolygon.setVisible(this.regionsVisibility[this.editedRegion.id] == true);
                    }
                }

                rangePolygon.exclusiveVisibility = false;
            }
        }

        /**
         * Przywraca widoczność według tego co ustawiono w menu głównym
         * @return void
         */
        protected restoreVisiblility(): void {
            for(let regionId in this.regionsVisibility) {
                if(!regionId || !this.regionsVisibility[regionId])
                    this.setRegionVisible(parseInt(regionId), this.regionsVisibility[regionId]);
            }
        }

        /**
         * Zmiana edytowalności aktualnie edytowanego regionu - wszystkich obszarów należących do tego regionu
         * @param editable
         * @return void
         */
        protected setRegionEditable(editable: boolean) {
            if(!this.regionRange)
                return;

            for(let rangePolygon of this.regionRange) {
                if(!rangePolygon)
                    continue;

                rangePolygon.setEditable(editable);
            }
        }

        /**
         * Właczanie i wyłaczanie przenoszenia obszarów
         * @param draggable
         */
        public setDraggable(draggable: boolean): void {
            this.polygonsDraggable = draggable;

            for(let rangePolygon of this.regionRange) {
                if(!rangePolygon)
                    continue;

                rangePolygon.setDraggable(draggable);
            }
        }

        /**
         * Usunięcie wybranego obszaru (zostanie usunięty jedynie z pamięci)
         * @param rangePolygon
         * @return void
         */
        public deleteRangePolygon(rangePolygon: Entity.Map.Shape.RangePolygon): void {
            for(let key in this.regionRange) {
                if(this.regionRange.hasOwnProperty(key) && this.regionRange[key] && this.regionRange[key] == rangePolygon) {
                    rangePolygon.dispose();
                    this.regionRange[key] = null;
                    return;
                }
            }
        }

        /**
         * Metoda wywoływana po zmianie oferty
         * @param offer
         */
        public onOfferUpdate(offer: Entity.Map.NetworkRange.Offer): void {
            for(let rangePolygonsArray of this.rangePolygons) {
                if(!rangePolygonsArray)
                    continue;

                for(let rangePolygon of rangePolygonsArray) {
                    if(!rangePolygon)
                        continue;

                    rangePolygon.updateOptions();
                }
            }
        }

        public onOfferDelete(offer: Entity.Map.NetworkRange.Offer): void {
            for(let rangePolygonsArray of this.rangePolygons) {
                if(!rangePolygonsArray)
                    continue;

                for(let rangePolygon of rangePolygonsArray) {
                    if(!rangePolygon)
                        continue;

                    rangePolygon.updateOptions(offer.id);
                }
            }
        }

        /**
         * Usuwa z pamięci wszystkie obszary zasięgu i pobiera ponownie z bazy danych
         * @return void
         */
        protected revertChanges(callback: () => void = null): void {
            let requestSender: Logic.RequestSender = new Logic.RequestSender();
            requestSender.sendRequest('GetRange', {}, (action: string, fields: Object, response: any): void => {
                if(requestSender.isError()) {
                    let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, requestSender.message);
                    alert.show();
                }
                else {
                    try {
                        let range = JSON.parse(response);
                        if (range) {
                            for(let rangePolygonsArray of this.rangePolygons) {
                                if(!rangePolygonsArray)
                                    continue;

                                for(let rangePolygon of rangePolygonsArray) {
                                    if(!rangePolygon)
                                        continue;

                                    rangePolygon.dispose();
                                }
                                rangePolygonsArray = null;
                            }
                            this.rangePolygons = [];
                            App.getInstance().populateRange(range);
                            this.restoreVisiblility();

                            if(callback)
                                callback();
                        }
                    }
                    catch (e) {
                        let alert: Entity.View.Alert = new Entity.View.Alert(Entity.View.AlertType.ERROR, 'Pobieranie obszarów zasięgu - nie można przetworzyć odpowiedzi: ' + e.message);
                        alert.show();
                    }
                }
            });
        }

        /**
         * Zwraca tablicę obszarów dla aktualnie edytowanego regionu
         * @return Entity.Map.Shape.RangePolygon[]
         */
        protected get regionRange(): rangePolygonsArray {
            if(!this.editedRegion)
                return null;

            return this.rangePolygons[this.editedRegion.id];
        }

        /**
         * Metoda pobierająca aktualnie edytowany region
         * @return Entity.Map.Type.Region
         */
        public get editedRegion(): Entity.Map.Type.Region {
            return this._editedRegion;
        }
    }
}
